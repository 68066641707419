import * as React from 'react';
import { Dispatch, useState } from 'react';
import { EdgeStyle } from 'react-native-theoplayer';
import { DeviceContext } from './deviceContext';
//96CB00EC
interface PlayerContext {
    castingApplicationId: string;
    setCastingApplicationId: Dispatch<any>;

    fromLive: [];
    setFromLive: Dispatch<any>;

    trackStyle: {
        backgroundColor: string;
        fontColor: string;
        fontSize: string;
        fontFamily: string;
        edgeStyle: EdgeStyle;
    };
    setTrackStyle: Dispatch<any>;

    pip: boolean;
    setPip: Dispatch<any>;

    seekControls: boolean;
    setSeekControls: Dispatch<any>;

    paused: boolean;
    setPaused: Dispatch<any>;

    playing: boolean;
    setPlaying: Dispatch<any>;

    showControls: boolean;
    setShowControls: Dispatch<any>;

    position: number;
    setPosition: Dispatch<any>;

    seekPosition: number;
    setSeekPosition: Dispatch<any>;

    seekSetPositionDelayed: string;
    setSeekSetPositionDelayed: Dispatch<any>;

    seekSetPosition: number;
    setSeekSetPosition: Dispatch<any>;

    duration: number;
    setDuration: Dispatch<any>;

    audioTrackIndex: number;
    setAudioTrackIndex: Dispatch<any>;

    textTrackIndex: number;
    setTextTrackIndex: Dispatch<any>;

    player: {
        isPlaying: boolean;
        unixstart: number;
        isLive: boolean;
        isAudio: boolean;

        resizeMode: string;
        resizeModes: any;

        textTracks: any;
        textTrackType: string;
        audioTracks: any;

        seekPosition: number;
        buffer: number;

        error: string;
        ended: boolean;
        lostfocus: boolean;
        type: string;
    };
    setPlayer: Dispatch<any>;

    stream: {
        url: string;
        type: string;
        header: 'Mozilla/5.0 (Windows NT 10.0; Win64;x64) AppleWebKit/537.36 (KHTML like Gecko) Chrome/80.0.3987.l63 Safari/537.36';
        vast: string;
        progress: number;
    };
    setStream: Dispatch<any>;

    drm: {
        enabled: boolean;
        type: string;
        licenseServer: string;
        base64Certificate?: boolean;

        //certificateUrl: string; //buydrm
        licenseServerApple?: string; //buydrm

        certificateUrlIrdeto: string; //irdeto
        keyServerUrl?: string; /// irdeto
        accountId?: string; ///irdeto

        headers: {
            customData: string;
        };
        supplier: string;
    };
    setDrm: Dispatch<any>;

    casting: {
        type: string;
        chromecastConnected: string;
    };
    setCasting: Dispatch<any>;

    castingStatus: string;
    setCastingStatus: Dispatch<any>;

    subtitles: {
        title: string;
        language?: 'en';
        uri: string;
        type?: 'text/vtt';
    };
    setSubtitles: Dispatch<any>;

    aspectRatio: string;
    setAspectRatio: Dispatch<any>;

    ads: {
        preroll: boolean;
        overlay: boolean;
        banner: boolean;

        vast: {
            url: string;
        };
    };
    setAds: Dispatch<any>;

    tk: {
        ak: string;
        fl: string;
        akl: string;
        ua: string;
    };
    setTk: Dispatch<any>;
}
export const PlayerContext = React.createContext<PlayerContext>(null);

export function PlayerProvider({ children }: { children: any }) {
    const deviceContext = React.useContext(DeviceContext);
    const [fromLive, setFromLive] = useState([] as any);
    const [stream, setStream] = useState([] as any);
    const [pip, setPip] = useState(false);
    const [ads, setAds] = useState([] as any);
    const [paused, setPaused] = useState([] as any);
    const [playing, setPlaying] = useState([] as any);
    const [subtitles, setSubtitles] = useState([] as any);
    const [casting, setCasting] = useState([] as any);
    const [castingStatus, setCastingStatus] = useState('');
    const [drm, setDrm] = useState([] as any);
    const [player, setPlayer] = useState([] as any);
    const [tk, setTk] = useState([] as any);
    const [showControls, setShowControls] = useState(false);
    const [seekControls, setSeekControls] = useState(false);
    const [position, setPosition] = useState(0);
    const [seekPosition, setSeekPosition] = useState(0);
    const [seekSetPosition, setSeekSetPosition] = useState(0);
    const [seekSetPositionDelayed, setSeekSetPositionDelayed] = useState('');
    const [duration, setDuration] = useState(0);
    const [audioTrackIndex, setAudioTrackIndex] = useState(0);
    const [textTrackIndex, setTextTrackIndex] = useState(0);
    const [aspectRatio, setAspectRatio] = useState('Cover');
    const [trackStyle, setTrackStyle] = useState({
        backgroundColor: '#00000060',
        fontColor: '#ffffff',
        fontSize: '100%',
        fontFamily: deviceContext.isApple ? deviceContext.fontType : 'monospace',
        edgeStyle: EdgeStyle.none,
    });
    const [castingApplicationId,setCastingApplicationId] = useState('7388A69F')

    return (
        <PlayerContext.Provider
            value={{
                castingApplicationId,
                setCastingApplicationId,
                trackStyle,
                setTrackStyle,
                fromLive,
                setFromLive,
                seekSetPosition,
                setSeekSetPosition,
                seekPosition,
                setSeekPosition,
                pip,
                setPip,
                castingStatus,
                setCastingStatus,
                aspectRatio,
                setAspectRatio,
                stream,
                setStream,
                ads,
                setAds,
                paused,
                setPaused,
                playing,
                setPlaying,
                subtitles,
                setSubtitles,
                casting,
                setCasting,
                drm,
                setDrm,
                player,
                setPlayer,
                tk,
                setTk,
                showControls,
                setShowControls,
                seekControls,
                setSeekControls,
                setPosition,
                position,
                duration,
                setDuration,
                audioTrackIndex,
                setAudioTrackIndex,
                textTrackIndex,
                setTextTrackIndex,
                seekSetPositionDelayed,
                setSeekSetPositionDelayed,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(PlayerContext);
//     return context;
// }
