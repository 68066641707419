import { setFocus, View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { useContext, useState } from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import { getKeyboard } from '../../elements/players/helpers/functions';
import { Screen } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { colorShade } from '../../../styling/colorShade';
import Text from '../text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling, getInputStyling } from '../helpers/helper';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import Input from '../input/input';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import { BackHandler } from 'react-native';

export interface ParentalProps {
    styling: any;
    setParentalApproved: any;
    setShowParental: any;
    profile?: any;
    focusContext: any;
    type: any;
}
const Parental = ({ focusContext, styling, setParentalApproved, setShowParental, profile, type }: ParentalProps) => {
    const deviceContext = useContext(DeviceContext);
    const contextUser = useContext(UserContext);
    const appContext = useContext(AppContext);
    const [showError, setShowError] = useState(false);
    const [parental, setParental] = useState('');

    React.useEffect(() => {
        setFocus('parental-control-screen');
    }, []);

    const onBackPress = React.useCallback(() =>{
        setShowParental?.(false);

        return true;
    }, []);

    React.useEffect(() => {
        const handler = BackHandler.addEventListener('hardwareBackPress', onBackPress)

        return () => {
            handler.remove();
        }
    }, []);

    const checkParentalCode = () => {
        if (parental == contextUser?.profile?.code || parental == profile?.code) {
            setParentalApproved(true);
        } else {
            setShowError(true);
        }
    };

    const setPincode = (value) => {
        if (value == 'back') {
            setParental(parental.substring(0, parental.length - 1));
        } else if (value == 'clear') {
            setParental('');
        } else if (parental.length < 4) {
            setParental(parental + '' + value);
        }
    };
    const width = getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isTablet || deviceContext.isWebTV ? 280 : 500);

    return (
        <ScreenFadeIn>
            <Screen
                focusOptions={{ screenOrder: 2, focusKey: 'parental-control-screen'}}
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    flex: 1,
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                }}
            >
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <View
                        style={{
                            borderColor: deviceContext.isPhone ? 'transparent' : styling.border_color,
                            borderWidth: deviceContext.isPhone ? 0 : styling.border_width,
                            borderRadius: deviceContext.isPhone ? 0 : getCorrectWidth(deviceContext, styling.border_radius),
                            backgroundColor: styling.background_color,
                            width: width,
                            height: getCorrectHeight(deviceContext, 250),
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 26) }}>
                            <View style={{ alignSelf: 'flex-start' }}>
                                <View>
                                    <Text
                                        style={{
                                            color: styling.title_color,
                                            fontWeight: styling.title_bold ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                            marginBottom: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        {lang.getTranslation(contextUser, 'parental_control_lock')}
                                    </Text>
                                    {type == 'access_profile' && (
                                        <Text
                                            style={{
                                                color: styling.subtext_color,
                                                fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 20),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'pin_profile_access')}
                                        </Text>
                                    )}
                                    {type == 'delete_account' && (
                                        <Text
                                            style={{
                                                color: styling.subtext_color,
                                                fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 20),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'pin_delete_account')}
                                        </Text>
                                    )}
                                    {type == 'delete_profile' && (
                                        <Text
                                            style={{
                                                color: styling.subtext_color,
                                                fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 20),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'pin_delete_profile')}
                                        </Text>
                                    )}
                                    {type == 'access_content' && (
                                        <Text
                                            style={{
                                                color: styling.subtext_color,
                                                fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 20),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'pin_access_content')}
                                        </Text>
                                    )}
                                    {type == 'lock_profile' && (
                                        <Text
                                            style={{
                                                color: styling.subtext_color,
                                                fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 20),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'pin_lock_profile')}
                                        </Text>
                                    )}
                                </View>
                                {showError && (
                                    <Text
                                        style={{
                                            color: 'crimson',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                            marginBottom: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        {lang.getTranslation(contextUser, 'parental_wrong')}
                                    </Text>
                                )}
                                <View style={{ width: getCorrectWidth(deviceContext, 225) }}>
                                    <Input
                                        focusContext={focusContext}
                                        showKeyboard={null}
                                        maxLength={4}
                                        onChangText={(value) => setParental(value.replace(/[^0-9]/g, ''))}
                                        icon={faLock}
                                        width={getCorrectWidth(deviceContext, 225)}
                                        isSecure={true}
                                        placeholder={'123...'}
                                        animation={getFocusStyling('Inputs', appContext)}
                                        styling={getInputStyling(styling.components?.input?._id, appContext)}
                                        value={parental}
                                    ></Input>
                                </View>

                                <Pressable
                                    style={{
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        width: getCorrectWidth(deviceContext, 225),

                                        marginTop: 10,
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => checkParentalCode()}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'submit')}
                                        </Text>
                                    </View>
                                </Pressable>

                                <Pressable
                                    style={{
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        width: getCorrectWidth(deviceContext, 225),
                                        marginTop: 10,
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => setShowParental(false)}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(contextUser, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                        {!deviceContext.isWebTV && !deviceContext.isTablet && !deviceContext.isPhone && (
                            <View style={{ flex: 1 }}>
                                {getKeyboard(appContext.application.theme.keyboard.search, deviceContext, appContext, setPincode)}
                            </View>
                        )}
                    </View>
                </View>
            </Screen>
        </ScreenFadeIn>
    );
};

export default Parental;
