import { ActivityIndicator, CoreManager, Image, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { faEdit, faLock, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Dropdown_ from '../../../application/builder/components/dropdown/dropdown';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Pressable from '../../../application/builder/components/pressable/pressable';
import ProfileImage from '../../../application/builder/components/profiles/profiles';
import FocusableSwitch from '../../../application/builder/components/switch/Switch';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import { updateProfilesJson } from '../../../application/data/account';
import utils from '../../../application/general/utils';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import { BackHandler, ScrollView as RNScrollView } from 'react-native';
import Text from '../../../application/builder/components/text';
import { isFactorBrowser, isFactorMobile, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getKeyboardStyling, getModalStyling, getFocusStyling, getInputStyling, getPressableStyling, getPressableTextStyling, getDropdownStyling, getSwitchStyling } from '../../../application/builder/components/helpers/helper';
import UTILS from '../../../application/general/utils';

export type DropdownRef = {
    current: {
        isOpen: () => boolean;
        toggleDropdown: (value: boolean) => void;
    };
};

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [name, setName] = React.useState('');
    const [screenSizes] = React.useState([{ name: 'Contain' }, { name: 'Cover' }, { name: 'Stretch' }]);
    const [times] = React.useState([{ name: 'AM/PM' }, { name: '24 Hour' }]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [ratings, setRatings] = React.useState([] as any);
    const [time, setTime] = React.useState('AM/PM');
    const [screenSize, setScreenSize] = React.useState('Cover');
    const [audioLanguage, setAudioLanguage] = React.useState(appContext.application.settings.languages?.[0]?.name ?? '');
    const [subtitleLanguage, setSubtitleLanguage] = React.useState(appContext.application.settings.languages?.[0]?.name ?? '');
    const [language, setLanguage] = React.useState(appContext.application.settings.languages?.[0]?.name ?? 'English');
    const [code, setCode] = React.useState('0000');
    const [consent, setConsent] = React.useState(false);
    const [parentalIndex, setParentalIndex] = React.useState(0);
    const [parentalCode, setParentalCode] = React.useState('');
    const [parentalLocked, setParentalLocked] = React.useState(false);
    const [lockAccount, setLockAccount] = React.useState(false);
    const [showProfileImageSelection, setShowProfileImageSelection] = React.useState(false);
    const [avatar, setAvatar] = React.useState('https://cloudtv.akamaized.net/ProfileImages/183371647_flower _xl-beta-v2-2-2.png');
    const [loading, setLoading] = React.useState(false);
    const [keyboardVisible, setKeyboardVisible] = React.useState(false);
    const [keyboardVisible2, setKeyboardVisible2] = React.useState(false);
    const insets = useSafeAreaInsets();

    const scrollViewRef = React.useRef<RNScrollView>(null);

    const dropdownsRefs = React.useRef<{ ref1: DropdownRef; ref2: DropdownRef; ref3: DropdownRef; ref4: DropdownRef; ref5: DropdownRef }>({
        ref1: React.createRef(),
        ref2: React.createRef(),
        ref3: React.createRef(),
        ref4: React.createRef(),
        ref5: React.createRef(),
    }).current;

    useEffectOnce(() => {
        if (appContext.application.settings.agerating != undefined) {
            var ratings = appContext.application.settings.agerating.ratings;
            setRatings(ratings);
        }
    });

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.ADD_PROFILE, duration);
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        return false;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, [disableNativeHardwareBackPress]);

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            if (['back', 'menu'].includes(eventType)) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    navigation.navigate('Profiles');
                }
            }
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    navigation.navigate('Profiles');
                }
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const createProfile = async () => {
        if (name != '' && name != undefined) {
            sendActionReport(appContext, deviceContext, userContext, actionType.ADD_PROFILE);
            setLoading(true);

            var id = utils.uuidv4();

            var langChanged = false;
            if (userContext.selectedLanguage != language) {
                userContext.setSelectedLanguage(language);
                UTILS.storeJson('Selected_Language', language);
                langChanged = true;
            }

            var profile = {
                id: id,
                avatar: avatar,
                name: name,
                locked: lockAccount,
                subtitles: subtitleLanguage,
                audio: audioLanguage,
                language: language,
                parental: {
                    index: parentalIndex,
                    code: parentalCode,
                    locked: parentalLocked,
                },
                code: code,
                clock: time,
                aspectRatio: screenSize,
                consent: consent,
                recommendations: [],
                recordings: [],
                downloads: {
                    movies: [],
                    series: [],
                    shorts: [],
                },
                reminders: [],
                favorites: {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                },
                watchings: {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    seasons: [],
                    episodes: [],
                    courses: [],
                    lessons: [],
                    podcasts: [],
                    pods: [],
                    music: [],
                    songs: [],
                    shorts: [],
                    games: [],
                },
                payperview: {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                },
            };
            var newProfiles = [
                ...userContext.allProfiles,
                {
                    ...profile,
                },
            ];
            userContext.setAllProfiles(newProfiles);
            await updateProfilesJson(newProfiles, appContext.application, userContext);
            if (langChanged == true) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader', params: { fromEditOrAdd: true } }],
                    })
                );
            } else {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles', params: { fromEditOrAdd: true } }],
                    })
                );
            }
        }
    };

    const getParentalStatus = (index) => {
        if (index == parentalIndex) {
            return true;
        }
        return false;
    };
    const setParentalStatus = (index, value, locked) => {
        setParentalIndex(index);
        setParentalCode(value);
        setParentalLocked(locked);
    };
    const setProfileImage = (item, index) => {
        setAvatar(item.image);
        setShowProfileImageSelection(false);
    };
    const setLockAccountLocal = () => {
        setLockAccount(!lockAccount);
    };
    const setConsentAccountLocal = () => {
        setConsent(!consent);
    };

    const dropdowns = [
        {
            title: lang.getTranslation(userContext, 'selected_language'),
            data: appContext.application.settings.languages,
            value: language,
            type: 'Languages',
            onSelect: setLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_subtitle'),
            data: appContext.application.settings.languages,
            value: subtitleLanguage,
            type: 'Subs',
            onSelect: setSubtitleLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_audio'),
            data: appContext.application.settings.languages,
            value: audioLanguage,
            type: 'Audio',
            onSelect: setAudioLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_screensize'),
            data: screenSizes,
            value: screenSize,
            type: 'Screens',
            onSelect: setScreenSize,
        },
        {
            title: lang.getTranslation(userContext, 'time_setting'),
            data: times,
            value: time,
            type: 'Times',
            onSelect: setTime,
        },
    ];

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {keyboardVisible && (
                <Keyboard
                    onCloseKeyboard={() => {
                        CoreManager.setFocus('profile');
                        setKeyboardVisible(false);
                    }}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                    onChangText={setName}
                    value={name}
                    focusOptions={{ nextFocusUp: 'profile', nextFocusDown: 'profile' }}
                />
            )}
            {keyboardVisible2 && (
                <Keyboard
                    onCloseKeyboard={() => {
                        CoreManager.setFocus('parental-code');
                        setKeyboardVisible2(false);
                    }}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                    onChangText={setCode}
                    value={code}
                    focusOptions={{ nextFocusUp: 'parental-code', nextFocusDown: 'parental-code' }}
                />
            )}
            {showProfileImageSelection && (
                <ProfileImage
                    stylingprofiles={appContext.application.authentication.profiles}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setShowProfileImageSelection={setShowProfileImageSelection}
                    setProfileImage={setProfileImage}
                    profile={[]}
                />
            )}
            {loading && (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                </View>
            )}
            {!loading && (
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1.5,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.profiles?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.profiles?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'add_profile')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.profiles?.header.sub_size),
                                fontWeight: appContext.application.authentication?.profiles?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.profiles?.header.sub_color,
                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'wittheprofile')}
                        </Text>
                    </View>
                    <View style={{ flex: 5 }}>
                        <ScrollView
                            ref={scrollViewRef}
                            contentContainerStyle={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <View style={{ flex: 1 }}>
                                    <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                        <View style={{ position: 'absolute', top: -20, right: -50, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                            <FontAwesomeIcon icon={faEdit} color={'#ffffff'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginTop: 5,
                                                    textAlign: 'center',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                                    color: appContext.application.theme.settings.font_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'edit_avatar')}
                                            </Text>
                                        </View>
                                        <Pressable
                                            animatorOptions={{
                                                type: 'scale',
                                                focus: {
                                                    scale: 1.1,
                                                    duration: 150,
                                                },
                                            }}
                                            onPress={() => setShowProfileImageSelection(true)}
                                        >
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'cover'}
                                                source={{ uri: avatar }}
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 100),
                                                    height: getCorrectWidth(deviceContext, 100),
                                                    borderRadius: getCorrectWidth(deviceContext, 100) / 2,
                                                    borderColor: getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext).backgroundColor,
                                                    borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                                                }}
                                            ></Image>
                                        </Pressable>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'flex-start',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                    }}
                                >
                                    <Input
                                        value={name}
                                        onChangText={(value) => setName(value)}
                                        icon={faUser}
                                        width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.35)}
                                        isSecure={false}
                                        placeholder={lang.getTranslation(userContext, 'your_name')}
                                        animation={getFocusStyling('Inputs', appContext)}
                                        styling={getInputStyling(appContext.application.authentication.profiles.components?.input?._id, appContext)}
                                        focusOptions={{ focusKey: 'profile' }}
                                        onFocus={() => {
                                            if (keyboardVisible) {
                                                setKeyboardVisible(false);
                                            }
                                        }}
                                        showKeyboard={() => {
                                            if (deviceContext.isAndroidTV) scrollViewRef.current.scrollTo({ y: getCorrectHeight(deviceContext, 25) });
                                            setKeyboardVisible(true);
                                        }}
                                    />
                                </View>

                                {ratings.length > 0 && (
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.settings.background_color2,
                                            borderRadius: appContext.application.theme.settings.border_radius,
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                            justifyContent: 'flex-start',
                                            padding: getCorrectHeight(deviceContext, 20),
                                            paddingVertical: getCorrectHeight(deviceContext, 10),
                                            paddingTop: getCorrectHeight(deviceContext, 20),
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'select_age_rating')}
                                        </Text>
                                        <View style={{ flexDirection: 'row' }}>
                                            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                                {ratings.map((rating, index) => {
                                                    return (
                                                        <View style={{ margin: getCorrectHeight(deviceContext, 10) }}>
                                                            <FocusableSwitch
                                                                focusContext={focusContext}
                                                                onValueChange={() => setParentalStatus(index, rating.name, rating.locked)}
                                                                value={getParentalStatus(index)}
                                                                focusOptions={{
                                                                    focusKey: `parental-rating-${index}`,
                                                                    nextFocusDown: 'parental-lock-switch',
                                                                }}
                                                                styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                                            />
                                                            <Text
                                                                style={{
                                                                    marginTop: getCorrectHeight(deviceContext, 5),
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                                    color: appContext.application.theme.settings.font_color,
                                                                }}
                                                            >
                                                                {rating.name}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </ScrollView>
                                        </View>
                                    </View>
                                )}
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 40),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingLeft: getCorrectWidth(deviceContext, 20),
                                        paddingRight: getCorrectWidth(deviceContext, 20),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            color: appContext.application.theme.settings.font_color,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'lock_account_parental_control')}
                                    </Text>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <View
                                            style={{
                                                marginLeft: getCorrectHeight(deviceContext, 10),
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <FocusableSwitch
                                                focusContext={focusContext}
                                                onValueChange={() => setLockAccountLocal()}
                                                value={lockAccount}
                                                focusOptions={{
                                                    focusKey: 'parental-lock-switch',
                                                    nextFocusUp: 'parental-rating-0',
                                                    nextFocusDown: 'consent-switch',
                                                }}
                                                styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                            ></FocusableSwitch>
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 80),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'center',
                                        paddingLeft: getCorrectWidth(deviceContext, 20),
                                        paddingRight: getCorrectWidth(deviceContext, 20),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                    }}
                                >
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'personalized_ads')}
                                        </Text>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <View
                                                style={{
                                                    marginLeft: getCorrectHeight(deviceContext, 10),
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <FocusableSwitch
                                                    focusContext={focusContext}
                                                    onValueChange={() => setConsentAccountLocal()}
                                                    value={consent}
                                                    focusOptions={{ focusKey: 'consent-switch', nextFocusUp: 'parental-lock-switch' }}
                                                    styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                                ></FocusableSwitch>
                                            </View>
                                        </View>
                                    </View>
                                    <View>
                                        <Text
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 8),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'consent_personalized_ads')}
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 40),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        marginTop: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.485 : deviceContext.isWebTV || deviceContext.isTablet ? 0.187 : 0.186),

                                            paddingLeft: getCorrectWidth(deviceContext, 20),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'parental_code')}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignSelf: 'center',
                                            overflow: 'visible',
                                        }}
                                    >
                                        <Input
                                            maxLength={4}
                                            value={code}
                                            isSecure={true}
                                            onChangText={(value) => setCode(value.replace(/[^0-9]/g, ''))}
                                            icon={faLock}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.385 : deviceContext.isWebTV || deviceContext.isTablet ? 0.2 : 0.203)}
                                            placeholder={'****'}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.profiles.components?.input?._id, appContext)}
                                            focusOptions={{ focusKey: 'parental-code' }}
                                            onFocus={() => {
                                                if (keyboardVisible2) {
                                                    setKeyboardVisible2(false);
                                                }
                                            }}
                                            showKeyboard={() => {
                                                if (deviceContext.isAndroidTV) scrollViewRef.current.scrollTo({ y: getCorrectHeight(deviceContext, 320) });
                                                setKeyboardVisible2(true);
                                            }}
                                        />
                                    </View>
                                </View>
                                {dropdowns.map(({ data, title, type, value, onSelect }, idx) => (
                                    <View
                                        style={{
                                            height: getCorrectHeight(deviceContext, 40),
                                            backgroundColor: appContext.application.theme.settings.background_color2,
                                            borderRadius: appContext.application.theme.settings.border_radius,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                            marginTop: 10,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            zIndex: 999 - idx,
                                            overflow: 'visible',
                                        }}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.5 : 0.19),

                                                paddingLeft: getCorrectWidth(deviceContext, 20),
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: appContext.application.theme.settings.font_color,
                                                }}
                                            >
                                                {title}
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                                flexDirection: 'row',
                                                alignSelf: 'center',
                                                overflow: 'visible',
                                            }}
                                        >
                                            <Dropdown_
                                                width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.38 : 0.2)}
                                                styling={getDropdownStyling(appContext.application.authentication.profiles.components?.dropdown?._id, appContext)}
                                                animation={getFocusStyling('Dropdowns', appContext)}
                                                value={value}
                                                dataIn={data}
                                                type={type}
                                                setSelection={onSelect}
                                                dropdownRef={dropdownsRefs[`ref${idx + 1}`]}
                                                onToggleDropdown={(value) => {
                                                    if (isFactorMobile || isFactorBrowser) {
                                                        dropdowns.forEach((_, index) => {
                                                            const nextRef: DropdownRef = dropdownsRefs[`ref${index + 1}`];

                                                            if (index !== idx && value && nextRef.current && nextRef.current.isOpen()) {
                                                                nextRef.current.toggleDropdown(false);
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </View>
                                    </View>
                                ))}

                                <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        focusOptions={{ focusKey: 'submit-button' }}
                                        style={{
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                            ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => createProfile()}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            {!isLoading && (
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'submit')}
                                                </Text>
                                            )}
                                            {isLoading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext).color} size={'large'}></ActivityIndicator>}
                                        </View>
                                    </Pressable>
                                </View>
                                <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        style={{
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),

                                            ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => navigation.navigate('Profiles')}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'back')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
