import { CreateListRenderItemInfo, FlashList, Image, View } from '@scriptx-com/xtv-toolkit';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator, BackHandler, Dimensions } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';
import Parental from '../../../application/builder/components/parental/parental_lock';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { PlayerContext } from '../../../application/context/playerContext';
import { SettingsContext } from '../../../application/context/settingsContext';
import { UserContext } from '../../../application/context/userContext';
import { getProfiles, updateProfilesJson } from '../../../application/data/account';
import utils from '../../../application/general/utils';
import useStateWithPromise from '../../../application/hooks/useStateWithPromise';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import 'moment/min/locales';
import { getPressableStyling, getFocusStyling, getPressableTextStyling, getModalStyling } from '../../../application/builder/components/helpers/helper';
import { ContentContext } from '../../../application/context/contentContext';
import { getNativeBackPress } from '../../wrapper/helpers/getNativeBackPress';
import { createNewProfiles } from './helpers/helper';
import { isPlatformIos } from '@rnv/renative';

const screenWidth = Dimensions.get('screen').width;
const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const settingsContext = React.useContext(SettingsContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const [showParental, setShowParental] = React.useState(false);
    const [showParentalEdit, setShowParentalEdit] = React.useState(false);
    const [parentalApproved, setParentalApproved] = React.useState(false);
    const [profile, setProfile] = React.useState([] as any);
    const [profiles, setProfiles] = React.useState(userContext.allProfiles);
    const [loading, setLoading] = React.useState(true);
    const [profileIndex, setProfileIndex] = React.useState(0);
    const [setNewProfile] = useStateWithPromise(profile, setProfile);
    const [showExit, setShowExit] = React.useState(false);
    const [showReset, setShowReset] = React.useState(false);

    // useTVRemoteHandler seems to not react to outside state changes, so we use ref to sync showExit state
    const showExitSync = React.useRef(false);
    const backCount = React.useRef(0);
    const disableNativeHardwareBackPress = () => {
        return getNativeBackPress();
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);
    useTVRemoteHandler(
        ({ eventType, eventKeyAction }: any) => {
            if (showParental || showParentalEdit || showReset) return;
            if (!deviceContext.isWebTV && eventKeyAction === 'down') {
                if (['back', 'menu'].includes(eventType)) {
                    backCount.current++;
                    if (backCount.current == 1) {
                        setShowExit(true);
                        showExitSync.current = true;
                    }
                    if (backCount.current == 2) {
                        if (deviceContext.isTizen) {
                            // @ts-expect-error
                            if (typeof tizen !== 'undefined') {
                                // @ts-expect-error
                                window.tizen.application.getCurrentApplication().exit();
                            }
                        } else if (deviceContext.isWebos) {
                            // @ts-expect-error
                            if (typeof webOS !== 'undefined') {
                                // @ts-expect-error
                                webOS.platformBack();
                            }
                        } else {
                            BackHandler.exitApp();
                        }
                    }
                } else {
                    if (showExitSync.current == true) {
                        cancelExit();
                    }
                }
            }
        },
        [showParental, showParentalEdit, showReset]
    );
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            if (showParental || showParentalEdit || showReset) return;

            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                backCount.current++;
                if (backCount.current == 1) {
                    setShowExit(true);
                    showExitSync.current = true;
                }
                if (backCount.current == 2) {
                    if (deviceContext.isTizen) {
                        // @ts-expect-error
                        if (typeof tizen !== 'undefined') {
                            // @ts-expect-error
                            window.tizen.application.getCurrentApplication().exit();
                        }
                    } else if (deviceContext.isWebos) {
                        // @ts-expect-error
                        if (typeof webOS !== 'undefined') {
                            // @ts-expect-error
                            webOS.platformBack();
                        }
                    } else {
                        BackHandler.exitApp();
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, [showParental, showParentalEdit, showReset]);
    const cancelExit = () => {
        setShowExit(false);
        showExitSync.current = false;
        backCount.current = 0;
    };

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
            settingsContext.setShowMainMenu(false);
            await loadCustomerData();
        }
        fetchData();
    }, [route, navigation]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.PROFILE, duration);
            };
        }, [])
    );

    const loadCustomerData = async () => {
        if (profiles.length == 0) {
            var allProfiles = (await getProfiles(appContext.application, userContext)) as any;
            if (allProfiles != null && route?.params?.fromEditOrAdd == undefined) {
                userContext.setAllProfiles(allProfiles);
                setProfiles(allProfiles);
            } else if (route?.params?.fromEditOrAdd != undefined) {
                setProfiles(userContext.allProfiles);
            } else {
                var id = utils.uuidv4();
                var newProfiles = createNewProfiles(id, userContext, appContext);
                await updateProfilesJson(newProfiles, appContext.application, userContext);
                userContext.setAllProfiles(newProfiles);
                setProfiles(newProfiles);
            }
        } else {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (profiles.length > 0) {
            if (appContext.application.settings.enable_profiles == false) {
                selectProfile(profiles[0], 0);
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, [profiles]);

    const selectProfile = async (profile, index) => {
        setLoading(true);
        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_PROFILE);
        if (!parentalApproved && profile.locked) {
            setProfile(profile);
            setProfileIndex(index);
            setShowParental(true);
        } else {
            var profile = userContext.allProfiles.find((p) => p.id == profile.id);

            if (profile.favorites == undefined) {
                profile.favorites = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.payperview == undefined) {
                profile.payperview = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.watchings == undefined) {
                profile.watchings = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    seasons: [],
                    episodes: [],
                    courses: [],
                    lessons: [],
                    podcasts: [],
                    pods: [],
                    music: [],
                    songs: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.recordings == undefined) {
                profile.recordings = [];
            }
            if (profile.reminders == undefined) {
                profile.reminders = [];
            }
            if (profile.recommendations == undefined) {
                profile.recommendations = [];
            }

            playerContext.setAspectRatio(profile.aspectRatio != undefined ? profile.aspectRatio : 'Cover');
            if (profile.trackStyle != undefined) {
                playerContext.setTrackStyle(profile.trackStyle);
            }
            userContext.setProfile(profile);
            userContext.setProfiled(true);
            deviceContext.setClockSetting(profile.clock == 'AM/PM' ? 'hh:mm A' : 'HH:mm');

            userContext.setSelectedLanguage(profile.language);

            var code = appContext.application.settings.languages.find((l) => l.name == profile.language);
            if (code != undefined) {
                userContext.setSelectedLanguageCode(code.code);
                userContext.setIso2(code.code);
                try {
                    var locales = moment.locales();
                    if (locales != undefined) {
                        var test = locales.find((l) => l == code.code);
                        if (test != undefined) {
                            moment.locale(code.code);
                        }
                    }
                } catch (e) {
                    //do nothing
                }
            }

            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Home' }],
                })
            );
            contentContext.setActiveMenu('Home');

            // Avoid race condition when page is not yet added
            setTimeout(() => {
                settingsContext.setShowMainMenu(true);
            }, 500);
        }
    };

    const setParentalApprovedLocal = () => {
        setParentalApproved(true);
        selectProfile(profile, profileIndex);
    };

    const editProfile = async (profile, index) => {
        setProfileIndex(index);
        if (profile.locked) {
            await setNewProfile({ ...profile }).then(() => {
                setShowParentalEdit(true);
            });
        } else {
            navigation.dispatch(
                CommonActions.navigate({
                    name: 'Profile_Edit',
                    params: { profile: profile, profileIndex: index },
                })
            );
        }
    };
    const setParentalApprovedLocalEdit = () => {
        setShowParentalEdit(false);
        navigation.dispatch(
            CommonActions.navigate({
                name: 'Profile_Edit',
                params: { profile: profile, profileIndex: profileIndex },
            })
        );
    };
    const resetProfilesModal = async () => {
        setShowReset(true);
    };
    const resetProfiles = async () => {
        setShowReset(false);
        setLoading(true);
        var id = utils.uuidv4();
        var newProfiles = createNewProfiles(id, userContext, appContext);
        await updateProfilesJson(newProfiles, appContext.application, userContext);
        userContext.setAllProfiles(newProfiles);
        setProfiles(newProfiles);
        setLoading(false);
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <View
                focusRepeatContext={focusRepeatContext}
                style={{
                    display: 'flex',
                    width: getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.5 : 0.25),
                    alignItems: 'center',
                    marginBottom: getCorrectHeight(deviceContext, 20),
                }}
            >
                <Pressable
                    style={{
                        margin: getCorrectHeight(deviceContext, 10),
                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11),
                    }}
                    animatorOptions={{
                        type: 'scale',
                        focus: {
                            scale: 1.1,
                            duration: 150,
                        },
                    }}
                    focusOptions={{
                        hasPreferredFocus: index === 0,
                    }}
                    hasPreferredFocus={index === 0}
                    onPress={() => selectProfile(item, index)}
                >
                    <View
                        style={{
                            alignItems: 'center',
                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11),
                        }}
                    >
                        <Image
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            source={{ uri: item.avatar }}
                            style={{
                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11),
                                height: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11),
                                borderRadius: (getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11)) / 2,
                                borderColor: '#fff',
                                borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                            }}
                        ></Image>
                    </View>
                </Pressable>
                <Pressable
                    style={{
                        flexDirection: 'row',
                        width: getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.4 : 0.15),
                        height: getCorrectHeight(deviceContext, 30),
                        ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                    }}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    onPress={() => editProfile(item, index)}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                ...getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                marginRight: getCorrectWidth(deviceContext, 4),
                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.12),
                            }}
                        >
                            {item.name}
                        </Text>
                        <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal')} icon={faEdit} color={getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext).color}></FontAwesomeIcon>
                    </View>
                </Pressable>
            </View>
        );
    };

    const profileWidth = (getRealWidth(deviceContext) / (deviceContext.isPhone ? 2 : 4)) * (deviceContext.isPhone ? (profiles.length == 1 ? 1 : 2) : profiles.length < 4 ? profiles.length : 4);
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {showReset && (
                <Modal
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'reset_profiles'}
                    submitChoice={resetProfiles}
                    submitChoice2={() => setShowReset(false)}
                    setShowModal={() => setShowReset(false)}
                ></Modal>
            )}
            {showExit ? <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'exit'} submitChoice={cancelExit} timerTime={5} /> : null}
            {showParental && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={setParentalApprovedLocal}
                    setShowParental={(e) => {
                        setShowParental(e);
                        setLoading(false);
                    }}
                    profile={profile}
                    type={'access_profile'}
                ></Parental>
            )}
            {showParentalEdit && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={setParentalApprovedLocalEdit}
                    setShowParental={setShowParentalEdit}
                    profile={profile}
                    type={'access_profile'}
                ></Parental>
            )}
            <View style={{ flex: 1 }} focusOptions={{ focusKey: 'screen', nextFocusLeft: 'side-menu' }}>
                {!loading && (
                    <View style={{ flex: 1, paddingBottom: getCorrectHeight(deviceContext, deviceContext.isAndroidTV || deviceContext.isWebTV || deviceContext.isFireTV || deviceContext.isAndroid ? 0 : 50) }}>
                        <View
                            style={{
                                height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 50 : 100),
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: appContext.application.authentication?.profiles?.header.title_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.profiles?.header.title_color,
                                    textAlign: 'center',
                                    lineHeight: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                }}
                            >
                                {lang.getTranslation(userContext, 'whoiswatching')}
                            </Text>
                        </View>
                        <View style={{ flex: 0.3, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {profiles.length < 10 && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: 5,
                                    }}
                                >
                                    <Pressable
                                        style={{
                                            margin: 10,
                                            width: getCorrectWidth(deviceContext, 100),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        focusOptions={{
                                            hasPreferredFocus: false,
                                        }}
                                        hasPreferredFocus={false}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() =>
                                            navigation.dispatch(
                                                CommonActions.navigate({
                                                    name: 'Profile_Add',
                                                })
                                            )
                                        }
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontWeight: 'bold',
                                                    fontSize: getFontSize(deviceContext, 'Small'),
                                                    ...getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'add_profile').toUpperCase()}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            )}
                            {profiles.length > 0 && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Pressable
                                        style={{
                                            margin: 10,
                                            width: getCorrectWidth(deviceContext, 100),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        focusOptions={{
                                            hasPreferredFocus: false,
                                        }}
                                        hasPreferredFocus={false}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => resetProfilesModal()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontWeight: 'bold',
                                                    fontSize: getFontSize(deviceContext, 'Small'),
                                                    ...getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'reset_profiles').toUpperCase()}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            )}
                        </View>
                        {!loading && profiles != null && profiles != undefined && profiles.length > 0 && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    //paddingBottom: deviceContext.isPhone ? 10 : 0, not sure why this is needed so commented Sander
                                    ...isPlatformIos ? {
                                        left: (screenWidth - profileWidth) / 2,
                                    } : {
                                        width: profileWidth
                                    }
                                }}
                            >
                                <FlashList
                                    data={profiles}
                                    estimatedItemSize={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.2)}
                                    numColumns={deviceContext.isPhone ? 2 : profiles.length < 4 ? profiles.length : 4}
                                    horizontal={false}
                                    showsVerticalScrollIndicator={false}
                                    renderItem={renderItem}
                                    initialScrollIndex={0}
                                    type={'grid'}
                                    style={{ flex: 1 }}
                                />
                            </View>
                        )}
                        {loading && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: deviceContext.isPhone ? 100 : 10,
                                }}
                            >
                                <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                            </View>
                        )}
                    </View>
                )}
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
