import React, { useState } from 'react';
import { Image } from 'react-native';
export const RemoteImage = ({ uri, desiredWidth }) => {
    const [desiredHeight, setDesiredHeight] = React.useState(0);

    Image.getSize(uri, (width, height) => {
        setDesiredHeight((desiredWidth / width) * height);
    });

    return (
        <Image
            source={{ uri }}
            style={{
                width: desiredWidth,
                height: desiredHeight,
            }}
        />
    );
};
export const RemoteImageHeight = ({ uri, desiredHeight }) => {
    const [desiredWidth, setDesiredWidth] = React.useState(0);

    Image.getSize(uri, (width, height) => {
        setDesiredWidth((desiredHeight / height) * width);
    });

    return (
        <Image
            source={{ uri }}
            style={{
                width: desiredWidth,
                height: desiredHeight,
                marginVertical: 50,
            }}
        />
    );
};

export const RemoteImageWidth = ({ uri, desiredWidth }) => {
    const [desiredHeight, setDesiredHeight] = React.useState(0);

    Image.getSize(uri, (width, height) => {
        setDesiredHeight((desiredWidth / width) * height);
    });

    return (
        <Image
            source={{ uri }}
            style={{
                width: desiredWidth,
                height: desiredHeight,
                marginVertical: 50,
            }}
        />
    );
};

export const RemoteImageHeightWidth = ({ uri, desiredWidth, desiredHeight, deviceContext }) => {
    if (deviceContext.isWebTV) {
        desiredHeight = desiredHeight * 1.7;
    }
    const [targetSize, setTargetSize] = React.useState(0);
    const [type, setType] = useState('width');

    Image.getSize(uri, (width, height) => {
        if (desiredWidth != 0) {
            setTargetSize((desiredWidth / width) * height);
            setType('width');
        } else {
            setTargetSize((desiredHeight / height) * width);
            setType('height');
        }
    });

    if (type == 'width') {
        return (
            <Image
                source={{ uri }}
                style={{
                    width: desiredWidth,
                    height: targetSize,
                }}
            />
        );
    } else {
        return (
            <Image
                source={{ uri }}
                style={{
                    width: targetSize,
                    height: desiredHeight,
                    marginVertical: 15,
                }}
            />
        );
    }
};
