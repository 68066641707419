import { ImageBackground, Screen, ScreenProps, ScreenStates, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import React, { useCallback, useState } from 'react';
import { Dimensions } from 'react-native';
import Gradient from 'react-native-linear-gradient';
import { AppContext } from '../../application/context/appContext';
import { DeviceContext } from '../../application/context/deviceContext';
import { useFocusEffect, useRoute } from './useFocusEffect';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getRealHeight } from '../../application/styling/realDeviceSizes';

const LinearGradient = withParentContextMapper(Gradient);

const NO_SAFE_AREA_ROUTES = ['Home', 'Details', 'Course', 'Movie', 'Short', 'Podcast', 'Album', 'Program', 'Seasons', 'Album_Player', 'Podcast_Player', 'Radio_Player'];

const ScreenWrapper = ({ children, stealFocus, focusOptions, style, ...rest }: ScreenProps & { stealFocus?: boolean }) => {
    const [screenState, setScreenState] = useState<ScreenStates>('foreground');
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const route = useRoute();

    useFocusEffect(
        useCallback(() => {
            setScreenState('foreground');

            return () => {
                setScreenState('background');
            };
        }, [])
    );

    useFocusEffect(
        useCallback(() => {
            if (isPlatformIos || isPlatformAndroid) {
                if (NO_SAFE_AREA_ROUTES.includes(route.name)) {
                    appContext.setSafeAreaEdges(['right', 'bottom', 'left']);
                } else {
                    appContext.setSafeAreaEdges(['right', 'bottom', 'left', 'top']);
                }
            }
        }, [route])
    );

    return (
        <Screen focusOptions={{ ...focusOptions, screenState, stealFocus, verticalViewportOffset: focusOptions?.verticalViewportOffset ?? 200 }} style={style} {...rest}>
            <LinearGradient
                colors={[appContext.application.theme.app.background_color1, appContext.application.theme.app.background_color2]}
                style={{ flex: 1, minHeight: Dimensions.get('window').height }}
                start={{ x: 0.5, y: 0 }}
            >
                <ImageBackground
                    source={{
                        uri:
                            deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                ? appContext.application.branding.background_images.portrait
                                : appContext.application.branding.background_images.landscape,
                    }}
                    style={{ flex: 1, position: 'absolute', width: '100%', height: '100%' }}
                    imageStyle={{ resizeMode: 'cover' }}
                />
                {children}
                {appContext.modal}
            </LinearGradient>
        </Screen>
    );
};

export default ScreenWrapper;
