import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid } from '@rnv/renative';
import { View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BackHandler, Dimensions, StatusBar, View as RNView } from 'react-native';
import SystemNavigationBar from 'react-native-system-navigation-bar';
import Orientation from '../../../libs/react-native-orientation-locker';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { ContentContext } from '../../context/contentContext';
import { DeviceContext } from '../../context/deviceContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import { manageFavorite } from '../../data/account';
import { WebView } from 'react-native-webview';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getRealWidth, getRealHeight } from '../../styling/realDeviceSizes';
import Pressable from '../components/pressable/pressable';
import { getFocusStyling } from '../components/helpers/helper';
import { getFontSize } from '../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import Text from '../components/text';
import lang from '../../languages/languages';

const Screen_ = ({ navigation, route, focusContext, type }: any): React.ReactElement => {
    const settingsContext = React.useContext(SettingsContext);
    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const insets = useSafeAreaInsets();
    const wrapperRef = React.useRef<RNView>(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isFavorite, setIsFavorite] = React.useState(false);
    const isLandscape = deviceContext.isPhone || deviceContext.isPwaVertical;
    const [orientationChanged, setOrientationChanged] = React.useState(isLandscape ? false : true);
    const [urlAndParams, setUrlAndParams] = useState('');

    //For TV to exit with back button
    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                navigation.goBack();
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);

        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);

    const startPlayer = async (type: any) => {
        switch (type) {
            case 'Arcamedics':
                setUrlAndParams(
                    'https://cloudtv.akamaized.net/donotremove/tvms/games/arcademics_v2.html?slug=' +
                        contentContext.gameData.game.game_id +
                        '&affiliate=' +
                        contentContext.gameData.game.game_affiliate +
                        '&user=' +
                        userContext.user.customer._id +
                        '&name=' +
                        encodeURI(userContext.user.customer.first_name) +
                        '&game_id=' +
                        contentContext.gameData.game.game_id +
                        '&iso2=' +
                        userContext.iso2
                );
                //setIsFavorite(favoriteStatus( type, contentContext.channelData.channel._id,userContext));
                // setRecommendations(contentContext.channelData.channel.tags, userContext.recommendations, appContext.application, userContext);
                setIsLoaded(true);
                break;
        }
    };

    //do we need this ? i dont thinks so it makes the stream start 2x at startup not very user friendly
    useEffect(() => {
        startPlayer(contentContext.gameData?.game?.provider);
    }, [navigation]);

    function fixRNDimensions(orientation: OrientationType) {
        const windowDim = Dimensions.get('window');
        const screenDim = Dimensions.get('screen');
        if ((orientation.match(/LANDSCAPE/i) && windowDim.width < windowDim.height) || (orientation.match(/PORTRAIT/i) && windowDim.width > windowDim.height)) {
            Dimensions.set({
                screen: {
                    ...screenDim,
                    width: screenDim.height,
                    height: screenDim.width,
                },
                window: {
                    ...windowDim,
                    width: windowDim.height,
                    height: windowDim.width,
                },
            });
        }
    }

    React.useEffect(() => {
        if (deviceContext.isPhone) {
            Orientation.lockToLandscape();
            fixRNDimensions('landscape-primary');
            setOrientationChanged(true);
        }
        navigation.addListener('beforeRemove', (e) => {
            if (deviceContext.isPhone) {
                e.preventDefault();
                Orientation.lockToPortrait();
                fixRNDimensions('portrait-primary');
                navigation.dispatch(e.data.action);
            }
        });
    }, [navigation]);

    React.useEffect(() => {
        settingsContext.setShowMainMenu(false);
        if (deviceContext.isPhone || deviceContext.isTablet) {
            SystemNavigationBar.fullScreen(true);
        }
        return () => {
            settingsContext.setShowMainMenu(true);
            if (deviceContext.isPhone || deviceContext.isTablet) {
                SystemNavigationBar.fullScreen(false);
            }
        };
    }, [navigation]);

    const manageFavoriteLocal = () => {
        switch (type) {
            case 'Channels':
                manageFavorite(contentContext, 'Channels', contentContext.channelData.channel, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Cams':
                manageFavorite(contentContext, 'Cams', contentContext.camData.cam, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Radio':
                manageFavorite(contentContext, 'Radio', contentContext.radioData.radio, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Movies':
                manageFavorite(contentContext, 'Movies', contentContext.movieData.movie, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Shorts':
                manageFavorite(contentContext, 'Shorts', contentContext.movieData.movie, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Series':
                manageFavorite(contentContext, 'Series', contentContext.seriesData.series, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Courses':
                manageFavorite(contentContext, 'Courses', contentContext.courseData.course, appContext.application, !isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            default:
                break;
        }
    };

    const onBack = (skipHideControls?) => {
        setIsLoaded(false);
        setTimeout(() => {
            navigation.goBack();
        }, 100);
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GAMES_WEBVIEW, duration);
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        onBack(true);
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    const insetFromBottom = isLandscape ? insets.bottom : 0;
    const styles = isLandscape
        ? {
              width: isPlatformAndroid ? '100%' : Dimensions.get('screen').width - insetFromBottom,
              height: deviceContext.isIos ? Dimensions.get('window').height - insetFromBottom : '100%', //done cause android this not have full height on android phone
          }
        : {
              justifyContent: 'center',
              alignItems: 'center',
          };

    return (
        //@ts-ignore
        <View ref={wrapperRef} style={styles} focusContext={focusContext}>
            {(deviceContext.isAndroid || deviceContext.isIos) && (
                <>
                    <View style={{ width: getRealWidth(deviceContext), justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <Pressable
                            hasPreferredFocus={true}
                            focusOptions={{ focusKey: 'back', nextFocusUp: 'playPause' }}
                            style={{ padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 20 : 10), backgroundColor: '#000000' }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onBack(true)}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#ffffff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraExtraLarge' : 'ExtraLarge')} style={{}}></FontAwesomeIcon>
                                {/* <Text
                                    style={{
                                        marginLeft: 10,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#FFFFFF',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'back')}
                                </Text> */}
                            </View>
                        </Pressable>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                paddingLeft: getCorrectWidth(deviceContext, 5),
                                backgroundColor: '#333333',
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                    color: '#FFFFFF',
                                }}
                            >
                                {contentContext.gameData.game.name}
                            </Text>
                        </View>
                    </View>
                    <StatusBar hidden />
                    {orientationChanged && isLoaded && urlAndParams != '' && deviceContext.isPhone && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth),
                                    height: getRealHeight(deviceContext) - getCorrectWidth(deviceContext, deviceContext.isAndroid ? 50 : 100),
                                    backgroundColor: '#000000',
                                    position: deviceContext.isAndroid ? 'relative' : 'absolute',
                                    left: deviceContext.isAndroid ? 0 : -55,
                                    top: deviceContext.isAndroid ? 0 : 50,
                                },
                            ]}
                        >
                            <WebView
                                source={{ uri: urlAndParams }}
                                allowsInlineMediaPlayback={true}
                                ignoreSilentHardwareSwitch={true}
                                startInLoadingState={true}
                                allowsFullscreenVideo={true}
                                autoManageStatusBarEnabled={true}
                                textZoom={100}
                                style={{
                                    border: 'none',
                                    backgroundColor: '#000000',
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            />
                        </View>
                    )}
                    {orientationChanged && isLoaded && urlAndParams != '' && deviceContext.isTablet && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext)- getCorrectWidth(deviceContext, deviceContext.isAndroid ?30 : 50),
                                    backgroundColor: '#000000',
                                },
                            ]}
                        >
                            <WebView
                                source={{ uri: urlAndParams }}
                                allowsInlineMediaPlayback={true}
                                ignoreSilentHardwareSwitch={true}
                                startInLoadingState={true}
                                allowsFullscreenVideo={true}
                                autoManageStatusBarEnabled={true}
                                textZoom={100}
                                style={{
                                    border: 'none',
                                    backgroundColor: '#000000',
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            />
                        </View>
                    )}
                </>
            )}
            {deviceContext.isWebTV && (
                <>
                    <View style={{ width: getRealWidth(deviceContext), justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <Pressable
                            hasPreferredFocus={true}
                            focusOptions={{ focusKey: 'back', nextFocusUp: 'playPause' }}
                            style={{ padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 20 : 10), backgroundColor: '#000000' }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onBack(true)}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#ffffff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraExtraLarge' : 'ExtraLarge')} style={{}}></FontAwesomeIcon>
                                <Text
                                    style={{
                                        marginLeft: 10,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#FFFFFF',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'back')}
                                </Text>
                            </View>
                        </Pressable>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                paddingLeft: getCorrectWidth(deviceContext, 5),
                                backgroundColor: '#333333',
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                    color: '#FFFFFF',
                                }}
                            >
                                {contentContext.gameData.game.name}
                            </Text>
                        </View>
                    </View>
                    {isLoaded && urlAndParams != '' && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, 40),
                                    backgroundColor: '#000000',
                                },
                            ]}
                        >
                            <iframe
                                title="myiframe"
                                id="myiframe"
                                sandbox="allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                                scrolling="true"
                                seamless
                                allow="fullscreen"
                                style={{ border: 'none', display: 'block', height: '100vh', width: '100%' }}
                                loading="lazy"
                                src={urlAndParams}
                            ></iframe>
                        </View>
                    )}
                </>
            )}
        </View>
    );
};
export default Screen_;
