import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faCheck, faChevronsLeft, faChevronsRight, faClapperboardPlay, faHeart, faPlus, faRecordVinyl, faRotateLeft, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import moment from 'moment';
import React from 'react';
import { CreateListRenderItemInfo, FlashList, Image as ImageNormal, View } from '@scriptx-com/xtv-toolkit';
import { FlashList as FlashList2 } from '@scriptx-com/flash-list';
import { favoriteStatus, payperviewStatus, recordStatus, remindStatus, watchingStatus } from '../../../../data/account';
import FocusHelper from '../../../../focushelper/FocusHelper';
import lang from '../../../../languages/languages';
import { getCorrectLeftMargin } from '../../../../styling/appLeftMargin';
import { colorShade } from '../../../../styling/colorShade';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFontSize } from '../../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import DownloadButton from '../../../components/downloadbutton/downloadbutton';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../components/helpers/helper';
import Image from '../../../components/image/image';
import Pressable from '../../../components/pressable/pressable';
import { RemoteImageHeightWidth } from '../../../components/remoteimage/remoteimage';
import Text from '../../../components/text/index';
import { checkMaxDays, getLiveImage } from '../../players/helpers/functions';
import Widescreen from '../../shared/widescreen';
import { GetScrollElement } from '../../rails/helpers/views';
import { isFactorMobile, isFactorTv, isPlatformTizen, isPlatformWebos } from '@rnv/renative';

export const BackgroundImage = ({ item, type, deviceContext, appContext, contentContext, program, channel }) => {
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 1,
            }}
        >
            <View
                style={{
                    width: getRealWidth(deviceContext),
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {type == 'Program' && getProgramImage(program != undefined ? program : contentContext.channelData?.program, channel != undefined ? channel : contentContext.channelData?.channel, deviceContext, appContext)}
                {type == 'Recording' && getProgramImage(contentContext.recordingData?.program, contentContext.recordingData?.channel, deviceContext, appContext)}
                {type == 'Movie' && getMovieImage(item, deviceContext, appContext)}
                {type == 'Seasons' && getSeriesImage(item, deviceContext, appContext, contentContext)}
                {type == 'Course' && (
                    <ImageNormal
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                        source={{ uri: appContext.cloudUrl + item.images?.widescreen }}
                    />
                )}
                {type == 'Short' && (
                    <ImageNormal
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                        source={{
                            uri: item.images?.background,
                        }}
                    />
                )}
            </View>
        </View>
    );
};
export const LogoRow = ({ item, type, deviceContext, appContext, contentContext, styling, channel }) => {
    if (type == 'Program') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View
                    style={{
                        backgroundColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                        borderRadius: 100,
                        flexDirection: 'row',
                        width: getCorrectWidth(deviceContext, 50),
                        height: getCorrectWidth(deviceContext, 50),
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Image
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        source={{ uri: appContext.cloudUrl + item?.images?.square }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    ></Image>
                </View>
                <View>
                    {item.program?.l != undefined && item.program?.l != '' && (
                        <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                            <RemoteImageHeightWidth uri={item.program?.l} desiredWidth={0} desiredHeight={getRealHeight(deviceContext) * 0.15} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(item.program?.l == undefined || item.program?.l == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View>
                    <Image
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'contain'}
                        source={{ uri: appContext.cloudUrl + contentContext.recordingData.channel.images?.square }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    ></Image>
                </View>
                <View>
                    {contentContext.recordingData.program?.l != undefined && contentContext.recordingData.program?.l != '' && (
                        <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                            <RemoteImageHeightWidth uri={contentContext.recordingData.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={100} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(contentContext.recordingData.program?.l == undefined || contentContext.recordingData.program?.l == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.recordingData.program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View>
                    {item.images.logo != undefined && item.images.logo != '' && (
                        <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                            <RemoteImageHeightWidth uri={appContext.cloudUrl + item?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={100} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(item.images.logo == undefined || item.images.logo == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    }
};
export const StatusTimeRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Program') {
        var currentTime = moment().unix();
        var isLive = item.program.s < currentTime && item.program.e > currentTime;
        var isPast = item.program.s < currentTime && item.program.e < currentTime;
        var isRecorded = recordStatus(item.program.i, userContext);
        var isReminded = remindStatus(item.program.i, userContext);
        var recordingDone = false;
        var recordingPlanned = false;
        var recordingProgress = false;
        var currentTime = moment().unix();
        if (currentTime > item.program?.e) {
            recordingDone = true;
        } else if (currentTime > item.program?.s && currentTime < item.program?.e) {
            recordingProgress = true;
        } else {
            recordingPlanned = true;
        }

        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    {isLive && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingProgress && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recording').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingPlanned && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'planned').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingDone && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isReminded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'restart').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {(isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || isReminded || isRecorded) && (
                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>
                    )}
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(item.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(item.program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                        <Text
                            style={{
                                backgroundColor: 'crimson',
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                        </Text>
                    </View>

                    <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                        <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                    </View>

                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(contentContext.recordingData.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.recordingData.program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const MetaRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Program') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <View>{getTagsChannels(item.program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                    {item.program?.p != undefined && item.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.program?.p != undefined && item.program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {item.program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {contentContext.recordingData.program.mt?.ye != undefined && contentContext.recordingData.program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.recordingData.program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {contentContext.recordingData.program.mt?.ye != undefined &&
                        contentContext.recordingData.program.mt?.ye != '' &&
                        contentContext.recordingData.program?.mt?.ge != undefined &&
                        contentContext.recordingData.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {contentContext.recordingData.program?.mt?.ge != undefined && contentContext.recordingData.program?.mt?.ge.length > 0 && (
                        <View>{getTagsChannels(contentContext.recordingData.program?.mt?.ge, appContext, deviceContext, userContext)}</View>
                    )}

                    {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {contentContext.recordingData.program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {item.released != undefined && item.released != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.released}
                            </Text>
                        </View>
                    )}
                    {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.tags != undefined && item.tags.length > 0 && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                    {item.tags != undefined && item.tags.length > 0 && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.runtime != undefined && item.runtime != '' && (
                        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                {'h:'}
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                {'m'}
                            </Text>
                        </View>
                    )}
                    {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.rating != undefined && item.rating != '' && (
                        <Text
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {item.rating}
                        </Text>
                    )}
                </View>
            </View>
        );
    }
};
export const DescriptionRow = ({ item, type, deviceContext, userContext, contentContext, styling }) => {
    if (type == 'Program') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.4 }}>
                <View>
                    <Text
                        //  numberOfLines={5}
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            color: styling.texts.subtext_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {item.program?.d}
                    </Text>
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.4 }}>
                <View>
                    <Text
                        //  numberOfLines={5}
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            color: styling.texts.subtext_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {contentContext.recordingData.program?.d}
                    </Text>
                </View>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.4 }}>
                <View>
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            //  numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            // numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Seasons') {
        var watchlist = watchingStatus(type, item._id, userContext);
        var episodeIndex = 0;
        var seasonIndex = 0;
        if (watchlist != undefined) {
            episodeIndex = watchlist.continue.episode_index ?? 0;
            seasonIndex = watchlist.continue.season_index ?? 0;
        }
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.4 }}>
                <View>
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            //numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            // numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    }
};

export const ProgressRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    var progress = 0;
    var remaining = 0;
    if (type == 'Program') {
        var currentTime = moment().unix();
        var totalProgram = item.program?.e - item.program?.s;
        progress = ((currentTime - item.program?.s) / totalProgram) * 100;
        var isLive = item.program.s < currentTime && item.program.e > currentTime;
        remaining = totalProgram - (currentTime - item.program?.s);
        if (!isLive) {
            progress = 0;
        }
    }
    if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Recording') {
        var watching = watchingStatus(type, item._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            progress = Math.round((watching.position / watching.duration) * 100);
            remaining = watching.duration - watching.position;
        }
    }

    if (progress > 0) {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.22, marginTop: getCorrectHeight(deviceContext, 15) }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: appContext.application.theme.progresses.base,
                            height: getCorrectHeight(deviceContext, 2),
                            width: getRealWidth(deviceContext) * 0.22,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: appContext.application.theme.progresses.progress,
                                height: getCorrectHeight(deviceContext, 2),
                                width: progress + '%',
                            }}
                        ></View>
                    </View>
                    <View style={{ marginLeft: getCorrectWidth(deviceContext, 2), justifyContent: 'center' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                            }}
                        >
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('H')}
                            {'h:'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('mm')}
                            {'m'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('ss')}
                            {'s'} {lang.getTranslation(userContext, 'left')}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};

export const ButtonRow = ({
    item,
    type,
    deviceContext,
    appContext,
    userContext,
    contentContext,
    styling,
    onPressPlay,
    onPressTrailer,
    onPressReplay,
    onPressPurchase,
    onPressFavorite,
    onPressWatchlist,
    onPressContinue,
    onPressRecording,
    onPressRemind,
    onPressPlayCatchupTV,
    onPressBack,
    onPressPlaySeason,
    onPressPlayLesson,
    onPressRecordingDelete,
    onPressPlayRecording,
    focusContext,
}) => {
    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;
    var isFavorite = favoriteStatus(type, item._id, userContext);
    var watchlist = watchingStatus(type, item._id, userContext);
    var isWatchlist = watchlist == undefined ? false : true;
    var hasProgress = watchlist != undefined && watchlist?.position > 0 ? true : false;
    var progress = watchlist?.position;

    var remainingPayPerViewDays = 0;
    if (isPayPerView) {
        var now = moment().unix();
        var diff = now - payperviewStatus(type, item._id, userContext).timePayPerViewStart;
        remainingPayPerViewDays = item.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
        if (remainingPayPerViewDays <= 0) {
            isPayPerView = false;
        }
    }
    switch (type) {
        case 'Movie':
            return (
                <View focusContext={focusContext} style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                    {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlay(item)}
                            text={'play_movie'}
                        ></ButtonTextPlusIcon>
                    )}

                    {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                        <ButtonContinuePlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            season={undefined}
                            episode={undefined}
                            lessonIndex={undefined}
                            progress={0}
                        ></ButtonContinuePlusIcon>
                    )}
                    {isPayPerViewEnabled && isPayPerView && (
                        <View
                            style={{
                                marginRight: getCorrectHeight(deviceContext, 7),
                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                borderRadius: 100,
                                justifyContent: 'center',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')} {lang.getTranslation(deviceContext, 'remaining')}
                            </Text>
                        </View>
                    )}
                    {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>
                    )}
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}

                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {(item.streams.movies.trailer != undefined || (item.trailer != undefined && item.trailer != '')) && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressTrailer(item)} icon={faClapperboardPlay}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressFavorite(isFavorite, item)} icon={isFavorite ? faHeartSolid : faHeart}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressWatchlist(isWatchlist, item)} icon={isWatchlist ? faCheck : faPlus}></ButtonIcon>
                    )}
                    {item.streams.movies.allow_downloads && (deviceContext.isTablet || deviceContext.isWebTV) && (
                        <>{(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && <DownloadButton styling={styling} screenName={type} item={item}></DownloadButton>}</>
                    )}
                </View>
            );
            break;
        case 'Course':
            var lessonIndex = 0;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.lesson_index ?? 0;
            }
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonCoursePlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlayLesson(lessonIndex)}
                            text={'play_course'}
                            lesson={lessonIndex}
                        ></ButtonCoursePlusIcon>
                    )}

                    {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                        <ButtonContinuePlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            season={undefined}
                            episode={undefined}
                            lessonIndex={lessonIndex}
                            progress={0}
                        ></ButtonContinuePlusIcon>
                    )}
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPayPerViewEnabled && isPayPerView && (
                        <View
                            style={{
                                marginRight: getCorrectHeight(deviceContext, 7),
                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                borderRadius: 100,
                                justifyContent: 'center',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')} {lang.getTranslation(deviceContext, 'remaining')}
                            </Text>
                        </View>
                    )}
                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {hasProgress || (isPayPerViewEnabled && isPayPerView && <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>)}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressFavorite(isFavorite, item)} icon={isFavorite ? faHeartSolid : faHeart}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressWatchlist(isWatchlist, item)} icon={isWatchlist ? faCheck : faPlus}></ButtonIcon>
                    )}
                </View>
            );
            break;
        case 'Short':
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlay(item)}
                            text={'play_short'}
                        ></ButtonTextPlusIcon>
                    )}

                    {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                        <ButtonContinuePlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            season={undefined}
                            episode={undefined}
                            lessonIndex={undefined}
                            progress={0}
                        ></ButtonContinuePlusIcon>
                    )}
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPayPerViewEnabled && isPayPerView && (
                        <View
                            style={{
                                marginRight: getCorrectHeight(deviceContext, 7),
                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                borderRadius: 100,
                                justifyContent: 'center',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')} {lang.getTranslation(deviceContext, 'remaining')}
                            </Text>
                        </View>
                    )}
                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {hasProgress || (isPayPerViewEnabled && isPayPerView && <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>)}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressFavorite(isFavorite, item)} icon={isFavorite ? faHeartSolid : faHeart}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressWatchlist(isWatchlist, item)} icon={isWatchlist ? faCheck : faPlus}></ButtonIcon>
                    )}
                    {item.streams.shorts.allow_downloads && (deviceContext.isTablet || deviceContext.isWebTV) && (
                        <>{(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && <DownloadButton styling={styling} screenName={type} item={item}></DownloadButton>}</>
                    )}
                </View>
            );
            break;
        case 'Seasons':
            var episodeIndex = 0;
            var seasonIndex = 0;
            hasProgress = watchlist?.continue != undefined;
            if (watchlist != undefined && hasProgress) {
                episodeIndex = watchlist.continue.episode_index;
                seasonIndex = watchlist.continue.season_index;
            }
            if (watchlist?.continue?.episodes != undefined) {
                var episode = watchlist?.continue?.episodes.find((e) => (e._id = contentContext.seriesData.seasons[seasonIndex].episodes[episodeIndex]._id));
                progress = (episode?.position / episode?.duration) * 100 || 0;
            }

            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonTextPlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)}
                            text={'play'}
                            extra={undefined}
                            backgroundColor={undefined}
                        ></ButtonTextPlusIcon>
                    )}

                    {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                        <ButtonContinuePlusIcon
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)}
                            text={'continue'}
                            season={seasonIndex}
                            episode={episodeIndex}
                            lessonIndex={undefined}
                            progress={progress}
                        ></ButtonContinuePlusIcon>
                    )}
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPayPerViewEnabled && isPayPerView && (
                        <View
                            style={{
                                marginRight: getCorrectHeight(deviceContext, 7),
                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                borderRadius: 100,
                                justifyContent: 'center',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')} {lang.getTranslation(deviceContext, 'remaining')}
                            </Text>
                        </View>
                    )}
                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {item.trailer != undefined && item.trailer != '' && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressTrailer(item)} icon={faClapperboardPlay}></ButtonIcon>
                    )}
                    {hasProgress || (isPayPerViewEnabled && isPayPerView && <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>)}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressFavorite(isFavorite, item)} icon={isFavorite ? faHeartSolid : faHeart}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item, seasonIndex, episodeIndex)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                </View>
            );
            break;
        case 'Program':
            var currentTime = moment().unix();
            var isLive = item.program.s < currentTime && item.program.e > currentTime;
            var isPast = item.program.s < currentTime && item.program.e < currentTime;
            var isFuture = item.program.s > currentTime && item.program.e > currentTime;
            var isRecorded = recordStatus(item.program.i, userContext);
            var isReminded = remindStatus(item.program.i, userContext);
            var isFavorite = favoriteStatus(type, contentContext.channelData.channel._id, userContext);
            var watchlist = watchingStatus(type, contentContext.channelData.channel._id, userContext);
            var isWatchlist = watchlist == undefined ? false : true;

            var recordingDone = false;
            var recordingPlanned = false;
            var recordingProgress = false;
            var currentTime = moment().unix();
            if (currentTime > item.program?.e) {
                recordingDone = true;
            } else if (currentTime > item.program?.s && currentTime < item.program?.e) {
                recordingProgress = true;
            } else {
                recordingPlanned = true;
            }

            isPayPerViewEnabled = contentContext.channelData.channel.payperview?.enable;
            isPayPerView = payperviewStatus(type, contentContext.channelData.channel._id, userContext).isPayPerView;

            remainingPayPerViewDays = 0;
            if (isPayPerView) {
                var now = moment().unix();
                var diff = now - payperviewStatus(type, contentContext.channelData.channel._id, userContext).timePayPerViewStart;
                remainingPayPerViewDays = contentContext.channelData.channel.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
                if (remainingPayPerViewDays <= 0) {
                    isPayPerView = false;
                }
            }

            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {((isLive && !isPayPerViewEnabled && !isPayPerView) || (isLive && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlay(contentContext.channelData.channel)}
                            text={'watch_now'}
                        ></ButtonTextPlusIcon>
                    )}

                    {isFuture && isRecorded && recordingDone && (
                        <ButtonTextPlusIcon
                            backgroundColor={'crimson'}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlayRecording(item)}
                            text={'watch_recording'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPast && isRecorded && recordingDone && (
                        <ButtonTextPlusIcon
                            backgroundColor={'crimson'}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlayRecording(item)}
                            text={'watch_recording'}
                        ></ButtonTextPlusIcon>
                    )}
                    {((isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && !isPayPerViewEnabled && !isPayPerView) ||
                        (isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && isPayPerViewEnabled && isPayPerView)) && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlayCatchupTV('live', contentContext.channelData.channel, contentContext.channelData.programIndex)}
                            text={'restart'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                        <>
                            {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                <ButtonTextPlusIcon
                                    backgroundColor={undefined}
                                    hasPreferredFocus={false}
                                    focusContext={focusContext}
                                    extra={''}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    styling={styling}
                                    onPress={() => onPressPlayCatchupTV('normal', contentContext.channelData.channel, contentContext.channelData.programIndex)}
                                    text={'watch_now'}
                                ></ButtonTextPlusIcon>
                            )}
                        </>
                    )}
                    {isFuture && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressRemind(isReminded)}
                            text={isReminded ? 'remove_reminder' : 'set_reminder'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}
                    {isPayPerViewEnabled && isPayPerView && (
                        <View
                            style={{
                                marginRight: getCorrectHeight(deviceContext, 7),
                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                borderRadius: 100,
                                justifyContent: 'center',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')} {lang.getTranslation(deviceContext, 'remaining')}
                            </Text>
                        </View>
                    )}
                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, contentContext.channelData.channel)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, contentContext.channelData.channel)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                    {contentContext.channelData.channel.interactivetv.recordings_enabled && isFuture && checkMaxDays(contentContext, userContext) && userContext.userId.indexOf('_review') < 0 && (
                        <>
                            {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                <ButtonIcon
                                    focusContext={focusContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressRecording(isRecorded)}
                                    icon={isRecorded ? faTrash : faRecordVinyl}
                                    style={{ color: 'crimson' }}
                                ></ButtonIcon>
                            )}
                        </>
                    )}
                </View>
            );
            break;
        case 'Recording':
            return (
                <View focusContext={focusContext} style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                    <ButtonTextPlusIcon
                        backgroundColor={'crimson'}
                        hasPreferredFocus={true}
                        focusContext={focusContext}
                        extra={''}
                        deviceContext={deviceContext}
                        appContext={appContext}
                        userContext={userContext}
                        styling={styling}
                        onPress={() => onPressPlayRecording(item)}
                        text={'watch_recording'}
                    ></ButtonTextPlusIcon>
                    <ButtonTextPlusIcon
                        backgroundColor={undefined}
                        hasPreferredFocus={true}
                        focusContext={focusContext}
                        extra={''}
                        deviceContext={deviceContext}
                        appContext={appContext}
                        userContext={userContext}
                        styling={styling}
                        onPress={() => onPressRecordingDelete(item)}
                        text={'delete_recording'}
                    ></ButtonTextPlusIcon>

                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                </View>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};
export const ButtonRowLiveTV = ({ item, type, deviceContext, appContext, userContext, contentContext, styling, onPressPlay, onPressPurchase, onPressFavorite, onPressWatchlist, onPressRecording, onPressRemind, onPressBack, focusContext }) => {
    var isFavorite = favoriteStatus(type, item._id, userContext);
    var watchlist = watchingStatus(type, item._id, userContext);
    var isWatchlist = watchlist == undefined ? false : true;
    switch (type) {
        case 'Program':
            var currentTime = moment().unix();
            var isLive = item.program.s < currentTime && item.program.e > currentTime;
            var isPast = item.program.s < currentTime && item.program.e < currentTime;
            var isFuture = item.program.s > currentTime && item.program.e > currentTime;
            var isRecorded = recordStatus(item.program.i, userContext);
            var isReminded = remindStatus(item.program.i, userContext);
            var isFavorite = favoriteStatus(type, contentContext.channelData.channel._id, userContext);
            var watchlist = watchingStatus(type, contentContext.channelData.channel._id, userContext);
            var isWatchlist = watchlist == undefined ? false : true;

            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {isLive && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={true}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlay(item)}
                            text={'watch_now'}
                        ></ButtonTextPlusIcon>
                    )}

                    {isFuture && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressRemind(isReminded)}
                            text={isReminded ? 'remove_reminder' : 'set_reminder'}
                        ></ButtonTextPlusIcon>
                    )}
                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            backgroundColor={undefined}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            extra={''}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                        ></ButtonTextPlusIcon>
                    )}
                    <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressBack} icon={faArrowLeft}></ButtonIcon>
                    {/* {appContext.application.settings.allow_favorites && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressFavorite(isFavorite, item.channel)} icon={isFavorite ? faHeartSolid : faHeart}></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon focusContext={focusContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressWatchlist(isWatchlist, item)} icon={isWatchlist ? faCheck : faPlus}></ButtonIcon>
                    )}
                    {contentContext.channelData.channel.interactivetv.recordings_enabled && isFuture && checkMaxDays(contentContext, userContext) && userContext.userId.indexOf('_review') < 0 && (
                        <ButtonIcon
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressRecording(isRecorded)}
                            icon={isRecorded ? faTrash : faRecordVinyl}
                            style={{ color: 'crimson' }}
                        ></ButtonIcon>
                    )} */}
                </View>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};

export const ActorsRowText = ({ item, styling, appContext, deviceContext, userContext, type }) => {
    if (type == 'Seasons' && item.seasons?.length > 0) {
        item = item.seasons[0];
    }
    if (type != 'Program' && type != 'Recording') {
        if (item.actors != undefined && item.actors.length > 0) {
            if (item.actors[0].image == undefined || item.actors[0].image == '') {
                return (
                    <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) * 0.3, marginTop: getCorrectHeight(deviceContext, 10), opacity: 0.7 }}>
                        <Text
                            numberOfLines={2}
                            style={{
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                includeFontPadding: false,
                            }}
                        >
                            {lang.getTranslation(userContext, 'actors')}:{' '}
                            {item.actors.map((actor, index) => {
                                return actor.name + (index == item.actors.length - 1 ? '' : ', ');
                            })}
                        </Text>
                    </View>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    } else {
        if (item.program.mt?.cr?.ac != undefined && item.program.mt?.cr?.ac.length > 0) {
            if (item.program.mt?.cr?.ac[0].photo == null) {
                return (
                    <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) * 0.3, marginTop: getCorrectHeight(deviceContext, 10), opacity: 0.7 }}>
                        <Text
                            numberOfLines={2}
                            style={{
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                includeFontPadding: false,
                            }}
                        >
                            {item.program.mt?.cr?.ac.map((actor, index) => {
                                return actor.name + (index == item.program.mt?.cr?.ac.length - 1 ? '' : ', ');
                            })}
                        </Text>
                    </View>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }
};
export const ActorsRowImages = ({ item, styling, appContext, deviceContext, userContext, type, focusContext }) => {
    if (type == 'Seasons' && item.seasons?.length > 0) {
        item = item.seasons[0];
    }
    if (type != 'Program' && type != 'Recording') {
        if (item.actors != undefined && item.actors.length > 0) {
            if (item.actors[0].image == undefined || item.actors[0].image == '') {
                return <></>;
            } else {
                return (
                    <View style={{ flex: 1, flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 60) }} focusContext={focusContext}>
                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'cast_and_crew')}
                            </Text>
                        </View>

                        <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) }}>
                            <FlashList
                                type="row"
                                data={item.actors}
                                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item, focusRepeatContext, index, target }) => renderActor({ item, focusRepeatContext, index, target }, appContext, deviceContext, focusContext)}
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                            />
                        </View>
                    </View>
                );
            }
        } else {
            return <></>;
        }
    } else {
        if (item.program.mt?.cr?.ac != undefined && item.program.mt?.cr?.ac.length > 0) {
            if (item.program.mt?.cr?.ac[0].photo == null) {
                return <></>;
            } else {
                return (
                    <View style={{ flex: 1, flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 60) }} focusContext={focusContext}>
                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'cast_and_crew')}
                            </Text>
                        </View>

                        <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) }}>
                            <FlashList
                                type="row"
                                data={item.program.mt?.cr?.ac}
                                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item, focusRepeatContext, index, target }) => renderActor({ item, focusRepeatContext, index, target }, appContext, deviceContext, focusContext)}
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                            />
                        </View>
                    </View>
                );
            }
        } else {
            return <></>;
        }
    }
};
export const EpisodeRails = ({ styling, deviceContext, userContext, appContext, focusContext, playEpisode, screenName, season, seasonIndex, watchingSeasonIndex }: any) => {
    const rails = React.useRef<FlashList2<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === season.episodes.length) {
            rails.current.scrollToEnd({ animated: true });
        } else {
            rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    return (
        <View
            style={{
                flex: 1,
                paddingBottom: getCorrectHeight(deviceContext, 15),
                marginBottom: getCorrectHeight(deviceContext, 20),
                justifyContent: 'center',
                width: getRealWidth(deviceContext) - getCorrectLeftMargin(deviceContext, appContext, screenName) - getCorrectWidth(deviceContext, global.menuWidth),
                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
            }}
            focusContext={focusContext}
        >
            <View
                style={[
                    {
                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                        marginVertical: getCorrectHeight(deviceContext, 10),
                    },
                    isPlatformTizen || isPlatformWebos ? { flexShrink: 1 } : { flex: 1 },
                ]}
            >
                <Text
                    style={{
                        margin: getCorrectWidth(deviceContext, 3),
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Large'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, 'season')} {seasonIndex + 1}
                </Text>
            </View>

            <FlashList
                type="row"
                ref={rails}
                data={season?.episodes}
                onViewableItemsChanged={onViewableItemsChanged}
                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item, focusRepeatContext, index, target }) => renderEpisode({ item, focusRepeatContext, index, target }, styling, deviceContext, focusContext, playEpisode, seasonIndex)}
                initialScrollIndex={0}
                style={{ flex: 1 }}
                focusOptions={{
                    autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                    autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                }}
            />
            {deviceContext.isWebTV && !deviceContext.isPwa && season.episodes.length > 0 && (
                <GetScrollElement height={getCorrectHeight(deviceContext, 100)} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
            )}
        </View>
    );
};
export const LessonRails = ({ course, styling, deviceContext, userContext, appContext, focusContext, playLesson, contentContext, screenName }) => {
    const rails = React.useRef<FlashList2<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === course.length) {
            rails.current.scrollToEnd({ animated: true });
        } else {
            rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };
    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    var watching = watchingStatus('Seasons', contentContext.seriesData.series._id, userContext);
    var selectedSeasonIndex = 0;
    if (watching != undefined && watching.continue != undefined) {
        selectedSeasonIndex = watching.continue.season_index;
    }
    if (course.lessons != undefined && course.lessons.length > 0) {
        return (
            <View style={{ flex: 1, flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 40) }} focusContext={focusContext}>
                <View style={{}}>
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            marginBottom: getCorrectHeight(deviceContext, 10),
                            marginTop: getCorrectHeight(deviceContext, 10),
                        }}
                    >
                        {lang.getTranslation(userContext, 'lessons')}
                    </Text>
                </View>
                <View style={{ flex: 1, marginLeft: -getCorrectWidth(deviceContext, 2) }}>
                    <View
                        style={{
                            flex: 1,
                            paddingBottom: getCorrectHeight(deviceContext, 15),
                            marginBottom: getCorrectHeight(deviceContext, 20),
                            justifyContent: 'center',
                            width: getRealWidth(deviceContext) - getCorrectLeftMargin(deviceContext, appContext, screenName),
                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                        }}
                        focusContext={focusContext}
                    >
                        <FlashList
                            type="grid"
                            numColumns={4}
                            ref={rails}
                            data={course.lessons}
                            onViewableItemsChanged={onViewableItemsChanged}
                            estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                            drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                            horizontal={false}
                            showsHorizontalScrollIndicator={false}
                            renderItem={({ item, focusRepeatContext, index, target }) => renderLesson({ item, focusRepeatContext, index, target }, styling, deviceContext, focusContext, playLesson, selectedSeasonIndex, appContext)}
                            initialScrollIndex={0}
                            style={{ flex: 1 }}
                            focusOptions={{
                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};

///extra functions

export const ButtonTextPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, extra, focusContext, hasPreferredFocus, backgroundColor }) => {
    return (
        <Pressable
            hasPreferredFocus={hasPreferredFocus}
            focusContext={focusContext}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: backgroundColor ? backgroundColor : getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                {extra == '' && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)}
                    </Text>
                )}
                {extra != '' && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)} {extra}
                    </Text>
                )}
            </View>
        </Pressable>
    );
};
export const ButtonContinuePlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, season, episode, focusContext, hasPreferredFocus, lessonIndex, progress }) => {
    return (
        <Pressable
            hasPreferredFocus={hasPreferredFocus}
            focusContext={focusContext}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'column',
                }}
            >
                {!isNaN(season) && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)} {lang.getTranslation(userContext, 'season')} {season + 1} {lang.getTranslation(userContext, 'episode')} {episode + 1}
                    </Text>
                )}
                {isNaN(season) && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)}
                    </Text>
                )}
                {progress > 0 && (
                    <View style={{ flexDirection: 'row', width: getCorrectWidth(deviceContext, 100) }}>
                        <View
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 2),
                                backgroundColor: appContext.application.theme.progresses.base,
                                height: getCorrectHeight(deviceContext, 2),
                                flex: 1,
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: appContext.application.theme.progresses.progress,
                                    height: getCorrectHeight(deviceContext, 2),
                                    width: progress + '%',
                                }}
                            ></View>
                        </View>
                    </View>
                )}
            </View>
        </Pressable>
    );
};
export const ButtonCoursePlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, lesson, focusContext, hasPreferredFocus }) => {
    return (
        <Pressable
            hasPreferredFocus={hasPreferredFocus}
            focusContext={focusContext}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)} {lang.getTranslation(userContext, 'lesson')} {lesson + 1}
                </Text>
            </View>
        </Pressable>
    );
};
export const ButtonPayPerViewPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, rule, focusContext, hasPreferredFocus }) => {
    return (
        <Pressable
            hasPreferredFocus={hasPreferredFocus}
            focusContext={focusContext}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)} ({rule})
                </Text>
            </View>
        </Pressable>
    );
};
export const ButtonIcon = ({ deviceContext, appContext, styling, onPress, icon, focusContext }) => {
    return (
        <Pressable
            focusContext={focusContext}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                width: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                borderRadius: 100,
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: getCorrectHeight(deviceContext, 30),
                    width: getCorrectHeight(deviceContext, 30),
                }}
            >
                <FontAwesomeIcon icon={icon} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
            </View>
        </Pressable>
    );
};
const getTags = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
        tags.map((tag, index) => {
            if (tag.tag != undefined) {
                var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                if (test == undefined) {
                    tag.name = tag[0]?.description;
                } else {
                    tag.name = test?.description;
                }
            }
            if (index < 3 && tag.name != '') {
                tagsOut.push(
                    <Text
                        key={index}
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            color: appContext.application.theme.tags.color,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag.name}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags.length > 0) {
        tags.map((tag, index) => {
            if (index < 3) {
                tagsOut.push(
                    <Text
                        key={index}
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const DotInBetween = ({ deviceContext, styling }) => {
    return (
        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
            <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: styling.texts.title_color, borderRadius: 100 }}></View>
        </View>
    );
};
const getProgramImage = (program, data, deviceContext, appContext) => {
    if (program != undefined && program?.m != '' && program?.m != null && program?.m.indexOf('https://') > -1) {
        return (
            <ImageNormal
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{
                    uri: program.m
                        .replace('.jpg', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealHeight(deviceContext)) + '.jpg')
                        .replace('.png', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealHeight(deviceContext)) + '.png'),
                }}
            />
        );
    } else {
        var currentTime = moment().unix();
        var isLive = program.s < currentTime && program.e > currentTime;
        if (isLive) {
            return (
                <ImageNormal
                    deviceContext={deviceContext}
                    resizeMethod={'scale'}
                    resizeMode={'cover'}
                    FadeZoom
                    style={{
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                    }}
                    source={{ uri: getLiveImage(data?.streams.channels.urls.standard_url) }}
                />
            );
        } else {
            return (
                <ImageNormal
                    blurRadius={95}
                    deviceContext={deviceContext}
                    resizeMethod={'scale'}
                    resizeMode={'cover'}
                    FadeZoom
                    style={{
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                    }}
                    source={{ uri: appContext.cloudUrl + data?.images?.square }}
                />
            );
        }
    }
};
const getMovieImage = (item, deviceContext, appContext) => {
    if (item != undefined && item?.images?.background != '') {
        return (
            <ImageNormal
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.background }}
            />
        );
    } else {
        return (
            <ImageNormal
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.poster }}
            />
        );
    }
};
const getSeriesImage = (item, deviceContext, appContext, contentContext) => {
    if (item != undefined && item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background != '') {
        return (
            <ImageNormal
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background }}
            />
        );
    } else {
        return (
            <ImageNormal
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster }}
            />
        );
    }
};

const renderActor = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, appContext, deviceContext, focusContext) => {
    return (
        <Pressable
            focusRepeatContext={focusRepeatContext}
            // key={index}
            style={{ marginLeft: 5, marginRight: getCorrectHeight(deviceContext, 10), width: getCorrectWidth(deviceContext, 70), marginBottom: getCorrectHeight(deviceContext, 15), paddingTop: 10 }}
            animatorOptions={{
                type: 'scale',
                focus: {
                    scale: 1.05,
                    duration: 150,
                },
            }}
            onPress={() => null}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <View>
                    <ImageNormal
                        source={{ uri: item?.photo ?? item?.image }}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        style={{
                            borderColor: appContext.application.theme.tags.color,
                            borderWidth: 3,
                            backgroundColor: '#000',
                            height: getCorrectWidth(deviceContext, 70),
                            width: getCorrectWidth(deviceContext, 70),
                            borderRadius: getCorrectWidth(deviceContext, 100),
                        }}
                    />

                    <Text
                        numberOfLines={2}
                        style={{
                            width: getCorrectWidth(deviceContext, 70),
                            marginTop: getCorrectHeight(deviceContext, 10),
                            textAlign: 'center',
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            includeFontPadding: false,
                        }}
                    >
                        {item?.name}
                    </Text>
                    <Text
                        numberOfLines={1}
                        style={{
                            width: getCorrectWidth(deviceContext, 70),
                            textAlign: 'center',
                            color: appContext.application.theme.tags.color,
                            opacity: 0.5,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            includeFontPadding: false,
                        }}
                    >
                        {item?.role}
                        {item?.charname}
                    </Text>
                </View>
            </View>
        </Pressable>
    );
};

const renderEpisode = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, styling, deviceContext, focusContext, playEpisode, seasonIndex) => {
    const hasPreferredFocus = FocusHelper.cfEpisodeIndex === index && FocusHelper.cfSeasonIndex === seasonIndex;
    return (
        <Widescreen
            elementType={'Button'}
            heroIsPlayer={false}
            onLongPress={() => null}
            rails={true}
            type={'Seasons'}
            styling={styling}
            data={item}
            height={getCorrectHeight(deviceContext, 150)}
            width={(getCorrectHeight(deviceContext, 120) / 9) * 16}
            focusContext={focusContext}
            focusRepeatContext={focusRepeatContext}
            focusOptions={{
                hasPreferredFocus: hasPreferredFocus,
            }}
            onPress={() => playEpisode(item, seasonIndex, index)}
        ></Widescreen>
    );
};

const renderLesson = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, styling, deviceContext, focusContext, playEpisode, selectedSeasonIndex, appContext) => {
    const hasPreferredFocus = FocusHelper.cfEpisodeIndex === index;
    const width = (getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth) - getCorrectWidth(deviceContext, appContext.application.theme.home_details_left_margin)) / 4;
    const height = (width / 16) * 9;
    return (
        <Widescreen
            elementType={'Button'}
            heroIsPlayer={false}
            onLongPress={() => null}
            rails={true}
            type={'Lessons'}
            styling={styling}
            data={item}
            height={height}
            width={width}
            focusContext={focusContext}
            focusRepeatContext={focusRepeatContext}
            focusOptions={{
                hasPreferredFocus: hasPreferredFocus,
            }}
            onPress={() => playEpisode(item, index)}
        ></Widescreen>
    );
};
