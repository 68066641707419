import { CreateListRenderItemInfo, FlashList, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { RemoteImage } from '../../../application/builder/components/remoteimage/remoteimage';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);

    // React.useEffect(() => {
    //   setData(appContext.cart.products)
    //   setShow(true)
    // }, [navigation, route]);

    const acceptDisclaimer = () => {};

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.SIGNUP_DETAILS, duration);
            };
        }, [])
    );

    useEffectOnce(() => {
        setData(appContext.cart.products);
        setShow(true);
    });
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <View style={{ flex: 1 }}>
                <RemoteImage uri={item.external.images[0].url} desiredWidth={200} />
                <Text>{item.name}</Text>
                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => productDetails()}>
                    <View
                        style={{
                            marginTop: 10,
                            width: getRealWidth(deviceContext) * 0.3,
                            height: getCorrectHeight(deviceContext, 30),
                            ...getPressableStyling(appContext.application.authentication.getstarted.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                ...getPressableTextStyling(appContext.application.authentication.getstarted.components?.button?._id, appContext),
                            }}
                        >
                            {lang.getTranslation(userContext, 'open_details')}
                        </Text>
                    </View>
                </Pressable>
            </View>
        );
    };
    const productDetails = () => {};
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {show && (
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'signup_account')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.registration?.header.sub_size),
                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.sub_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'signup_info').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 4 }}>
                        {show && (
                            <View
                                style={{
                                    flex: 1,
                                    width: getRealWidth(deviceContext) * 0.8,
                                }}
                                focusContext={focusContext}
                            >
                                <FlashList
                                    data={data}
                                    estimatedItemSize={getCorrectHeight(deviceContext, 200)}
                                    numColumns={5}
                                    renderItem={renderItem}
                                    horizontal={false}
                                    showsVerticalScrollIndicator={false}
                                    initialScrollIndex={0}
                                    type={'grid'}
                                    style={{ flex: 1 }}
                                />
                            </View>
                        )}
                        <View style={{ alignItems: 'center', marginBottom: getCorrectHeight(deviceContext, 20) }}></View>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
