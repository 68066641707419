import * as React from 'react';
import { Image, View, Screen, FocusContext, ImageBackground } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import moment from 'moment';
import { LogoTime as styling } from '../../../models/elements/misc/logotime/logotime';
import { ContentContext } from '../../../context/contentContext';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Text from '../../components/text';

export interface Props {
    styling: styling;
    scrollA;
    focusContext?: any;
}

export const LogoTime = ({ styling, scrollA, focusContext }: Props) => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const BANNER_H = getRealHeight(deviceContext);
    const safeArea = useSafeAreaInsets();
    const isFloating = !!scrollA;
    const TOPNAVI_H = getCorrectHeight(deviceContext, 40);
    const [isTransparent, setTransparent] = React.useState(isFloating);

    const [showLogo, setShowLogo] = React.useState(true);

    var screenWidth =
        getRealWidth(deviceContext) -
        getCorrectWidth(deviceContext, +styling.general.margin_left + styling.general.margin_right) -
        getCorrectWidth(deviceContext, global.menuWidth);

    React.useEffect(() => {
        if (!scrollA) {
            return;
        }
        const listenerId = scrollA.addListener((a) => {
            const topNaviOffset = BANNER_H - TOPNAVI_H - safeArea.top - getCorrectHeight(deviceContext, 225);
            isTransparent !== a.value < topNaviOffset && setTransparent(!isTransparent);
        });
        return () => scrollA.removeListener(listenerId);
    });
    React.useEffect(() => {
        if (deviceContext.isWebTV) {
            if (appContext.application.navigators?.browser.type != 'Bottom Menu') {
                setShowLogo(false);
            }
        } else if (deviceContext.isTablet) {
            if (appContext.application.navigators?.browser.type != 'Bottom Menu') {
                setShowLogo(false);
            }
        } else if (deviceContext.isPhone || deviceContext.isPwaVertical) {
            if (appContext.application.navigators?.browser.type != 'Bottom Menu') {
                setShowLogo(false);
            }
        } else if (deviceContext.isTelevision) {
            if (appContext.application.navigators?.browser.type != 'Bottom Menu') {
                setShowLogo(false);
            }
        }
    }, [styling]);

    return (
        <>
            {!deviceContext.isPhone && !deviceContext.isPwaVertical && (
                <View focusContext={focusContext} style={{ paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    {deviceContext.isKaiOs && (
                        <View
                            style={{
                                width: screenWidth,
                                flexDirection: 'row',
                                paddingTop: safeArea.top,
                                height: TOPNAVI_H + safeArea.top,
                                //justifyContent: 'center',
                                backgroundColor: styling.general.background_color,
                                paddingLeft: 3,
                            }}
                        >
                            <Image
                                style={{ width: 100, height: 25 }}
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.application.branding.logos.logo }}
                            ></Image>
                        </View>
                    )}
                    {!deviceContext.isKaiOs && (
                        <View
                            style={{
                                width: screenWidth,
                                flexDirection: 'row',
                                paddingTop: safeArea.top,
                                height: TOPNAVI_H + safeArea.top,
                                justifyContent: 'center',
                                backgroundColor: isTransparent ? 'transparent' : styling.general.background_color,

                                zIndex: 100,
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                                {showLogo && (
                                    <Image
                                        style={{ width: 100, height: 50 }}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        source={{ uri: appContext.application.branding.logos.logo }}
                                    ></Image>
                                )}
                            </View>

                            <View
                                style={{
                                    justifyContent: 'center',
                                    marginRight: getCorrectWidth(deviceContext, 10),
                                    flexDirection: 'row',
                                }}
                            >
                                {/* <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <Text
                                        style={{
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.6)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        300
                                    </Text>
                                    <Text
                                        style={{
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, styling.texts.subtext_size),
                                            fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.6)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        Credits
                                    </Text>
                                </View> */}
                                {/* <View style={{ justifyContent: 'center', alignItems: 'center', marginLeft: getCorrectWidth(deviceContext, 20) }}>
                                    <Text
                                        style={{
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.6)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        100 Hrs
                                    </Text>
                                    <Text
                                        style={{
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, styling.texts.subtext_size),
                                            fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.6)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        Recording
                                    </Text>
                                </View> */}
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: getCorrectWidth(deviceContext, 5),
                                        marginTop: getCorrectWidth(deviceContext, 5),
                                    }}
                                >
                                    <Text
                                        style={{
                                            includeFontPadding: false,
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.3)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {moment().format(deviceContext.clockSetting)}
                                    </Text>
                                    <Text
                                        style={{
                                            includeFontPadding: false,
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, styling.texts.subtext_size),
                                            fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                            textShadowColor: 'rgba(0, 0, 0, 0.3)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {moment().format('ll')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}
                </View>
            )}
        </>
    );
};
export default LogoTime;
