import { ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import HTMLRenderer from '../../../libs/react-native-render-html';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import { getAuthenticationText } from '../helper/helper';
import { ContentContext } from '../../../application/context/contentContext';

const Screen_ = ({ navigation, route, focusContext, showButton }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const [show, setShow] = React.useState(true);

    useFocusEffect(
        React.useCallback(() => {
            contentContext.setActiveMenu(route.name);
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.DISCLAIMER, duration);
            };
        }, [])
    );

    const acceptDisclaimer = () => {};

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'settings-screen' }}>
            {show && (
                <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.disclaimer?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.disclaimer?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'disclaimer')}
                        </Text>
                    </View>
                    <View style={{ flex: 4 }}>
                        {appContext.application.authentication?.disclaimer?.translations?.translations?.length > 0 && !deviceContext.isKaiOs && (
                            <ScrollView contentContainerStyle={{ flex: 1 }}>
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <View
                                        style={{
                                            borderRadius: getPressableStyling(
                                                appContext.application.authentication.disclaimer.components?.button?._id,
                                                appContext
                                            ).borderRadius,
                                            
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getRealWidth(deviceContext) * 0.9,
                                            marginVertical: 50,
                                        }}
                                    >
                                        <HTMLRenderer
                                            html={getAuthenticationText(
                                                appContext.application.authentication?.disclaimer?.translations?.translations,
                                                userContext
                                            )}
                                            contentWidth={getRealWidth(deviceContext) * 0.9}
                                        />
                                    </View>
                                </View>
                            </ScrollView>
                        )}
                        <View style={{ alignItems: 'center', marginBottom: getCorrectHeight(deviceContext, 20) }}>
                            {showButton && (
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => acceptDisclaimer()} focusOptions={{hasPreferredFocus: true}}>
                                    <View
                                        style={{
                                            width: getRealWidth(deviceContext) / 2,
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(
                                                appContext.application.authentication.disclaimer.components?.button?._id,
                                                appContext
                                            ),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: 5,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(
                                                    appContext.application.authentication.disclaimer.components?.button?._id,
                                                    appContext
                                                ),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'accept_disclaimer')}
                                        </Text>
                                    </View>
                                </Pressable>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
