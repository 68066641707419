import { isPlatformAndroidtv, isPlatformFiretv } from '@rnv/renative';
import { Dimensions, PixelRatio } from 'react-native';

export function Ratio(pixels: number): number {
    if (!(isPlatformAndroidtv || isPlatformFiretv)) return pixels;
    const resolution = Dimensions.get('screen').height * PixelRatio.get();

    return Math.round(pixels / (resolution < 2160 ? 2 : 1));
}

export const getFocusOptions = (deviceContext, appContext, focusKey, screenName = '') => {
    var navigatorType = 'Menu Bottom';
    if (deviceContext.isWebTV) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }

    if (navigatorType == 'Menu Bottom') {
        return {
            focusKey: focusKey,
            nextFocusDown: ['menu-bottom'],
            verticalViewportOffset: Ratio(100),
        };
    } else if (navigatorType == 'Menu Left') {
        return {
            focusKey: focusKey,
            nextFocusLeft: screenName === 'Home' ? ['vertical-list-content', 'menu-left'] : ['menu-left'],
            verticalViewportOffset: Ratio(100),
        };
    } else if (navigatorType == 'Menu Top') {
        return {
            focusKey: focusKey,
            nextFocusUp: ['menu-top'],
            verticalViewportOffset: Ratio(100),
        };
    } else if (navigatorType == 'Drawer') {
        return {
            focusKey: focusKey,
            nextFocusLeft: screenName === 'Home' ? ['vertical-list-content', 'menu-drawer'] : ['menu-drawer'],
            verticalViewportOffset: Ratio(100),
        };
    }
};
