import { ActivityIndicator, ScrollView, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { faEnvelope, faMobile, faShield, faUser } from '@fortawesome/pro-light-svg-icons';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getGlobals } from '../../../globals';
import Text from '../../../application/builder/components/text';
import { getKeyboardStyling, getPressableStyling, getFocusStyling, getInputStyling, getPressableTextStyling, getModalStyling } from '../../../application/builder/components/helpers/helper';
import { KeyboardAvoidingView as KAV } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';
import { languageCodes } from '../../../application/languages/language_codes';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';

const KeyboardAvoidingView = withParentContextMapper(KAV);

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordCheck, setPasswordCheck] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [showKeyboard1, setShowKeyboard1] = React.useState(false);
    const [showKeyboard2, setShowKeyboard2] = React.useState(false);
    const [showKeyboard3, setShowKeyboard3] = React.useState(false);
    const [showKeyboard4, setShowKeyboard4] = React.useState(false);
    const [showKeyboard5, setShowKeyboard5] = React.useState(false);
    const [showKeyboard6, setShowKeyboard6] = React.useState(false);
    const [error, setError] = React.useState('');

    useEffectOnce(() => {
        getRegisterInfo();
    });

    const getRegisterInfo = async () => {
        let response = await fetch('https://cloudtv.akamaized.net/location.php?_=' + moment().unix());
        let data = await response.json();
        if (data != undefined) {
            var country = languageCodes.find((l) => l.code == data.country).name != undefined ? languageCodes.find((l) => l.code == data.country).name : 'United States';
            var city = data.city.toLowerCase();
            city = data.city.charAt(0).toUpperCase() + city.slice(1);
            userContext.setCity(city);
            userContext.setCountry(country);
            userContext.setLatitude(data.latitude);
            userContext.setLongitude(data.longitude);
            userContext.setIso2(data.country);
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.REGISTER, duration);
            };
        }, [])
    );

    const registerUser = () => {
        setLoading(true);
        if (email == '' && appContext.cart.customer.mandatory_email) {
            setError(lang.getTranslation(userContext, 'filloutform'));
            setLoading(false);
        } else if (mobile == '' && appContext.cart.customer.mandatory_phone && !appContext.cart.customer.mandatory_email) {
            setError(lang.getTranslation(userContext, 'filloutform'));
            setLoading(false);
        } else {
            fetch(
                getGlobals().AppApiUrl +
                    '/register/register?client=' +
                    encodeURIComponent(appContext.application.client) +
                    '&deployment=' +
                    encodeURIComponent(appContext.application.deployment) +
                    '&service=' +
                    encodeURIComponent(appContext.application.branding.service),
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        cart_id: appContext.cart._id,
                        email: email,
                        mobile: mobile != '' ? mobile : '0031612345678',
                        firstname: firstname,
                        lastname: lastname,
                        password: password,
                        model: deviceContext.model,
                        uuid: deviceContext.uniqueId,
                        city: userContext.city,
                        country: userContext.country,
                        number: deviceContext.number != 'unknown' && deviceContext.number != '' ? deviceContext.number : '0031201234567',
                        iso2: userContext.iso2,
                    }),
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    //reload to login
                    if (response.success == true) {
                        if (appContext.cart.customer.mandatory_email) {
                            userContext.setUserId(email);
                            utils.storeJson('UserID', email);
                        } else if (appContext.cart.customer.mandatory_phone) {
                            userContext.setUserId(mobile);
                            utils.storeJson('UserID', mobile);
                        } else {
                            userContext.setUserId(mobile);
                            utils.storeJson('UserID', mobile);
                        }
                        userContext.setPass(password);
                        utils.storeJson('Pass', password);

                        userContext.setAutoLogin(true);
                        utils.storeJson('AutoLogin', true);

                        setLoading(false);
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    } else {
                        if (response.error) {
                            setLoading(false);
                            setError(lang.getTranslation(userContext, response.error));
                        } else {
                            userContext.setUserId(response.username);
                            utils.storeJson('UserID', response.username);

                            userContext.setPass(response.password);
                            utils.storeJson('Pass', response.password);

                            userContext.setAutoLogin(true);
                            utils.storeJson('AutoLogin', true);

                            setLoading(false);
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Login' }],
                                })
                            );
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error);
                });
        }
    };

    const goBack = () => {
        navigation && navigation.navigate('Login');
    };
    const closeError = () => {
        setError('');
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <KeyboardAvoidingView behavior={deviceContext.isApple ? 'padding' : 'height'} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {error != '' && <Modal error={error} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'error'} setShowModal={closeError} submitChoice={closeError}></Modal>}
                {showKeyboard1 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard1(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setPassword}
                        value={pageType}
                    ></Keyboard>
                )}
                {showKeyboard2 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard2(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setPasswordCheck}
                        value={passwordCheck}
                    ></Keyboard>
                )}
                {showKeyboard3 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard3(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setMobile}
                        value={mobile}
                    ></Keyboard>
                )}
                {showKeyboard4 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard4(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setEmail}
                        value={email}
                    ></Keyboard>
                )}
                {showKeyboard5 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard5(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setFirstname}
                        value={firstname}
                    ></Keyboard>
                )}
                {showKeyboard6 && (
                    <Keyboard
                        onCloseKeyboard={() => setShowKeyboard6(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                        onChangText={setLastname}
                        value={lastname}
                    ></Keyboard>
                )}

                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            width: getRealWidth(deviceContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                            
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'register')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.registration?.header.sub_size),
                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.sub_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'register_info').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 6, flexDirection: 'row', marginTop: 10 }}>
                        {appContext.cart != undefined && (
                            <ScrollView horizontal={false} contentContainerStyle={{ paddingBottom: 160 }}>
                                <View style={{ flex: 4, alignItems: 'center' }}>
                                    <View
                                        style={{
                                            ...getPressableStyling(appContext.application.authentication.registration.components?.button?._id, appContext),

                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3),
                                            padding: getCorrectWidth(deviceContext, 10),
                                            marginBottom: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.sub_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'register_explanation')}
                                        </Text>
                                    </View>
                                    <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.sub_color,
                                                marginLeft: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'your_firstname')}
                                        </Text>
                                        <Input
                                            showKeyboard={() => setShowKeyboard5(!showKeyboard5)}
                                            value={firstname}
                                            style={{ marginTop: 10 }}
                                            onChangText={(value) => setFirstname(value)}
                                            icon={faUser}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                            isSecure={false}
                                            placeholder={lang.getTranslation(userContext, 'first_name')}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.registration.components?.input?._id, appContext)}
                                        ></Input>
                                    </View>
                                    <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.sub_color,
                                                marginLeft: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'your_lastname')}
                                        </Text>
                                        <Input
                                            showKeyboard={() => setShowKeyboard6(!showKeyboard6)}
                                            value={lastname}
                                            style={{ marginTop: 10 }}
                                            onChangText={(value) => setLastname(value)}
                                            icon={faUser}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                            isSecure={false}
                                            placeholder={lang.getTranslation(userContext, 'last_name')}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.registration.components?.input?._id, appContext)}
                                        ></Input>
                                    </View>
                                    <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.sub_color,
                                                marginLeft: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'enter_password')}
                                        </Text>
                                        <Input
                                            showKeyboard={() => setShowKeyboard1(!showKeyboard1)}
                                            value={password}
                                            style={{ marginTop: 10 }}
                                            onChangText={(value) => setPassword(value)}
                                            icon={faShield}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                            isSecure={true}
                                            placeholder={lang.getTranslation(userContext, 'password')}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.registration.components?.input?._id, appContext)}
                                        ></Input>
                                    </View>
                                    {/* <View style={{ marginBottom: getCorrectHeight(deviceContext, 15) }}>
                                    <Text
                                        style={{
                                            alignSelf: 'flex-start',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                            color: appContext.application.authentication?.registration?.header.sub_color,
                                            marginLeft: getCorrectWidth(deviceContext, 5),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'reenter_password')}
                                    </Text>
                                    <Input
                                        showKeyboard={() => setShowKeyboard2(!showKeyboard2)}
                                        value={password}
                                        style={{ marginTop: 10 }}
                                        onChangText={(value) => setPasswordCheck(value)}
                                        icon={faShield}
                                        width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                        isSecure={true}
                                        placeholder={lang.getTranslation(userContext, 'password')}
                                        animation={getFocusStyling('Inputs', appContext)}
                                        styling={getInputStyling(
                                            appContext.application.authentication.registration.components?.input?._id,
                                            appContext
                                        )}
                                    ></Input>
                                </View> */}
                                    {appContext.cart.customer.mandatory_phone && !appContext.cart.customer.mandatory_email && (
                                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                            <Text
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                    color: appContext.application.authentication?.registration?.header.sub_color,
                                                    marginLeft: getCorrectWidth(deviceContext, 5),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'your_mobile_username')}
                                            </Text>
                                            <Input
                                                showKeyboard={() => setShowKeyboard3(!showKeyboard3)}
                                                value={mobile}
                                                style={{ marginTop: 10 }}
                                                onChangText={(value) => setMobile(value)}
                                                icon={faMobile}
                                                width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                                isSecure={false}
                                                placeholder={lang.getTranslation(userContext, 'mobile')}
                                                animation={getFocusStyling('Inputs', appContext)}
                                                styling={getInputStyling(appContext.application.authentication.registration.components?.input?._id, appContext)}
                                            ></Input>
                                        </View>
                                    )}

                                    {appContext.cart.customer.mandatory_email && (
                                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                            <Text
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                    color: appContext.application.authentication?.registration?.header.sub_color,
                                                    marginLeft: getCorrectWidth(deviceContext, 5),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'your_email_username')}
                                            </Text>
                                            <Input
                                                showKeyboard={() => setShowKeyboard4(!showKeyboard4)}
                                                value={email}
                                                style={{ marginTop: 10 }}
                                                onChangText={(value) => setEmail(value)}
                                                icon={faEnvelope}
                                                width={getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3)}
                                                isSecure={false}
                                                placeholder={lang.getTranslation(userContext, 'email')}
                                                animation={getFocusStyling('Inputs', appContext)}
                                                styling={getInputStyling(appContext.application.authentication.registration.components?.input?._id, appContext)}
                                            ></Input>
                                        </View>
                                    )}
                                    <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable focusContext={focusContext} style={{
     
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.registration.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                         }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => registerUser()}>
                                        <View
                                   
                                        >
                                            {!loading && (
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(appContext.application.authentication.registration.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'submit')}
                                                </Text>
                                            )}
                                            {loading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.registration.components?.button?._id, appContext).color} size={'small'}></ActivityIndicator>}
                                        </View>
                                    </Pressable>
                                    </View>
                                    <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        focusContext={focusContext}
                                        style={{
                                    

                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.registration.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => goBack()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.registration.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'back')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                    </View>
                                </View>
                            </ScrollView>
                        )}
                    </View>
                </View>
            </KeyboardAvoidingView>
        </ScreenWrapper>
    );
};
export default Screen_;
