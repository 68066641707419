import * as React from 'react';
import { useState, Dispatch } from 'react';
import { Product } from '../models/content/product/product';

interface UserContext {
    walletBalance: number;
    setWalletBalance: Dispatch<any>;

    selectedLanguage: string;
    setSelectedLanguage: Dispatch<any>;

    selectedLanguageCode: string;
    setSelectedLanguageCode: Dispatch<any>;

    accountLoadedTimestamp: number;
    setAccountLoadedTimestamp: Dispatch<any>;

    isOnboardingFinished: boolean;
    setIsOnboardingFinished: Dispatch<any>;

    devices: any;
    setDevices: Dispatch<any>;
    product: Product;
    setProduct: Dispatch<any>;

    productType: string;
    setProductType: Dispatch<any>;

    isAuthenticated: boolean;
    setAuthenticated: Dispatch<any>;

    autoLogin: boolean;
    setAutoLogin: Dispatch<any>;

    isLoaded: boolean;
    setIsLoaded: Dispatch<any>;

    profiled: boolean;
    setProfiled: Dispatch<any>;

    userId: any;
    setUserId: Dispatch<any>;
    pass: any;
    setPass: Dispatch<any>;
    appId: any;
    setAppId: Dispatch<any>;
    orginalPackageId: any;
    setOrginalPackageId: Dispatch<any>;

    user: any;
    setUser: Dispatch<any>;
    profile: any;
    setProfile: Dispatch<any>;
    allProfiles: any;
    setAllProfiles: Dispatch<any>;

    refreshData: any;
    setRefreshData: Dispatch<any>;

    city: string;
    setCity: Dispatch<any>;
    state: string;
    setState: Dispatch<any>;
    country: string;
    setCountry: Dispatch<any>;
    latitude: string;
    setLatitude: Dispatch<any>;
    longitude: string;
    setLongitude: Dispatch<any>;
    iso2: string;
    setIso2: Dispatch<any>;
}
export const UserContext = React.createContext<UserContext>(null);

export function UserProvider({ children }: { children: any }) {
    const [accountLoadedTimestamp, setAccountLoadedTimestamp] = useState(0);

    const [devices, setDevices] = useState([] as any);
    const [product, setProduct] = useState([] as any);
    const [productType, setProductType] = useState('');

    const [userId, setUserId] = useState('customer');
    const [pass, setPass] = useState('test1234');
    const [appId, setAppId] = useState('');
    const [orginalPackageId, setOrginalPackageId] = useState('');

    const [isOnboardingFinished, setIsOnboardingFinished] = useState(false);

    const [isAuthenticated, setAuthenticated] = useState(false);
    const [autoLogin, setAutoLogin] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [profiled, setProfiled] = useState(false);

    const [user, setUser] = useState([] as any);
    const [profile, setProfile] = useState([] as any);
    const [allProfiles, setAllProfiles] = useState([] as any);

    const [refreshData, setRefreshData] = useState(false);

    const [walletBalance, setWalletBalance] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [selectedLanguageCode, setSelectedLanguageCode] = useState('en');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [iso2, setIso2] = useState('');

    return (
        <UserContext.Provider
            value={{
                isOnboardingFinished,
                setIsOnboardingFinished,

                accountLoadedTimestamp,
                setAccountLoadedTimestamp,

                devices,
                setDevices,
                product,
                setProduct,
                productType,
                setProductType,

                isAuthenticated,
                isLoaded,
                profiled,
                autoLogin,
                setAuthenticated,
                setIsLoaded,
                setProfiled,
                setAutoLogin,

                userId,
                setUserId,
                pass,
                setPass,
                appId,
                setAppId,
                orginalPackageId,
                setOrginalPackageId,

                user,
                setUser,
                profile,
                setProfile,

                refreshData,
                setRefreshData,

                allProfiles,
                setAllProfiles,

                selectedLanguage,
                setSelectedLanguage,
                selectedLanguageCode,
                setSelectedLanguageCode,

                city,
                setCity,
                state,
                setState,
                country,
                setCountry,
                latitude,
                setLatitude,
                longitude,
                setLongitude,
                iso2,
                setIso2,
                walletBalance, 
                setWalletBalance
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(UserContext);
//     return context;
// }
