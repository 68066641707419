import * as React from 'react';
import { View, CreateListRenderItemInfo, FlashList, FocusContext } from '@scriptx-com/xtv-toolkit';
import { Square_Rails as styling } from '../../../models/elements/rails/square/square';
import Genre from '../shared/genre';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { getFocusStyling } from '../../components/helpers/helper';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { GetScrollElement, GetTopPart } from './helpers/views';
import { EMPTY_DATA_ARRAY, extraTopBottomMargin, getRailsHeight, getRailsWidth, LOADER_TIMEOUT_MS } from './helpers/helper';
import useBaseRail from './useBaseRail';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
    navigation: any;
    railIndex: any;
}

export const Genre_Rails = React.memo(({ styling, focusContext, navigation, railIndex }: Props) => {
    const [loading, setLoading] = React.useState(true);
    const screenName = getCurrentScreenName();
    const [data, setData] = React.useState(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    if(deviceContext.formFactor == 'Phone'){
        height = height * 0.8
    }
    var width = (height / 9) * 16;

        //mobile width check
        if(width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone'){
            height = ((getRealWidth(deviceContext) * 0.7) / 16) * 9;
            width = (height / 9) * 16;
        }

    React.useEffect(() => {
        if (data.length) {
            setTimeout(() => {
                setLoading(false);
            }, LOADER_TIMEOUT_MS);
        }
    }, [data]);

    const getData = async () => {
        var categories = [] as any;
        if (styling.content.list?.type == 'Channels') {
            categories = contentContext.channels.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Radio') {
            categories = contentContext.radios.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Cams') {
            categories = contentContext.cams.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Movies') {
            categories = contentContext.movies.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Shorts') {
            categories = contentContext.shorts.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Series') {
            categories = contentContext.series.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Seasons') {
            categories = contentContext.seriesData.seasons;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Podcasts') {
            categories = contentContext.podcasts.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Music') {
            categories = contentContext.albums.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }
        if (styling.content.list?.type == 'Courses') {
            categories = contentContext.courses.categories;
            if (categories != undefined) {
                setData(categories);
                return;
            }
        }

        // setData(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'genre',
        getData,
        emptyItemRenderer: (props: any) => {
            return renderItem({
                item: EMPTY_DATA_ARRAY[0],
                index: props.index,
                target: 'Cell',
                emptyRailProps: props,
            });
        },
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: false
    });

    const detailsItem = (item: any, index: any) => {
        if (styling.content.list?.type == 'Channels') {
            contentContext.setChannelData({
                ...contentContext.channelData,
                channelCategoryIndex: index,
                channelListCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Radio') {
            contentContext.setRadioData({
                ...contentContext.radioData,
                radioCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Cams') {
            contentContext.setCamData({
                ...contentContext.camData,
                camCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Movies') {
            contentContext.setMovieData({
                ...contentContext.movieData,
                movieCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Shorts') {
            contentContext.setShortData({
                ...contentContext.shortData,
                shortCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Series') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seriesCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Seasons') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seasonCategoryIndex: index,
                season: contentContext.seriesData.seasons[index],
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Podcasts') {
            contentContext.setPodcastData({
                ...contentContext.podcastData,
                podcastCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Music') {
            contentContext.setAlbumData({
                ...contentContext.albumData,
                albumCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Courses') {
            contentContext.setCourseData({
                ...contentContext.courseData,
                courseCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
    };

    const renderItem = ({ item, focusRepeatContext, index, emptyRailProps }: CreateListRenderItemInfo<any> & { emptyRailProps?: any }) => {
        return (
            <Genre
                elementType={'Rails'}
                rails={true}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    if (item.type !== 'empty') {
                        detailsItem(item, index);
                    }
                }}
                focusOptions={{
                    focusKey: `genre-${railIndex}-${index}`,
                }}
                isFirst={index === 0}
                isLast={data.length - 1 === index}
                {...emptyRailProps}
            ></Genre>
        );
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            <View
                style={{
                    flex: 1,
                    marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                    marginRight:  styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                    marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext,styling),
                    marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext,styling),
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            backgroundColor: styling.placement.background_color,
                            height: styling.content.type === 'Search' && data.length === 0 ? 0 :  getRailsHeight(deviceContext, appContext, styling, height),
                            borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                            paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingHorizontal:
                                styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                    ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 5)
                                        : getCorrectWidth(deviceContext, styling.placement.padding)
                                    : 0,
              
                        }}
                    >
                        <GetTopPart
                            screenName={screenName}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            focusContext={focusContext}
                            openOverviewPage={undefined}
                        ></GetTopPart>

                        <View style={{ flex: 1, width:  getRailsWidth(deviceContext, appContext, styling, screenName)}} focusContext={focusContext}>
                            <View>{Loader()}</View>
                            {data != null && data.length > 0 && (
                                <FlashList
                                    type="row"
                                    ref={railsRef}
                                    data={data}
                                    onViewableItemsChanged={onViewableItemsChanged}
                                    estimatedItemSize={height}
                                    // drawDistance={height * 2}
                                    numColumns={1}
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={renderItem}
                                    initialScrollIndex={0}
                                    focusOptions={{
                                        autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                        autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                        ...flashListProps.focusOptions,
                                    }}
                                    {...flashListProps}
                                />
                            )}
                            {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
});
export default Genre_Rails;
