import { Pressable, PressableFocusOptions } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';

export interface PressableProps {
    animatorOptions: any;
    children: React.ReactChild;
    hasPreferredFocus?: boolean;
    focusOptions?: PressableFocusOptions;
    focusRepeatContext?: any;
    focusContext?: any;
    onPress?: any;
    props?: any;
    onMouseOver?: any;
    onMouseOut?: any;
    style?: any;
    ref?: any;
    isFirst?: boolean;
    isLast?: boolean;
}
const Pressable_ = ({ animatorOptions, hasPreferredFocus, focusOptions = {}, focusRepeatContext, focusContext, ref, children, onPress, onMouseOver, onMouseOut, style, isFirst, isLast, ...props }: PressableProps) => {
    let animator = animatorOptions ? { ...animatorOptions, focus: { ...animatorOptions.focus  } } : null; 
    if (animator == null) {
        animator = {
            blur: {},
            focus: { backgroundColor: '#ffffff' },
            type: 'background',
        };
    } else {
        if (animator.type === 'scale' || animator.type === 'scale_with_border') {
            if (isFirst) {
                animator.focus.scaleToRight = true;

            }
            if (isLast) {
                animator.focus.scaleToLeft = true;
            }
        }
    }

    return (
        <Pressable
            {...props}
            style={style}
            focusRepeatContext={focusRepeatContext}
            focusContext={focusContext}
            onPress={onPress}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            focusOptions={{
                hasPreferredFocus: hasPreferredFocus != undefined ? hasPreferredFocus : false,
                animator,
                ...focusOptions,
            }}
        >
            {children}
        </Pressable>
    );
};

export default Pressable_;
