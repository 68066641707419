import * as React from 'react';
import { View, ActivityIndicator, FlashList, CreateListRenderItemInfo } from '@scriptx-com/xtv-toolkit';
import { Square_Grids as styling } from '../../../models/elements/grids/square/square';
import Rectangle from '../shared/rectangle';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { ContentContext } from '../../../context/contentContext';
import { getCorrectColumns } from '../../../styling/correctColumns';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { getGridData } from './helpers/functions';
import { AppContext } from '../../../context/appContext';
import { getDetailsMovieIndex } from '../rails/helpers/helper';
import lang from '../../../languages/languages';
import { getFontSize } from '../../../styling/fontSizes';
import { useGridHeightCalculator } from '../../../hooks/useGridHeightCalculator';
import Text from '../../components/text';
import { getFocusStyling, getModalStyling } from '../../components/helpers/helper';
import Modal from '../../components/modal/modal';
import { manageWatching, manageFavorite, favoriteStatus, watchingStatus } from '../../../data/account';
import { useFocusEffect } from '@react-navigation/native';

export interface Props {
    styling: styling;
    focusContext?: any;
    navigation: any;
    dataRails?: any;
    customGridHeight?: number;
    screenNameOverride?: string;
}

export const Rectangle_Grid = ({ styling, focusContext, navigation, dataRails, customGridHeight, screenNameOverride }: Props) => {
    const [data, setData] = React.useState(dataRails ?? ([] as any));
    const screenName = getCurrentScreenName();
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [favoriteCount, setFavoriteCount] = React.useState<number>(0);

    const { onLayout, targetRef, gridHeight, paddingBottom } = useGridHeightCalculator();

    var screenWidth = getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +styling.placement.margin_left + styling.placement.margin_right) - getCorrectWidth(deviceContext, global.menuWidth);
    var itemWidth = screenWidth / getCorrectColumns(styling, deviceContext);
    var width = itemWidth - 5;
    var height = itemWidth * 1.5;

    useFocusEffect(() => {
        setFavoriteCount(userContext.profile.favorites['movies'].length);
    });

    const detailsItem = (item: any, index: any) => {
        if (screenName == 'Movies' || screenNameOverride == 'Movies') {
            getDetailsMovieIndex(contentContext, item, navigation, index, contentContext.movieData.movieCategoryIndex);
        }
    };

    React.useEffect(() => {
        async function myAsynFunction() {
            if (contentContext.movies?.categories != undefined) {
                if (contentContext.searching == true) {
                    if (screenName == 'Movies') {
                        var movies = await getGridData(userContext, screenName, contentContext, appContext);
                        if (contentContext.search != '') {
                            var filter = movies.filter((c) => c.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1);
                            setData(filter);
                        } else {
                            if (data.length > 0) {
                                setData(movies);
                            }
                            if (contentContext.search == '') {
                                contentContext.setSearching(false);
                            }
                        }
                    }
                }
            }
        }
        myAsynFunction();
    }, [contentContext.search]);

    React.useEffect(() => {
        async function myAsynFunction() {
            if (dataRails == undefined) {
                var items = await getGridData(userContext, screenName, contentContext, appContext);
                if (items != undefined && items.length > 0) {
                    setData(items);
                } else {
                    setData(null);
                }
            }
        }
        myAsynFunction();
    }, [contentContext.movieData.movieCategoryIndex]);

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Rectangle
                elementType={'Grids'}
                heroIsPlayer={false}
                onLongPress={() => null}
                type={screenName}
                styling={styling}
                height={height}
                width={width}
                data={item}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onPress={() => detailsItem(item, index)}
                focusOptions={{
                    focusKey: `grid-item-${index}`,
                }}
            ></Rectangle>
        );
    };

    const getInitialScrollIndex = () => {
        if (screenName == 'Movies') {
            return contentContext.movieData.movieIndex;
        }
        return 0;
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        if ((deviceContext.isWebTV || deviceContext.isPhone || deviceContext.isTablet) && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(screenName, item._id, userContext));
            setWatchlist(watchingStatus(screenName, item._id, userContext));
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        setWatchlist(!watchlist);
        manageWatching(userContext, screenName, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setData(data);
        setShowLongpress(false);
    };
    const manageFavorite_ = () => {
        manageFavorite(contentContext, screenName, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setData(data);
        setShowLongpress(false);
    };

    return (
        <View style={{ flexGrow: 1, flexDirection: 'row', height: customGridHeight || gridHeight, paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }} focusContext={focusContext} ref={targetRef} onLayout={onLayout}>
            {showLongpress && (
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => null}
                    data={longItem}
                    contentType={screenName}
                ></Modal>
            )}
            {data != undefined && data != null && data?.length == 0 && (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                </View>
            )}
            {data == null && (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    {screenName != 'Seasons' && screenName != 'Course' && screenName != 'Movie' && screenName != 'Program' && (
                        <Text style={{ color: '#fff', fontSize: getFontSize(deviceContext, 'Large') }}>{lang.getTranslation(userContext, 'no_content')}</Text>
                    )}
                </View>
            )}

            {data != undefined && data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        height: customGridHeight || null,
                        width: screenWidth,
                        backgroundColor: styling.placement.background_color,
                        marginLeft: getCorrectWidth(deviceContext, styling.placement.margin_left ?? 0) + 3,
                        marginRight: getCorrectWidth(deviceContext, styling.placement.margin_right ?? 0),
                        marginTop: getCorrectHeight(deviceContext, styling.placement.margin_top == 0 ? 5 : styling.placement.margin_top ?? 5),
                        marginBottom: getCorrectHeight(deviceContext, styling.placement.margin_bottom ?? 0),
                    }}
                    focusContext={focusContext}
                >
                    <FlashList
                        scrollEnabled={!contentContext.disableScroll}
                        data={data}
                        estimatedItemSize={getCorrectHeight(deviceContext, width)}
                        numColumns={getCorrectColumns(styling, deviceContext)}
                        renderItem={renderItem}
                        horizontal={false}
                        showsVerticalScrollIndicator={false}
                        initialScrollIndex={getInitialScrollIndex()}
                        // drawDistance={getCorrectHeight(deviceContext, width) * 2}
                        type={'grid'}
                        style={{ flex: 1 }}
                        contentContainerStyle={{ paddingBottom }}
                        extraData={favoriteCount}
                        focusOptions={{
                            nextFocusUp: ['menu-item-active-1', 'menu-item-active-0'],
                            autoLayoutScaleAnimation: getFocusStyling('Grids', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                            autoLayoutSize: getFocusStyling('Grids', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                        }}
                    />
                </View>
            )}
        </View>
    );
};
export default Rectangle_Grid;
