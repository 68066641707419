import React, { useState } from 'react';
import { CreateListRenderItemInfo, FlashList, ScrollView, View, Image as Image_, FocusContext } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { DeviceContext } from '../../../../context/deviceContext';
import { UserContext } from '../../../../context/userContext';
import { ContentContext } from '../../../../context/contentContext';
import { AppContext } from '../../../../context/appContext';
import Pressable from '../../../components/pressable/pressable';
import Image from '../../../components/image/image';
import Text from '../../../components/text';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../../components/helpers/helper';
import { getFontSize } from '../../../../styling/fontSizes';
import { BackHandler } from 'react-native';
import { watchingStatus } from '../../../../data/account';

type MiniEPGTypes = {
    focusContext?: FocusContext;
    navigation: any;
    playEpisodeIndex: any;
    styling: any;
    onHide: () => void;
};

const MiniSeries = ({ focusContext, navigation, onHide, playEpisodeIndex, styling }: MiniEPGTypes) => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(contentContext.seriesData.seasonCategoryIndex);

    const disableNativeHardwareBackPress = () => {
        onHide();
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, []);

    const openCategory = (index: number) => {
        setSelectedSeasonIndex(index);
    };
    const getDescription = (item) => {
        var test = item.translations.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            return test?.description;
        } else {
            return item.translations[0]?.description;
        }
    };

    return (
        <View
            focusContext={focusContext}
            style={{
                zIndex: 1000,
                width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : getRealWidth(deviceContext, 'screen'),
                backgroundColor: '#00000099',
                position: 'absolute',
                height: deviceContext.isPhone ? getRealWidth(deviceContext, 'screen') : getRealHeight(deviceContext),
                flexDirection: 'column',
            }}
        >
            <View style={{ flex: 5 }}>
                <View style={{ zIndex: 10 }}>
                    <Pressable
                        style={{
                            margin: 40,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            padding: getCorrectHeight(deviceContext, 3),
                            borderRadius: 100,
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        hasPreferredFocus={false}
                        onPress={() => onHide()}
                        focusOptions={{ focusKey: 'close-btn', nextFocusDown: 'seasons-selector' }}
                    >
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: 100,
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
                <View style={{ position: 'absolute' }}>
                    <Image
                        isCache={true}
                        deviceContext={deviceContext}
                        source={{ uri: appContext.cloudUrl + contentContext.seriesData.series.seasons[selectedSeasonIndex].images.background }}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        style={{
                            height: deviceContext.isPhone ? getRealWidth(deviceContext, 'screen') : getRealHeight(deviceContext),
                            width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : getRealWidth(deviceContext),
                        }}
                    />
                </View>
                <View style={{ position: 'absolute' }}>
                    <Image_
                        source={require('../../../../../assets/images/cover-bg.png')}
                        resizeMethod={'scale'}
                        resizeMode={'stretch'}
                        style={{
                            height: deviceContext.isPhone ? getRealWidth(deviceContext, 'screen') : getRealHeight(deviceContext),
                            width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : getRealWidth(deviceContext),
                        }}
                    ></Image_>
                </View>
            </View>
            <View
                style={{
                    zIndex: 10,
                    flex: 1,
                    flexDirection: 'row',
                    marginLeft: 50,
                    marginBottom: 10,
                    borderRadius: getPressableStyling(styling?.components?.button?._id, appContext).borderRadius,
                }}
                focusOptions={{ group: 'seasons-selector', focusKey: 'seasons-selector', nextFocusDown: 'episodes-selector', nextFocusUp: 'close-btn' }}
            >
                <ScrollView contentContainerStyle={{ alignItems: 'center' }} horizontal={true}>
                    {contentContext.seriesData.series.seasons.map((season, index) => {
                        return (
                            <Pressable
                                focusContext={focusContext}
                                style={{
                                    width: 250,
                                    ...getPressableStyling(styling?.components?.button?._id, appContext),
                                    backgroundColor: index === selectedSeasonIndex ? getSelectedStyling(styling?.components?.button?._id, appContext).backgroundColor : '#00000040',
                                    marginRight: getCorrectHeight(deviceContext, 2),
                                }}
                                key={index}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => openCategory(index)}
                                hasPreferredFocus={contentContext.seriesData.seasonCategoryIndex === index}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                            marginHorizontal: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {season.number}. {season.name}
                                        </Text>
                                    </View>
                                    {index === selectedSeasonIndex && (
                                        <View
                                            style={{
                                                width: getCorrectWidth(deviceContext, 20),
                                                flexDirection: 'row',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                //height: getCorrectHeight(deviceContext, 30),
                                                marginRight: getCorrectHeight(deviceContext, 5),
                                                borderRadius: getCorrectWidth(deviceContext, 3),
                                                marginVertical: getCorrectHeight(deviceContext, 2),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, 'Normal')}></FontAwesomeIcon>
                                        </View>
                                    )}
                                </View>
                            </Pressable>
                        );
                    })}
                </ScrollView>
            </View>
            <View
                style={{ zIndex: 10, paddingLeft: 50, flex: 5, width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext) }}
                focusOptions={{ group: 'episodes-selector', focusKey: 'episodes-selector', nextFocusUp: 'seasons-selector' }}
            >
                <FlashList
                    //to reset scroll offset and avoid scroll overflow on data change
                    key={selectedSeasonIndex}
                    horizontal={true}
                    data={contentContext.seriesData.series.seasons[selectedSeasonIndex].episodes}
                    showsVerticalScrollIndicator={false}
                    estimatedItemSize={getCorrectWidth(deviceContext, 170) + getCorrectHeight(deviceContext, 10)}
                    drawDistance={getCorrectWidth(deviceContext, 160) * 2}
                    style={{ flex: 1 }}
                    renderItem={({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
                        var progress = -1;
                        var watching = watchingStatus('Series', contentContext.seriesData.series._id, userContext);
                        if (watching != undefined && watching.continue != undefined) {
                            var episode = watching.continue.episodes?.find((e) => e?._id == item?._id);
                            if (episode != undefined) {
                                progress = (episode.position / episode.duration) * 100;
                            }
                        }
                        return (
                            <Pressable
                                focusContext={focusContext}
                                focusRepeatContext={focusRepeatContext}
                                style={{
                                    width: getCorrectWidth(deviceContext, 170),
                                    height: getCorrectWidth(deviceContext, 160),
                                    backgroundColor: '#00000050',
                                    borderRadius: getPressableStyling(styling?.components?.button?._id, appContext).borderRadius,
                                    marginRight: getCorrectHeight(deviceContext, 10),
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => {
                                    contentContext.setSeriesData({
                                        ...contentContext.seriesData,
                                        season: contentContext.seriesData.series.seasons[selectedSeasonIndex],
                                        episodeIndex: index,
                                        episode: item,
                                        episodeList: contentContext.seriesData.series.seasons[selectedSeasonIndex].episodes,
                                        seasonCategoryIndex: selectedSeasonIndex,
                                    });
                                    playEpisodeIndex();
                                }}
                            >
                                <View style={{ flex: 1, justifyContent: 'center', margin: getCorrectWidth(deviceContext, 5) }}>
                                    <View>
                                        <View>
                                            <Image
                                                isCache={true}
                                                deviceContext={deviceContext}
                                                source={{ uri: appContext.cloudUrl + item?.images?.widescreen }}
                                                resizeMethod={'scale'}
                                                resizeMode={'cover'}
                                                style={{
                                                    height: getCorrectWidth(deviceContext, 90),
                                                    width: getCorrectWidth(deviceContext, 160),
                                                }}
                                            />
                                            <View style={{ position: 'absolute', zIndex: 1 }}>
                                                <Image_
                                                    source={require('../../../../../assets/images/episodes-overlay.png')}
                                                    resizeMethod={'scale'}
                                                    resizeMode={'stretch'}
                                                    style={{
                                                        height: getCorrectWidth(deviceContext, 90),
                                                        width: getCorrectWidth(deviceContext, 160),
                                                    }}
                                                ></Image_>
                                            </View>
                                        </View>
                                        <View style={{ position: 'absolute', bottom: 10, alignItems: 'center', width: getCorrectWidth(deviceContext, 160) }}>
                                            {progress > -1 && (
                                                <View
                                                    style={{
                                                        width: getCorrectWidth(deviceContext, 150),
                                                        backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                                        height: getCorrectHeight(deviceContext, 2),
                                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                        shadowOffset: { width: -1, height: 1 },
                                                        shadowRadius: 2,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            backgroundColor: appContext.application.theme.progresses.progress,
                                                            height: getCorrectHeight(deviceContext, 2),
                                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                            shadowOffset: { width: -1, height: 1 },
                                                            shadowRadius: 2,

                                                            width: progress + '%',
                                                        }}
                                                    ></View>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, width: getCorrectWidth(deviceContext, 160) }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginVertical: 10,
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {item.number}. {item.name}
                                        </Text>
                                        <Text
                                            numberOfLines={5}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                lineHeight: getFontSize(deviceContext, 'Small'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getDescription(item)}
                                        </Text>
                                    </View>
                                </View>
                            </Pressable>
                        );
                    }}
                    //applying only to the season that the episode is played from
                    {...(selectedSeasonIndex === contentContext.seriesData.seasonCategoryIndex && {
                        initialScrollIndex: deviceContext.isPhone
                            ? Math.min(contentContext.seriesData.episodeIndex, contentContext.seriesData.episodeList.length - 4 > 0 ? contentContext.seriesData.episodeList.length - 4 : Number.MAX_SAFE_INTEGER)
                            : contentContext.seriesData.episodeIndex,

                        initialFocusIndex: contentContext.seriesData.episodeIndex,
                    })}
                    type={'row'}
                />
            </View>
        </View>
    );
};

export default MiniSeries;
