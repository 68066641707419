import { MutableRefObject } from 'react';
import { View } from 'react-native';
import _ from 'lodash';

export const getUsedHeight = (appContext: any, screenName: any, gridMargin: any, extra: any, insets = 0, tabBarHeight = 0) => {
    var usedHeight = 0;
    var elements = appContext.application.screens.find((s) => s.type == screenName);

    if (elements != undefined) {
        elements.content.forEach((element) => {
            if (element.filterbars != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.filterbars.general.margin_top + element.filterbars.general.border_width * 2);
            }
            if (element.menus != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.menus.general.margin_top + element.menus.general.border_width * 2);
            }
        });
        elements.header.forEach((element) => {
            if (element.messages != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.messages.general.margin_top + element.messages.general.border_width * 2) + 30;
            }
        });
    }
    return usedHeight + insets + tabBarHeight;
};

export const getGridData = async (userContext, screenName, contentContext, appContext) => {
    if (screenName == 'Recordings' && userContext.profile.recordings != undefined) {
        return userContext.profile.recordings;
    }
    if (screenName == 'Channels' && contentContext.channels.categories != undefined) {
        var channels =
            contentContext.channels.categories[
                contentContext.channelData.localCategoryIndex != 0
                    ? contentContext.channelData.localCategoryIndex
                    : contentContext.channelData.channelCategoryIndex
            ].channels;
        if (channels != undefined) {
            return channels.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Cams' && contentContext.cams.categories != undefined) {
        var cams =
            contentContext.cams.categories[
                contentContext.camData.localCategoryIndex != 0 ? contentContext.camData.localCategoryIndex : contentContext.camData.camCategoryIndex
            ].cams;
        if (cams != undefined) {
            return cams.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Series' && contentContext.series.categories != undefined) {
        return contentContext.series.categories[
            contentContext.seriesData.localCategoryIndex != 0
                ? contentContext.seriesData.localCategoryIndex
                : contentContext.seriesData.seriesCategoryIndex
        ].series;
    }
    if (screenName == 'Seasons' && contentContext.seriesData.seasons != undefined) {
        return contentContext.seriesData.season.episodes;
    }
    if (screenName == 'Courses' && contentContext.courses.categories != undefined) {
        return contentContext.courses.categories[
            contentContext.courseData.localCategoryIndex != 0
                ? contentContext.courseData.localCategoryIndex
                : contentContext.courseData.courseCategoryIndex
        ].courses;
    }
    if (screenName == 'Course' && contentContext.courseData.course.lessons != undefined) {
        return contentContext.courseData.course.lessons;
    }
    if (screenName == 'Radio' && contentContext.radios.categories != undefined) {
        var radio =
            contentContext.radios.categories[
                contentContext.radioData.localCategoryIndex != 0
                    ? contentContext.radioData.localCategoryIndex
                    : contentContext.radioData.radioCategoryIndex
            ].radios;
        if (radio != undefined) {
            return radio.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Podcasts' && contentContext.podcasts.categories != undefined) {
        return contentContext.podcasts.categories[
            contentContext.podcastData.localCategoryIndex != 0
                ? contentContext.podcastData.localCategoryIndex
                : contentContext.podcastData.podcastCategoryIndex
        ].podcasts;
    }
    if (screenName == 'Music' && contentContext.albums.categories != undefined) {
        return contentContext.albums.categories[
            contentContext.albumData.localCategoryIndex != 0
                ? contentContext.albumData.localCategoryIndex
                : contentContext.albumData.albumCategoryIndex
        ].albums;
    }
    if (screenName == 'Movies' && contentContext.movies?.categories != undefined) {
        return contentContext.movies.categories[
            contentContext.movieData.localCategoryIndex != 0
                ? contentContext.movieData.localCategoryIndex
                : contentContext.movieData.movieCategoryIndex
        ].movies;
    }
    if (screenName == 'Games' && contentContext.games.categories != undefined) {
        return contentContext.games.categories[
            contentContext.gameData.localCategoryIndex != 0
                ? contentContext.gameData.localCategoryIndex
                : contentContext.gameData.gameCategoryIndex
        ].games;
    }
    if (screenName == 'Shorts' && contentContext.shorts.categories != undefined) {
        return contentContext.shorts.categories[
            contentContext.shortData.localCategoryIndex != 0
                ? contentContext.shortData.localCategoryIndex
                : contentContext.shortData.shortCategoryIndex
        ].shorts;
    }
    return [];
};

export const calculateGridHeight = async (wrapperRef: MutableRefObject<View>, insets = 0, tabBarHeight = 0): Promise<number> => {
    return new Promise((resolve) => {
        if (wrapperRef.current) {
            wrapperRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
                resolve((pageY ? pageY : 0) + insets + tabBarHeight);
            });
        } else {
            resolve(insets + tabBarHeight);
        }
    });
};