import { FocusContext, View, Image, Pressable } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getAdsStyling } from '../../components/helpers/helper';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';

export interface Data {
    image: string;
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    fromLoader?: boolean;
}

export const Promotions_Ads = ({ styling, focusContext, fromLoader }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState<Data>([] as any);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();

    // const getFullscreenAd = () => {
    //     // @ts-expect-error
    //     getKaiAd({
    //         publisher: styling.publisher_id,
    //         app: styling.app_name,
    //         slot: styling.slot_name,
    //         onerror: (err) => console.error('Custom catch:', err),
    //         onready: (ad) => {
    //             // Ad is ready to be displayed
    //             // calling 'display' will display the ad
    //             ad.call('display');
    //         },
    //     });
    // };
    const getResponseAd = () => {
        // @ts-expect-error
        getKaiAd({
            publisher: styling.publisher_id,
            app: styling.app_name,
            slot: styling.slot_name,
            h: 264,
            w: 240,
            // Max supported size is 240x264
            // container is required for responsive ads
            container: document.getElementById('ad-container'),
            onerror: (err) => console.error('Custom catch:', err),
            onready: (ad) => {
                // Ad is ready to be displayed
                // calling 'display' will display the ad
                ad.call('display', {
                    // In KaiOS the app developer is responsible
                    // for user navigation, and can provide
                    // navigational className and/or a tabindex
                    tabindex: 0,

                    // if the application is using
                    // a classname to navigate
                    // this classname will be applied
                    // to the container
                    navClass: 'items',

                    // display style will be applied
                    // to the container block or inline-block
                    display: 'block',
                });
            },
        });
    };

    useEffectOnce(() => {
        getResponseAd();
    });

    return (
        <View focusContext={focusContext}>
            {show && (
                <Pressable
                    focusOptions={{
                        animator: {
                            type: 'scale',
                            focus: {
                                scale: 1.01,
                                borderWidth: 0,
                            },
                        },
                    }}
                    hasPreferredFocus={false}
                    style={{
                        marginLeft:  getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                        ...getAdsStyling(appContext),
                    }}
                >
                    <View
                        style={{
                            ...getAdsStyling(appContext),
                            display: 'flex',
                            flex: 1,
                            padding: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 5 : 10),
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <div id="ad-container"></div>
                    </View>
                </Pressable>
            )}
        </View>
    );
};

export default Promotions_Ads;
