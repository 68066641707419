import { isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { Dimensions, Platform, ViewStyle } from 'react-native';

import {
    AdEvent,
    AdIntegrationKind,
    DurationChangeEvent,
    EdgeStyle,
    LoadedMetadataEvent,
    PlayerConfiguration,
    PlayerEventType,
    PresentationMode,
    RenderingTarget,
    TextTrackKind,
    TextTrackMode,
    TextTrackType,
    THEOplayer,
    THEOplayerView,
} from 'react-native-theoplayer';
import { PresentationModeChangeEvent } from 'theoplayer';
import { sendErrorReport } from '../../../../reporting/apis/error';
import { sendWatchedReport } from '../../../../reporting/apis/watched';
import { errorType } from '../../../../reporting/models/apps/error';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import Event from '../../../events';
import lang from '../../../languages/languages';
import { getLanguagFromPlayer } from '../../elements/players/helpers/functions';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { contentType, mainType } from '../../../../reporting/models/shared/content';

export type PlayerProps = {
    adsEnabled?: boolean;
    castEnable?: boolean;
    source?: string;
    epgPlayer?: boolean;
    style?: ViewStyle;
    startPiP?: () => void;
};

const testAdTagUrl =
    'https://pubads.g.doubleclick.net/gampad/ads?' + 'iu=/21775744923/external/single_ad_samples&sz=640x480&' + 'cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&' + 'gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';

const TheoPlayer = ({ style, source, epgPlayer, startPiP }: PlayerProps) => {
    const theoPlayer = React.useRef<THEOplayer>();
    const playerContext = React.useContext(PlayerContext);
    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const client = global.useRemoteMediaClient;

    enum AspectRatio {
        FIT = 'fit',
        FILL = 'fill',
        ASPECT_FILL = 'aspectFill',
    }

    React.useEffect(() => {
        if (deviceContext.isWebTV || deviceContext.isSmartTV) {
            var resizeMode = '';
            if (playerContext.aspectRatio == 'Stretch') {
                resizeMode = 'fill';
            }
            if (playerContext.aspectRatio == 'Cover') {
                resizeMode = 'cover';
            }
            if (playerContext.aspectRatio == 'Contain') {
                resizeMode = 'contain';
            }
            // @ts-expect-error
            $('.theoplayer-container video').css('object-fit', resizeMode);
        } else if (theoPlayer.current) {
            if (playerContext.aspectRatio == 'Stretch') {
                theoPlayer.current.aspectRatio = AspectRatio.FILL;
            }
            if (playerContext.aspectRatio == 'Cover') {
                theoPlayer.current.aspectRatio = AspectRatio.FIT;
            }
            if (playerContext.aspectRatio == 'Contain') {
                theoPlayer.current.aspectRatio = AspectRatio.ASPECT_FILL;
            }
        }
    }, [playerContext.aspectRatio, theoPlayer.current]);

    React.useEffect(() => {
        if (client) {
            client.loadMedia({
                mediaInfo: {
                    contentUrl: source ?? playerContext.stream.url,
                    contentType: playerContext.stream.type,
                },
            });
        }
    }, [client]);

    // React.useEffect(() => {
    //     if (theoPlayer.current != undefined) {
    //         if (playerContext) {
    //             if (deviceContext.isWebTV || deviceContext.isPhone || deviceContext.isTablet) {
    //                 if (playerContext.pip) {
    //                     theoPlayer.current.presentationMode = PresentationMode.pip;
    //                 }
    //             }
    //         }
    //     }
    // }, [playerContext.pip]);

    React.useEffect(() => {
        if (theoPlayer.current != undefined) {
            if (playerContext) {
                theoPlayer.current.textTrackStyle.backgroundColor = playerContext.trackStyle.backgroundColor;
                theoPlayer.current.textTrackStyle.windowColor = playerContext.trackStyle.backgroundColor;
                theoPlayer.current.textTrackStyle.fontColor = playerContext.trackStyle.fontColor;
                theoPlayer.current.textTrackStyle.fontSize = playerContext.trackStyle.fontSize;
                theoPlayer.current.textTrackStyle.fontFamily = playerContext.trackStyle.fontFamily;
                theoPlayer.current.textTrackStyle.edgeStyle = playerContext.trackStyle.edgeStyle;
            }
        }
    }, [playerContext.trackStyle, theoPlayer.current]);

    React.useEffect(() => {
        if (theoPlayer.current) {
            if (playerContext.paused == true) {
                theoPlayer.current.pause();
            } else if (playerContext.paused == false) {
                theoPlayer.current.play();
            }
        }
    }, [playerContext.paused]);

    React.useEffect(() => {}, [playerContext.seekSetPositionDelayed]);

    React.useEffect(() => {
        if (!isNaN(playerContext.seekSetPosition) && theoPlayer.current) {
            global.seekPosition = playerContext.seekSetPosition;
            theoPlayer.current.currentTime = playerContext.seekSetPosition * 1000;
        }
    }, [playerContext.seekSetPosition]);

    React.useEffect(() => {
        if (theoPlayer.current != undefined && theoPlayer.current.audioTracks.length > 0 && playerContext.player.audioTracks != undefined && playerContext.player.audioTracks.length > 0) {
            theoPlayer.current.selectedAudioTrack = playerContext.audioTrackIndex;
        }
    }, [playerContext.audioTrackIndex]);

    React.useEffect(() => {
        if (theoPlayer.current != undefined && theoPlayer.current.textTracks.length > 0 && playerContext.player.textTracks != undefined && playerContext.player.textTracks.length > 0) {
            theoPlayer.current.selectedTextTrack = playerContext.textTrackIndex;
        }
    }, [playerContext.textTrackIndex]);

    var start = moment().unix();
    var content = [] as any;
    var adsEnabled = false;

    React.useEffect(() => {
        return () => {
            let duration = moment().unix() - start;
            if (duration > 5) {
                sendWatchedReport(appContext, deviceContext, userContext, duration, content);
            }
        };
    }, [playerContext.stream]);

    useEffectOnce(() => {
        startPlayer();
    });

    React.useEffect(() => {
        if (theoPlayer.current) {
            if (playerContext.casting.type == 'Chromecast') {
                const inConnection = theoPlayer.current.cast.chromecast.state === 'connected';
                if (!inConnection) {
                    theoPlayer.current.cast.chromecast.start();
                }
            }
            if (playerContext.casting.type == 'Airplay') {
                const inConnection = theoPlayer.current.cast.airplay.state === 'connected';
                if (!inConnection) {
                    theoPlayer.current.cast.airplay.start();
                }
            }
            if (playerContext.casting.type == '') {
                theoPlayer.current.cast.chromecast.stop();
            }
        }
    }, [playerContext.casting]);

    React.useEffect(() => {
        return () => {
            if (theoPlayer.current) {
                theoPlayer.current.removeEventListener(PlayerEventType.AD_EVENT, adsEventListener);
            }
        };
    }, []);

    const startPlayer = () => {
        var setDrmConfiguration;
        if (playerContext.drm.supplier == 'buydrm') {
            setDrmConfiguration = {
                integration: 'keyos',
                widevine: {
                    licenseAcquisitionURL: deviceContext.isApple ? playerContext.drm.licenseServerApple : playerContext.drm.licenseServer,
                    headers: {
                        customData: playerContext.drm.headers.customData,
                    },
                },
            };
        } else if (playerContext.drm.supplier == 'irdeto') {
            setDrmConfiguration = {
                integration: 'irdeto',
                widevine: {
                    certificateURL: playerContext.drm.certificateUrlIrdeto,
                    licenseAcquisitionURL: 'https://wv-keyos.licensekeyserver.com/licenseServer/widevine/v1/' + playerContext.drm.accountId + '/license',
                },
                contentId: 'channel1',
            };
        }

        var setMetadataConfiguration = [] as any;
        var setAdsConfiguration = [] as any;

        //if (castEnabled == undefined) {
        if (playerContext.player.type == 'Albums') {
            content = {
                contentType: contentType.SONG,
                mainType: mainType.MUSIC,
                id: contentContext.albumData.album._id,
                name: contentContext.albumData.album.name ?? '',
                subname: contentContext.albumData.album?.songs[contentContext.albumData.songIndex]?.name ?? '',
                image: appContext.cloudUrl + contentContext.albumData.album.images?.square,
                provider: contentContext.albumData.album.provider ?? 'No Provider',
                tags: contentContext.albumData.album.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.albumData.album.name,
                    subtitle: contentContext.albumData.album.artist,
                    displayIconUri: appContext.cloudUrl + contentContext.albumData.album.images?.square,
                },
            };
        }
        if (playerContext.player.type == 'Podcasts') {
            content = {
                contentType: contentType.POD,
                mainType: mainType.PODCAST,
                id: contentContext.podcastData.podcast._id,
                name: contentContext.podcastData.podcast.name ?? '',
                subname: contentContext.podcastData.podcast?.episodes[contentContext.podcastData.episodeIndex]?.name ?? '',
                image: appContext.cloudUrl + contentContext.podcastData.podcast.images?.square,
                provider: contentContext.podcastData.podcast.provider ?? 'No Provider',
                tags: contentContext.podcastData.podcast.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.podcastData.podcast.name,
                    subtitle: contentContext.podcastData.podcast.creator,
                    displayIconUri: appContext.cloudUrl + contentContext.podcastData.podcast.images?.square,
                },
            };
        }
        if (playerContext.player.type == 'Movies') {
            content = {
                contentType: contentType.MOVIE,
                mainType: mainType.MOVIE,
                id: contentContext.movieData.movie._id,
                name: contentContext.movieData.movie.name ?? '',
                subname: '',
                image: appContext.cloudUrl + contentContext.movieData.movie?.images?.poster,
                provider: contentContext.movieData.movie.provider?.name ?? 'No Provider',
                tags: contentContext.movieData.movie.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.movieData.movie.name ?? '',
                    subtitle: contentContext.movieData.movie.released ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.movieData.movie?.images?.poster,
                },
            };
            if (contentContext.movieData.movie.ads?.vast_url != undefined && contentContext.movieData.movie.ads?.vast_url != '' && !deviceContext.isKaiOs) {
                adsEnabled = true;
                setAdsConfiguration = {
                    ads: [
                        {
                            integration: 'google-ima' as AdIntegrationKind,
                            sources: {
                                src: contentContext.movieData.movie.ads?.vast_url,
                            },
                        },
                    ],
                };
            }
        }
        if (playerContext.player.type == 'Series') {
            content = {
                contentType: contentType.EPISODE,
                mainType: mainType.SERIES,
                id: contentContext.seriesData.series._id,
                name: contentContext.seriesData.series.name ?? '',
                subname: contentContext.seriesData.episode.name ?? '',
                image: appContext.cloudUrl + contentContext.seriesData.episode.images?.widescreen,
                provider: contentContext.seriesData.series.provider?.name ?? 'No Provider',
                tags: contentContext.seriesData.season.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.seriesData.episode.name ?? '',
                    subtitle: contentContext.seriesData.series.name ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.seriesData.episode.images?.widescreen,
                },
            };
            if (contentContext.seriesData.series.ads?.vast_url != undefined && contentContext.seriesData.series.ads?.vast_url != '' && !deviceContext.isKaiOs) {
                adsEnabled = true;
                setAdsConfiguration = {
                    ads: [
                        {
                            integration: 'google-ima' as AdIntegrationKind,
                            sources: {
                                src: contentContext.seriesData.series.ads?.vast_url,
                            },
                        },
                    ],
                };
            }
        }
        if (playerContext.player.type == 'Channels') {
            content = {
                contentType: contentType.CHANNEL,
                mainType: mainType.CHANNEL,
                id: contentContext.channelData.channel._id,
                name: contentContext.channelData.channel.name ?? '',
                subname: contentContext.channelData.program?.n ?? '',
                image: appContext.cloudUrl + contentContext.channelData.channel.images?.square,
                provider: contentContext.channelData.channel.provider ?? 'No Provider',
                tags: contentContext.channelData.channel.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.channelData.channel.name ?? '',
                    subtitle: contentContext.channelData.program?.n ?? '',
                    artist: contentContext.channelData.program?.n ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.channelData.channel.images?.square,
                },
            };

            if (contentContext.channelData.channel.ads?.vast_url != undefined && contentContext.channelData.channel.ads?.vast_url != '' && !deviceContext.isKaiOs) {
                adsEnabled = true;
                setAdsConfiguration = {
                    ads: [
                        {
                            integration: 'google-ima' as AdIntegrationKind,
                            sources: {
                                src: contentContext.channelData.channel.ads?.vast_url,
                            },
                        },
                    ],
                };
            }
        }
        if (playerContext.player.type == 'Cams') {
            content = {
                contentType: contentType.CAM,
                mainType: mainType.CAM,
                id: contentContext.camData.cam._id,
                name: contentContext.camData.cam.name ?? '',
                subname: contentContext.camData.program?.n ?? '',
                image: appContext.cloudUrl + contentContext.camData.cam.images?.square,
                provider: contentContext.camData.cam.provider ?? 'No Provider',
                tags: contentContext.camData.cam.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.camData.cam.name ?? '',
                    subtitle: contentContext.camData.program?.n ?? '',
                    artist: contentContext.camData.program?.n ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.camData.cam.images?.square,
                },
            };
            if (contentContext.camData.cam.ads?.vast_url != undefined && contentContext.camData.cam.ads?.vast_url != '' && !deviceContext.isKaiOs) {
                adsEnabled = true;
                setAdsConfiguration = {
                    ads: [
                        {
                            integration: 'google-ima' as AdIntegrationKind,
                            sources: {
                                src: contentContext.camData.cam.ads?.vast_url,
                            },
                        },
                    ],
                };
            }
        }
        if (playerContext.player.type == 'Radio') {
            content = {
                contentType: contentType.RADIO,
                mainType: mainType.RADIO,
                id: contentContext.radioData.radio._id,
                name: contentContext.radioData.radio.name ?? '',
                subname: contentContext.radioData.program?.n ?? '',
                image: appContext.cloudUrl + contentContext.radioData.radio.images?.square,
                provider: contentContext.radioData.radio.provider ?? 'No Provider',
                tags: contentContext.radioData.radio.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.radioData.radio.name ?? '',
                    subtitle: contentContext.radioData.program?.n ?? '',
                    artist: contentContext.radioData.program?.n ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.radioData.radio.images?.square,
                },
            };
        }
        if (playerContext.player.type == 'CatchupTV') {
            content = {
                contentType: contentType.CATCHUPTV,
                mainType: mainType.CATCHUPTV,
                id: contentContext.channelData.channel._id,
                name: contentContext.channelData.channel.name ?? '',
                subname: contentContext.channelData.program?.n ?? '',
                image: appContext.cloudUrl + contentContext.channelData.channel.images?.square,
                provider: contentContext.channelData.channel.provider ?? 'No Provider',
                tags: contentContext.channelData.channel.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.channelData.channel.name ?? '',
                    subtitle: contentContext.channelData.program?.n ?? '',
                    artist: contentContext.channelData.program?.n ?? '',
                    displayIconUri: appContext.cloudUrl + contentContext.channelData.channel.images?.square,
                },
            };
        }
        if (playerContext.player.type == 'Recordings') {
            content = {
                contentType: contentType.RECORDINGS,
                mainType: mainType.RECORDINGS,
                id: contentContext.recordingData.channel._id,
                name: contentContext.recordingData.program.n ?? '',
                subname: contentContext.recordingData.channel.name ?? '',
                image: appContext.cloudUrl + contentContext.recordingData.program.m,
                provider: contentContext.recordingData.program.provider ?? 'No Provider',
                tags: contentContext.recordingData.channel.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.recordingData.program.n ?? '',
                    subtitle: contentContext.recordingData.channel.name ?? '',
                    displayIconUri: contentContext.recordingData.program.m ?? '',
                    artist: contentContext.recordingData.channel.name ?? '',
                },
            };
        }
        if (playerContext.player.type == 'Courses') {
            content = {
                contentType: contentType.LESSON,
                mainType: mainType.COURSE,
                id: contentContext.courseData.course._id,
                name: contentContext.courseData.course.name ?? '',
                subname: contentContext.courseData.lesson.name ?? '',
                image: appContext.cloudUrl + contentContext.courseData.lesson.images?.widescreen,
                provider: contentContext.courseData.course.provider ?? 'No Provider',
                tags: contentContext.courseData.course.tags,
            };
            setMetadataConfiguration = {
                metadata: {
                    title: contentContext.courseData.course.name ?? '',
                    subtitle: '',
                    displayIconUri: appContext.cloudUrl + contentContext.courseData.course.images?.square,
                },
            };
            if (contentContext.courseData.course.ads?.vast_url != undefined && contentContext.courseData.course.ads?.vast_url != '' && !deviceContext.isKaiOs) {
                adsEnabled = true;
                setAdsConfiguration = {
                    ads: [
                        {
                            integration: 'google-ima' as AdIntegrationKind,
                            sources: {
                                src: contentContext.courseData.course.ads?.vast_url,
                            },
                        },
                    ],
                };
            }
        }
        // }

        if (playerContext.stream.vast != undefined && playerContext.stream.vast != '') {
            adsEnabled = true;
            setAdsConfiguration = {
                ads: [
                    {
                        integration: 'google-ima' as AdIntegrationKind,
                        sources: {
                            src: playerContext.stream.vast,
                        },
                    },
                ],
            };
        }

        if (theoPlayer.current) {
            theoPlayer.current.source = {
                sources: [
                    {
                        src: source ?? playerContext.stream.url,
                        type: playerContext.stream.type,
                        contentProtection: setDrmConfiguration,
                        dash: {
                            ignoreAvailabilityWindow: true,
                        },
                    },
                ],
                ...setAdsConfiguration,
                ...setMetadataConfiguration,
            };
        }
    };

    const getL = (c) => {
        try {
            if (c == null || c == '' || !c) return '';
            return isPlatformIos ? c : new Intl.DisplayNames([lang.getLanguageCode(userContext.selectedLanguage)], { type: 'language' }).of(c);
        } catch (e) {
            return '';
        }
    };

    const adsEventListener = (event: AdEvent) => {};

    const audioBackground = deviceContext.isPhone || deviceContext.isTablet || deviceContext.isWebTV ? true : false;

    const onPlayerReady = React.useCallback(
        (player: THEOplayer) => {
            theoPlayer.current = player;
            player.autoplay = true;
            player.backgroundAudioConfiguration = {
                enabled: audioBackground,
            };

            player.pipConfiguration = { startsAutomatically: true };

            if (deviceContext.isPhone) {
                player.renderingTarget = RenderingTarget.TEXTURE_VIEW; // default is SURFACE_VIEW
            }

            if (adsEnabled) {
                player.addEventListener(PlayerEventType.AD_EVENT, adsEventListener);
            }

            player.addEventListener(PlayerEventType.PRESENTATIONMODE_CHANGE, (event: PresentationModeChangeEvent) => {
                const newPresentationMode = event.presentationMode;
                if (newPresentationMode == PresentationMode.pip) {
                    player.presentationMode = PresentationMode.pip;

                    // startPiP?.();
                    if (deviceContext.isWebTV || deviceContext.isSmartTV) {
                        // @ts-expect-error
                        $('.theoplayer-container video').css('object-fit', 'fill');
                    } else {
                        player.aspectRatio = AspectRatio.FILL;
                    }
                } else {
                    // playerContext.setPip(false);
                    if (deviceContext.isWebTV || deviceContext.isSmartTV) {
                        var resizeMode = '';
                        if (playerContext.aspectRatio == 'Stretch') {
                            resizeMode = 'fill';
                        }
                        if (playerContext.aspectRatio == 'Cover') {
                            resizeMode = 'cover';
                        }
                        if (playerContext.aspectRatio == 'Contain') {
                            resizeMode = 'contain';
                        }

                        // @ts-expect-error
                        $('.theoplayer-container video').css('object-fit', resizeMode);
                    } else {
                        if (playerContext.aspectRatio == 'Stretch') {
                            player.aspectRatio = AspectRatio.FILL;
                        }
                        if (playerContext.aspectRatio == 'Cover') {
                            player.aspectRatio = AspectRatio.FIT;
                        }
                        if (playerContext.aspectRatio == 'Contain') {
                            player.aspectRatio = AspectRatio.ASPECT_FILL;
                        }
                    }
                }
            });

            player.addEventListener(PlayerEventType.CAST_EVENT, (onCastEvent: any) => {
                playerContext.setCastingStatus(onCastEvent.state);
            });
            player.addEventListener(PlayerEventType.TIME_UPDATE, (timeupdate) => {
                if (playerContext.player.isLive && global.showControls) {
                    if (timeupdate.currentProgramDateTime >= 0) {
                        Event.emit('onPlayerTimeUpdate', timeupdate.currentProgramDateTime / 1000);
                    }
                } else {
                    if (timeupdate.currentTime >= 0) {
                        Event.emit('onPlayerTimeUpdate', timeupdate.currentTime / 1000);
                    }
                }
            });
            player.addEventListener(PlayerEventType.ENDED, () => {
                playerContext.setPlayer({
                    ...playerContext.player,
                    ended: true,
                });
            });
            player.addEventListener(PlayerEventType.ERROR, (event: any) => {
                sendErrorReport(appContext, deviceContext, userContext, errorType.PLAYER_ERROR, content, event.error.errorMessage);

                if (event.error.errorCode == '4000') {
                    if (!deviceContext.isIos) {
                        playerContext.setPlayer({
                            ...playerContext.player,
                            error: 'The provided stream could not be loaded (403)',
                        });
                    }
                } else {
                    if (!deviceContext.isIos) {
                        playerContext.setPlayer({
                            ...playerContext.player,
                            error: event.error.errorMessage,
                        });
                    }
                }
            });
            player.addEventListener(PlayerEventType.DESTROY, () => {});
            player.addEventListener(PlayerEventType.PLAYING, () => {
                if (playerContext.stream.progress > 0) {
                    playerContext.setStream({
                        ...playerContext.stream,
                        progress: 0,
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        seekPosition: playerContext.stream.progress,
                        isPlaying: true,
                    });
                }
            });
            player.addEventListener(PlayerEventType.LOADED_DATA, () => {
                var textTracks = player.textTracks;
                var audioTracks = player.audioTracks;
                textTracks.forEach((track, index) => {
                    // 99999 Means disabled and if it was disabled let's not enable it by default unless user changes settings
                    if (getLanguagFromPlayer(track.language) === userContext.profile.subtitles && playerContext.textTrackIndex !== 99999) {
                        playerContext.setTextTrackIndex(track.uid);
                        theoPlayer.current.selectedTextTrack = track.uid;
                    }
                });
                audioTracks.forEach((track, index) => {
                    if (getLanguagFromPlayer(track.language) == userContext.profile.audio) {
                        playerContext.setAudioTrackIndex(track.uid);
                        theoPlayer.current.selectedAudioTrack = track.uid;
                    }
                });

                if (textTracks.length > 0) {
                    textTracks.unshift({
                        uid: 99999,
                        label: lang.getTranslation(userContext, 'disable_subtitle'),
                        kind: TextTrackKind.subtitles,
                        language: 'disable',
                        id: '',
                        mode: TextTrackMode.disabled,
                        type: TextTrackType.cea608,
                        cues: [],
                        src: '',
                        forced: false,
                    });
                }
                if (!epgPlayer) {
                    playerContext.setPlayer({
                        ...playerContext.player,
                        textTracks: textTracks,
                        audioTracks: audioTracks,
                    });
                }
            });

            player.addEventListener(PlayerEventType.DURATION_CHANGE, (event: DurationChangeEvent) => {
                global.duration = event.duration / 1000;
                playerContext.setDuration(event.duration / 1000);
                playerContext.setPlaying(true);
            });

            player.addEventListener(PlayerEventType.LOADED_METADATA, (event: LoadedMetadataEvent) => {
                var textTracks = player.textTracks as any;
                var audioTracks = player.audioTracks as any;

                textTracks.forEach((track) => {
                    if (track.language != '') {
                        track.name = getL(track.language);
                    }
                });
                audioTracks.forEach((track) => {
                    if (track.language != '') {
                        track.name = getL(track.language);
                    }
                });
                if (!epgPlayer) {
                    playerContext.setPlayer({
                        ...playerContext.player,
                        textTracks: textTracks,
                        audioTracks: audioTracks,
                    });
                }
            });
            startPlayer();
        },
        [theoPlayer]
    );
    var license =
        'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya';
    return <PlayerView licensePlayer={appContext.application.tp == undefined ? license : appContext.application.tp} onPlayerReady={onPlayerReady} playerContext={playerContext} style={style} />;
};

const PlayerView = React.memo(function PlayerView({ licensePlayer, onPlayerReady, playerContext, style }: any) {
    const config: PlayerConfiguration = {
        license: licensePlayer,
        chromeless: true,
        startsAutomatically: true,
        libraryLocation: 'theoplayer',
        cast: {
            chromecast: {
                appID: 'CC1AD845',
            },
            strategy: 'manual',
        },
        mediaControl: {
            mediaSessionEnabled: true,
        },
    };
    //'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
    //You can use the following HLS VOD stream that I also usually take as reference, it has multiple subtitles and audio tracks: https://cdn.theoplayer.com/video/elephants-dream/playlist.m3u8
    //For HLS live: https://radio538.prd1.talpatvcdn.nl/22e9bbf9616547d7bc162c993009c533/index.m3u8
    //For DASH VOD: http://amssamples.streaming.mediaservices.windows.net/7ceb010f-d9a3-467a-915e-5728acced7e3/ElephantsDreamMultiAudio.ism/manifest(format=mpd-time-csf)
    const needsBorder = Platform.OS === 'ios';

    const playerStyles = style
        ? style
        : {
              top: 0,
              left: needsBorder ? 2 : 0,
              bottom: 0,
              right: needsBorder ? 2 : 0,
              zIndex: 0,
              //   //   position: 'absolute',
              width: '100%',
              height: '100%',
          };

    return <THEOplayerView posterActive={false} config={config} onPlayerReady={onPlayerReady} style={playerStyles} />;
});

export default React.memo(TheoPlayer);
