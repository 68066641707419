import _, { merge } from 'lodash';
import moment from 'moment';
export const loadContent = async (appContext, contextApp, contextUser) => {
    filterMenus(contextUser, appContext);
    epgs = [];

    try {
        const response = await Promise.all([
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? getChannelsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Cams') != undefined ? getCamsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? getRadioData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Series') != undefined ? getSeriesData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Movies') != undefined ? getMoviesData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Shorts') != undefined ? getShortsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Courses') != undefined ? getCoursesData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Games') != undefined ? getGamesData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Music') != undefined ? getMusicData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Podcasts') != undefined ? getPodcastsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await getEpgTvData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await getEpgRadioData(0, appContext, contextApp, contextUser) : null,

            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? extractChannelDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Cams') != undefined ? extractCamDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? extractRadioDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Series') != undefined ? extractSeriesDataBundles(0, appContext, contextApp, contextUser) : null,
            //appContext.application.menus?.find((m) => m.name == 'Movies') != undefined ? extractMovieDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Shorts') != undefined ? extractShortDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Courses') != undefined ? extractCoursesDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Games') != undefined ? extractGamesDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Music') != undefined ? extractMusicDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Podcasts') != undefined ? extractPodcastDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await extractEpgChannelDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await extractEpgRadioDataBundles(0, appContext, contextApp, contextUser) : null,
        ]).then((values) => {
            return true;
        });
    } catch (error) {
        return false;
    }
};
export const filterMenus = (contextUser, appContext) => {
    var newMenus = appContext.application.menus;
    var bundles = contextUser.product.bundles.channels;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Channels');
        newMenus = newMenus?.filter((m) => m.name != 'TV Guide');
    }
    var bundles = contextUser.product.bundles.cams;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Cams');
    }
    var bundles = contextUser.product.bundles.radio;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Radio');
    }
    var bundles = contextUser.product.bundles.movies;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Movies');
    }
    var bundles = contextUser.product.bundles.series;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Series');
    }
    var bundles = contextUser.product.bundles.shorts;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Shorts');
    }
    var bundles = contextUser.product.bundles.podcasts;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Podcasts');
    }
    var bundles = contextUser.product.bundles.courses;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Courses');
    }
    var bundles = contextUser.product.bundles.music;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Music');
    }
    var bundles = contextUser.product.bundles.games;
    if (bundles == undefined || bundles.length == 0) {
        newMenus = newMenus?.filter((m) => m.name != 'Games');
    }
    var app = {
        ...appContext.application,
        menus: newMenus,
    };
    appContext.setApplication(app);
};
// export const merge = (arr1, adding) => {
//     arr1.forEach((element, index) => {
//         adding.forEach((element_, index_) => {
//             if (element.name == element_.name) {
//                 //add the content in
//                 element.channels.push(elements)
//             } else {
//                 ///
//             }
//         });
//     });
// };
export const reloadEpg = async (appContext, contextApp, contextUser) => {
    epgs = [];
    try {
        const responses = await Promise.all([
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await getChannelsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await getRadioData(0, appContext, contextApp, contextUser) : null,
            (await appContext.application.menus?.find((m) => m.name == 'Channels')) != undefined ? await getEpgTvData(0, appContext, contextApp, contextUser) : null,
            (await appContext.application.menus?.find((m) => m.name == 'Radio')) != undefined ? await getEpgRadioData(0, appContext, contextApp, contextUser) : null,

            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await extractChannelDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await extractRadioDataBundles(0, appContext, contextApp, contextUser) : null,
            (await appContext.application.menus?.find((m) => m.name == 'Channels')) != undefined ? await extractEpgChannelDataBundles(0, appContext, contextApp, contextUser) : null,
            (await appContext.application.menus?.find((m) => m.name == 'Radio')) != undefined ? await extractEpgRadioDataBundles(0, appContext, contextApp, contextUser) : null,
        ]);
        return true;
    } catch (error) {
        return false;
    }
};
export const reloadEpgChannels = async (appContext, contextApp, contextUser) => {
    epgs = [];
    try {
        const responses = await Promise.all([
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await getChannelsData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await getEpgTvData(0, appContext, contextApp, contextUser) : null,

            (await appContext.application.menus?.find((m) => m.name == 'Channels')) != undefined ? await extractChannelDataBundles(0, appContext, contextApp, contextUser) : null,
            (await appContext.application.menus?.find((m) => m.name == 'Channels')) != undefined ? await extractEpgRadioDataBundles(0, appContext, contextApp, contextUser) : null,
        ]);
        return true;
    } catch (error) {
        return false;
    }
};
export const reloadEpgRadio = async (appContext, contextApp, contextUser) => {
    epgs = [];
    try {
        const responses = await Promise.all([
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await getRadioData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await getEpgRadioData(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await extractRadioDataBundles(0, appContext, contextApp, contextUser) : null,
            appContext.application.menus?.find((m) => m.name == 'Radio') != undefined ? await extractEpgRadioDataBundles(0, appContext, contextApp, contextUser) : null,
        ]);
        return true;
    } catch (error) {
        return false;
    }
};

export const getEpgDataOtherDate = async (appContext, contextApp, contextUser, day) => {
    epgs = [];
    try {
        const responses = await Promise.all([appContext.application.menus?.find((m) => m.name == 'Channels') != undefined ? await getEpgTvDataDate(0, appContext, contextApp, contextUser, day) : null]);
        return epgs;
    } catch (error) {
        return [];
    }
};

const differenceBy = (adding: any, exist: any) => {
    var differenceOut = [] as any;
    adding.forEach((arr) => {
        var test = exist.find((a) => a.translations[0].description == arr.translations[0]?.description);
        if (test == undefined) {
            differenceOut.push(arr);
        }
    });
    return differenceOut;
};

const intersectionBy = (adding: any, exist: any) => {
    var differenceOut = [] as any;
    adding.forEach((arr) => {
        var test = exist.find((a) => a.translations[0].description == arr.translations[0]?.description);
        if (test != undefined) {
            differenceOut.push(arr);
        }
    });
    return differenceOut;
};

//BASE BUNDLES
const getChannelsData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.channels;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getChannelBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var channels = [] as any;
function mergeChannels(exist: any, adding: any) {
    if (exist.length == 0) {
        channels = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        if (difference.length > 0) {
            difference.forEach((difference) => {
                channels.categories.push(difference);
            });
        }
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = channels.categories.findIndex((c) => c.translations[0].description == overlap.translations[0]?.description);
            let difference = _.differenceBy(overlap.channels, channels.categories[categoryIndex].channels, '_id');
            difference.forEach((difference: any) => {
                channels.categories[categoryIndex].channels.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getChannelBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeChannels(channels, data.data[0]);
            if (index < length - 1) {
                return getChannelsData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setChannels(channels);

                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getCamsData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.cams;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getCamsBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var cams = [] as any;
function mergeCams(exist: any, adding: any) {
    if (exist.length == 0) {
        cams = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            cams.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = cams.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.cams, cams.categories[categoryIndex].cams, '_id');
            difference.forEach((difference: any) => {
                cams.categories[categoryIndex].cams.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getCamsBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/cams/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeCams(cams, data[0]);
            if (index < length - 1) {
                return getCamsData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setCams(cams);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getRadioData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.radio;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getRadioBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var radios = [] as any;
function mergeRadios(exist: any, adding: any) {
    if (exist.length == 0) {
        radios = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            radios.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = radios.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.radios, radios.categories[categoryIndex].radios, '_id');
            difference.forEach((difference: any) => {
                radios.categories[categoryIndex].radios.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getRadioBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/radios/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeRadios(radios, data.data[0]);
            if (index < length - 1) {
                return getRadioData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setRadios(radios);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getMoviesData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.movies;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getMoviesBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var movies = [] as any;
function mergeMovies(exist: any, adding: any) {
    console.log(exist);
    if (exist.length == 0) {
        movies = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            movies.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = movies.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.movies, movies.categories[categoryIndex].movies, '_id');
            difference.forEach((difference: any) => {
                movies.categories[categoryIndex].movies.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getMoviesBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeMovies(movies, data[0]);
            if (index < length - 1) {
                return getMoviesData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setMovies(movies);
                return appContext.application.menus?.find((m) => m.name == 'Movies') != undefined ? extractMovieDataBundles(0, appContext, contentContext, contextUser) : { success: true };
                //return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getShortsData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.shorts;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getShortsBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var shorts = [] as any;
function mergeShorts(exist: any, adding: any) {
    if (exist.length == 0) {
        shorts = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            shorts.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = shorts.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.shorts, shorts.categories[categoryIndex].shorts, '_id');
            difference.forEach((difference: any) => {
                shorts.categories[categoryIndex].shorts.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getShortsBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/shorts/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeShorts(shorts, data[0]);
            if (index < length - 1) {
                return getShortsData(index + 1, appContext, contentContext, contextUser);
            } else {
                // var externalCats = shorts.categories.filter((c) => c.external == true);
                // if (externalCats.length > 0) {
                //     const promises = shorts.categories.map((cat) => getExternalBundleData(cat, appContext));
                //     await Promise.all(promises).then((responses) => {
                //         responses.forEach((response, index) => {
                //             if (response.length > 0) {
                //                 shorts.categories[index].shorts = response;
                //             }
                //         });
                //     });
                //     contentContext.setShorts(shorts);
                //     return { success: true };
                // } else {
                contentContext.setShorts(shorts);
                return { success: true };
                //}
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getSeriesData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.series;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getSeriesBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var series = [] as any;
function mergeSeries(exist: any, adding: any) {
    if (exist.length == 0) {
        series = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            series.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = series.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.series, series.categories[categoryIndex].series, '_id');
            difference.forEach((difference: any) => {
                series.categories[categoryIndex].series.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getSeriesBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeSeries(series, data[0]);
            if (index < length - 1) {
                return getSeriesData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setSeries(series);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getCoursesData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.courses;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getCoursesBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var courses = [] as any;
function mergeCourses(exist: any, adding: any) {
    if (exist.length == 0) {
        courses = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            courses.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = courses.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.courses, courses.categories[categoryIndex].courses, '_id');
            difference.forEach((difference: any) => {
                courses.categories[categoryIndex].courses.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getCoursesBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeCourses(courses, data[0]);
            if (index < length - 1) {
                return getCoursesData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setCourses(courses);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getGamesData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.games;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getGamesBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var games = [] as any;
function mergeGames(exist: any, adding: any) {
    if (exist.length == 0) {
        games = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            games.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = games.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.games, games.categories[categoryIndex].games, '_id');
            difference.forEach((difference: any) => {
                games.categories[categoryIndex].games.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getGamesBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeGames(games, data[0]);
            if (index < length - 1) {
                return getGamesData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setGames(games);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getMusicData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.music;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getMusicBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var albums = [] as any;
function mergeAlbums(exist: any, adding: any) {
    if (exist.length == 0) {
        albums = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            albums.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = albums.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.albums, albums.categories[categoryIndex].albums, '_id');
            difference.forEach((difference: any) => {
                albums.categories[categoryIndex].albums.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getMusicBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/albums/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeAlbums(albums, data[0]);
            if (index < length - 1) {
                return getMusicData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setAlbums(albums);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getPodcastsData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.podcasts;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getPodcastBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var podcasts = [] as any;
function mergePodcasts(exist: any, adding: any) {
    if (exist.length == 0) {
        podcasts = adding;
    } else {
        let difference = differenceBy(adding.categories, exist.categories);
        difference.forEach((difference) => {
            podcasts.categories.push(difference);
        });
        let overlap = intersectionBy(adding.categories, exist.categories);
        overlap.forEach((overlap: any) => {
            var categoryIndex = podcasts.categories.findIndex((c) => c.name == overlap.name);
            let difference = _.differenceBy(overlap.podcasts, podcasts.categories[categoryIndex].podcasts, '_id');
            difference.forEach((difference: any) => {
                podcasts.categories[categoryIndex].podcasts.splice(difference.number - 1, 0, difference);
            });
        });
    }
}
const getPodcastBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/podcasts/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergePodcasts(podcasts, data[0]);
            if (index < length - 1) {
                return getPodcastsData(index + 1, appContext, contentContext, contextUser);
            } else {
                contentContext.setPodcasts(podcasts);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};
var epgs = [] as any;

//EXTRA BUNDLES
const extractChannelDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.channels) {
            bundlesOut.push(bundle);
        }
    }
    getChannelsDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getChannelsDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getChannelBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getChannelBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeChannels(channels, data.data[0]);

            if (index < length - 1) {
                return getChannelsDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setChannels(channels);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractCamDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.cams) {
            bundlesOut.push(bundle);
        }
    }
    getCamsDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getCamsDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getCamsBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getCamsBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/cams/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeCams(cams, data[0]);

            if (index < length - 1) {
                return getCamsDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setCams(cams);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractRadioDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.radio) {
            bundlesOut.push(bundle);
        }
    }
    getRadioDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getRadioDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getRadioBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getRadioBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/radios/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeRadios(radios, data.data[0]);

            if (index < length - 1) {
                return getRadioDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setRadios(radios);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractMovieDataBundles = async (index, appContext, contentContext, contextUser) => {
    console.log('extr')
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.movies) {
            bundlesOut.push(bundle);
        }
    }
    getMoviesDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getMoviesDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getMoviesBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getMoviesBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            console.log(data)
            mergeMovies(movies, data[0]);

            if (index < length - 1) {
                return getMoviesDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setMovies(movies);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractShortDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.shorts) {
            bundlesOut.push(bundle);
        }
    }
    getShortsDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getShortsDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getShortsBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getShortsBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/shorts/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeShorts(shorts, data[0]);

            if (index < length - 1) {
                return getShortsDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setShorts(shorts);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractSeriesDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.series) {
            bundlesOut.push(bundle);
        }
    }
    getSeriesDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getSeriesDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getSeriesBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getSeriesBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeSeries(series, data[0]);
            if (index < length - 1) {
                return getSeriesDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setSeries(series);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractCoursesDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.courses) {
            bundlesOut.push(bundle);
        }
    }
    getCoursesDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getCoursesDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getCoursesBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getCoursesBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeCourses(contentContext.courses, data[0]);

            if (index < length - 1) {
                return getCoursesDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setCourses(courses);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractGamesDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.games) {
            bundlesOut.push(bundle);
        }
    }
    getGamesDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getGamesDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getGamesBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getGamesBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeGames(contentContext.games, data[0]);

            if (index < length - 1) {
                return getGamesDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setGames(games);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractMusicDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.albums) {
            bundlesOut.push(bundle);
        }
    }
    getMusicDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getMusicDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getMusicBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getMusicBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/albums/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeAlbums(albums, data[0]);

            if (index < length - 1) {
                return getMusicDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setAlbums(albums);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractPodcastDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.podcasts) {
            bundlesOut.push(bundle);
        }
    }
    getPodcastsDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getPodcastsDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getPodcastBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getPodcastBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/podcasts/bundles/' + _id + '_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergePodcasts(podcasts, data[0]);

            if (index < length - 1) {
                return getPodcastsDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setPodcasts(podcasts);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

//TAGS
// const getMoviesDataTags = async (index, appContext, contentContext, contextUser) => {
//     var bundles = contextUser.product.bundles.movies;
//     if (bundles != undefined && bundles.length > 0) {
//         if (bundles[index] != undefined) {
//             return getMoviesBundleTags(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
//         } else {
//             return { success: true };
//         }
//     } else {
//         return { success: true };
//     }
// };
// const getMoviesBundleTags = async (_id, index, length, appContext, contentContext, contextUser) => {
//     var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/bundles/' + _id + '_tags.json';
//     try {
//         let response = await fetch(path);
//         if (response.status == 200) {
//             let data = await response.json();
//             var merge_ = merge(contentContext.moviesTags, data[0]);
//             contentContext.setMoviesTags(merge_);
//             if (index < length - 1) {
//                 return getMoviesDataTags(index + 1, appContext, contentContext, contextUser);
//             } else {
//                 return { success: true };
//             }
//         } else {
//             return { success: true };
//         }
//     } catch (error) {
//         return { success: true };
//     }
// };
// const getSeriesDataTags = async (index, appContext, contentContext, contextUser) => {
//     var bundles = contextUser.product.bundles.series;
//     if (bundles != undefined && bundles.length > 0) {
//         if (bundles[index] != undefined) {
//             return getSeriesBundleTags(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
//         } else {
//             return { success: true };
//         }
//     } else {
//         return { success: true };
//     }
// };
// const getSeriesBundleTags = async (_id, index, length, appContext, contentContext, contextUser) => {
//     var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/bundles/' + _id + '_tags.json';
//     try {
//         let response = await fetch(path);
//         if (response.status == 200) {
//             let data = await response.json();
//             var merge_ = merge(contentContext.seriesData.seriessTags, data[0]);
//             contentContext.setSeriesTags(merge_);
//             if (index < length - 1) {
//                 return getSeriesDataTags(index + 1, appContext, contentContext, contextUser);
//             } else {
//                 return { success: true };
//             }
//         } else {
//             return { success: true };
//         }
//     } catch (error) {
//         return { success: true };
//     }
// };

///EPGS
var epgs = [] as any;
function mergeEpgs(exist: any, adding: any) {
    if (exist.length == 0) {
        epgs = adding;
    } else {
        let difference = _.differenceBy(adding, exist, 'n');
        difference.forEach((difference) => {
            epgs.push(difference);
        });
    }
}
const getEpgTvData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.channels;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getEpgTvBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getEpgTvBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/' + _id + '_' + date + '_minified_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeEpgs(epgs, data);

            if (index < length - 1) {
                return getEpgTvData(index + 1, appContext, contentContext, contextUser);
            } else {
                epgs.sort(function (a, b) {
                    if (a.d < b.d) {
                        return -1;
                    }
                    if (a.d > b.d) {
                        return 1;
                    }
                    return 0;
                });
                contentContext.setEpgTv(epgs);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const getEpgTvDataDate = async (index, appContext, contentContext, contextUser, day) => {
    var bundles = contextUser.product.bundles.channels;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getEpgTvBundleDate(bundles[index], index, bundles.length, appContext, contentContext, contextUser, day);
        }
    }
};
const getEpgTvBundleDate = async (_id, index, length, appContext, contentContext, contextUser, day) => {
    const date = moment().add(day, 'day').format('DD') + '-' + Number(moment().add(day, 'day').month() + 1) + '-' + moment().add(day, 'day').year();
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/' + _id + '_' + date + '_minified_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeEpgs(epgs, data);

            if (index < length - 1) {
                return getEpgTvDataDate(index + 1, appContext, contentContext, contextUser, day);
            } else {
                epgs.sort(function (a, b) {
                    if (a.d < b.d) {
                        return -1;
                    }
                    if (a.d > b.d) {
                        return 1;
                    }
                    return 0;
                });

                return epgs;
            }
        }
    } catch (error) {
        return { success: true };
    }
};

const getEpgRadioData = async (index, appContext, contentContext, contextUser) => {
    var bundles = contextUser.product.bundles.radio;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getEpgRadioBundle(bundles[index], index, bundles.length, appContext, contentContext, contextUser);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
var epgsr = [] as any;
function mergeEpgsr(exist: any, adding: any) {
    if (exist.length == 0) {
        epgsr = adding;
    } else {
        let difference = _.differenceBy(adding, exist, 'n');
        difference.forEach((difference) => {
            epgsr.push(difference);
        });
    }
}
const getEpgRadioBundle = async (_id, index, length, appContext, contentContext, contextUser) => {
    const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/radios/epg/' + _id + '_' + date + '_minified_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeEpgsr(epgsr, data);
            if (index < length - 1) {
                return getEpgRadioData(index + 1, appContext, contentContext, contextUser);
            } else {
                epgsr.sort(function (a, b) {
                    if (a.d < b.d) {
                        return -1;
                    }
                    if (a.d > b.d) {
                        return 1;
                    }
                    return 0;
                });
                contentContext.setEpgRadio(epgsr);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractEpgRadioDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.radio) {
            bundlesOut.push(bundle);
        }
    }
    getEpgRadioDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getEpgRadioDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    var bundles = contextUser.user.extra_products.radio;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getEpgRadioBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getEpgRadioBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/' + _id + '_' + date + '_minified_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeEpgsr(epgsr, data[0]);
            if (index < length - 1) {
                return getEpgRadioDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setEpgRadio(epgsr);
                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};

const extractEpgChannelDataBundles = async (index, appContext, contentContext, contextUser) => {
    var bundlesOut = [] as any;
    for (var product of contextUser.user.extra_products) {
        for (var bundle of product.product.items.bundles.channels) {
            bundlesOut.push(bundle);
        }
    }
    getEpgTvDataExtra(index, appContext, contentContext, contextUser, bundlesOut);
};
const getEpgTvDataExtra = async (index, appContext, contentContext, contextUser, bundles) => {
    var bundles = contextUser.user.extra_products.channels;
    if (bundles != undefined && bundles.length > 0) {
        if (bundles[index] != undefined) {
            return getEpgTvBundleExtra(bundles[index], index, bundles.length, appContext, contentContext, contextUser, bundles);
        } else {
            return { success: true };
        }
    } else {
        return { success: true };
    }
};
const getEpgTvBundleExtra = async (_id, index, length, appContext, contentContext, contextUser, bundles) => {
    const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
    var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/' + _id + '_' + date + '_minified_bundle.json?t=' + moment().unix();
    try {
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            mergeEpgs(epgs, data[0]);

            if (index < length - 1) {
                return getEpgTvDataExtra(index + 1, appContext, contentContext, contextUser, bundles);
            } else {
                contentContext.setEpgTv(epgs);

                return { success: true };
            }
        } else {
            return { success: true };
        }
    } catch (error) {
        return { success: true };
    }
};
