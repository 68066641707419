import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { isFactorTv, isPlatformAndroid, isPlatformIos, isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, BackHandler, Dimensions, StatusBar, View as RNView, AppState } from 'react-native';
import SystemNavigationBar from 'react-native-system-navigation-bar';
import { Image as Image_, TouchableOpacity, View } from '@scriptx-com/xtv-toolkit';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { ContentContext } from '../../context/contentContext';
import { DeviceContext } from '../../context/deviceContext';
import { PlayerContext } from '../../context/playerContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import Event from '../../events';
import { favoriteStatus, manageFavorite, manageProfiles, manageWatching, payperviewStatus, setRecommendations, watchingStatus } from '../../data/account';
import { getCurrentScreenName } from '../../hooks/getScreenName';
import { useTVRemoteHandler } from '../../hooks/useTVRemoteHandler';
import lang from '../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getFontSize } from '../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../styling/realDeviceSizes';
import { PlayerFadeInOut } from '../animations/playerFadeFadeOut';
import { ScreenFadeIn } from '../animations/screenFadeIn';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../components/helpers/helper';
import Modal from '../components/modal/modal';
import Parental from '../components/parental/parental';
import TheoPlayer from '../components/players/theoPlayer';
import Pressable from '../components/pressable/pressable';
import Text from '../components/text';
import { SettingsView, TextSizingView } from '../elements/players/helpers/settings';
import { checkParentalLockShouldApply, showAirplayPlaying, showChromecastPlaying } from '../elements/players/helpers/functions';
import { getDrm, getToken } from '../elements/players/helpers/security';
import { getCatchupLiveTVStream, getCatchupTVStream, getCatoolaVast, getStreamParams, getStreamTypeCasting } from '../elements/players/helpers/stream';
import MiniEPG from '../elements/players/helpers/miniepg';
import MiniSeries from '../elements/players/helpers/miniseries';
import Player_Standard from '../elements/players/standard';
import MiniShorts from '../elements/players/helpers/minishorts';
import MiniCourses from '../elements/players/helpers/minicourses';
import { RemoteImageHeightWidth } from '../components/remoteimage/remoteimage';
import PayPerView from '../components/payperview/payperview';

const HIDE_CONTROLS_TIMEOUT_MS = 5000;

const Screen_ = ({ navigation, route, focusContext, type }: any): React.ReactElement => {
    const settingsContext = React.useContext(SettingsContext);
    const contentContext = React.useContext(ContentContext);
    const playerContext = React.useContext(PlayerContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);

    const params = route.params;

    const insets = useSafeAreaInsets();
    const wrapperRef = React.useRef<RNView>(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [showProgramDetails, setShowProgramDetails] = useState(false);
    const [showChannelList, setShowChannelList] = React.useState(false);
    const [showEpisodeList, setShowEpisodeList] = React.useState(false);
    const [showLessonList, setShowLessonList] = React.useState(false);
    const [showShortList, setShowShortList] = React.useState(false);
    const [showSettingsMenu, setShowSettingMenu] = React.useState(false);
    const [showTextSizingMenu, setshowTextSizingMenu] = React.useState(false);
    const [showSupportMenu, setshowSupportMenu] = React.useState(false);
    const [parentalApproved, setParentalApproved] = React.useState(false);
    const [showParental, setShowParental] = React.useState(false);
    const [isFavorite, setIsFavorite] = React.useState(false);
    const [styling, setStyling] = React.useState([] as any);
    const screenName = getCurrentScreenName();
    const [isRestart, setIsRestart] = React.useState(false);
    const [timerInternal, setTimerInteral] = React.useState(false);
    const [showSleeptimerModal, setShowSleeptimerModal] = React.useState(false);
    const [showZapper, setShowZapper] = React.useState(false);
    const [paused, setPaused] = React.useState(false);
    const [zapperIndex, setZapperIndex] = React.useState(contentContext.channelData.channelIndex);
    const [osdNumber, setOsdNumber] = React.useState(0);
    const [osdIndex, setOsdIndex] = React.useState(contentContext.channelData.channelIndex);
    const [osdColor, setOsdColor] = React.useState('#999999');
    const [showOsd, setShowOsd] = React.useState(false);
    const [code, setCode] = React.useState('');
    const [startType, setStartType] = useState('');
    const timeout = React.useRef<NodeJS.Timeout>();
    const zaptimeout = React.useRef<NodeJS.Timeout>();
    const [prepareNextEpisode, setPrepareNextEpisode] = useState(false);
    const [countDown, setCountDown] = useState(20);
    const [showPip, setShowPip] = useState(false);
    const [showPayPerView, setShowPayPerView] = useState(false);
    const [isPayPerView, setIsPayPerView] = useState(false);

    const [canShowControls, setCanShowControls] = React.useState(true);

    useEffect(() => {
        if (!showProgramDetails && !showChannelList && !showEpisodeList && !showLessonList && !showSettingsMenu && !showSupportMenu && !showParental && !showShortList && !showTextSizingMenu) {
            setCanShowControls(true);
            global.showContentList = false;
        } else {
            setCanShowControls(false);
            global.showContentList = false;
        }
    }, [showProgramDetails, showChannelList, showEpisodeList, showLessonList, showSettingsMenu, showSupportMenu, showParental, showShortList, showTextSizingMenu]);

    useEffect(() => {
        const subscription = AppState.addEventListener('change', (nextAppState) => {
            if (nextAppState === 'background') {
                if (!global.playerPaused && isFactorTv) {
                    onPlayPause();
                }
                startPiP();
            } else if (nextAppState === 'active') {
                if (global.playerPaused && isFactorTv) {
                    onPlayPause();
                }
                setShowPip(false);
                showControls();
                playerContext.setPip(false);
            }
        });

        return () => {
            subscription.remove();
        };
    }, []);

    const makeStreamUnique = (url) => {
        if (url.indexOf('?') > -1) {
            return url + '&t=' + moment().unix();
        } else {
            return url + '?t=' + moment().unix();
        }
    };

    //This is used to change content like zapping channel or changing episode
    useEffect(() => {
        startPlayer();
    }, [contentContext.channelData.channel, contentContext.camData.cam, contentContext.seriesData.episode, contentContext.courseData.lesson, contentContext.shortData.short]);

    const startPlayer = async () => {
        global.prepareNextEpisode = false;
        global.playerInFocus = true;
        global.showContentList = false;
        global.showProgramDetails = false;
        global.duration = 0;
        global.seekPosition = 0;
        global.preventControlsFromHiding = false;
        global.scrubberIsFocus = false;

        var stream = '' as any;
        setControlsControllerTimeout();
        if (isPayPerView) {
            setIsPayPerView(false);
        }

        switch (type) {
            case 'Channels':
                if (contentContext.channelData.channel.payperview.enable && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView) {
                    setIsPayPerView(true);
                } else if (checkParentalLockShouldApply(userContext, contentContext, contentContext.channelData.program, contentContext.channelData.channel) && !parentalApproved) {
                    setShowParental(true);
                } else {
                    if (deviceContext.isSmartTV || deviceContext.isTizen || deviceContext.isWebos || deviceContext.isWebTV || deviceContext.isPwa) {
                        stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.tizen_webos, deviceContext, userContext);
                    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                        stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.ios_tvos, deviceContext, userContext);
                    } else {
                        stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.standard_url, deviceContext, userContext);
                    }

                    var drm = await getDrm(contentContext.channelData.channel, contentContext.channelData.channel._id, 'channels', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }

                    stream = getCatchupLiveTVStream(contentContext.channelData.channel, contentContext.channelData.program, stream);
                    stream = getStreamParams(stream, contentContext.channelData.channel.name, contentContext.channelData.channel._id, type, deviceContext, userContext, appContext, contentContext.channelData.channel?.streams?.params);

                    var vastUrl = getCatoolaVast(
                        appContext,
                        userContext,
                        deviceContext,
                        contentContext.channelData.channel._id,
                        contentContext.channelData.channel.name,
                        'genre',
                        contentContext.channelData.program?.s,
                        contentContext.channelData.program?.e,
                        0
                    );

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: true,
                        type: 'Channels',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.channelData.channel._id, userContext));
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, type, contentContext.channelData.channel, appContext.application, false, 0, 0, contentContext.channelData.program, [], [], [], contentContext);
                    }
                    setRecommendations(contentContext.channelData.channel.tags, appContext.application, userContext);
                    playerContext.setStream({ url: makeStreamUnique(stream), vast: vastUrl, type: getStreamTypeCasting(stream) });

                    setIsLoaded(true);
                }
                break;
            case 'Recordings':
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (checkParentalLockShouldApply(userContext, contentContext, contentContext.recordingData.program, contentContext.recordingData.channel) && !parentalApproved) {
                    setShowParental(true);
                } else {
                    // if (deviceContext.isSmartTV || deviceContext.isAndroidTV || deviceContext.isFireTV || deviceContext.isAndroid) {
                    //     stream = await getToken(contentContext.recordingData.channel, playerContext.tk, contentContext.recordingData.channel.streams.channels?.urls.tizen_webos, deviceContext, userContext);
                    // } else if (deviceContext.isIos || deviceContext.isAppleTV || deviceContext.isWebTV) {
                    stream = await getToken(contentContext.recordingData.channel, playerContext.tk, contentContext.recordingData.channel.streams.channels?.urls.ios_tvos, deviceContext, userContext);
                    // } else {
                    //     stream = await getToken(contentContext.recordingData.channel, playerContext.tk, contentContext.recordingData.channel.streams.channels?.urls.standard_url, deviceContext, userContext);
                    // }

                    var drm = await getDrm(contentContext.recordingData.channel, contentContext.recordingData.channel._id, 'channels', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }

                    var vastUrl = getCatoolaVast(
                        appContext,
                        userContext,
                        deviceContext,
                        contentContext.recordingData.channel._id,
                        contentContext.recordingData.channel.name,
                        'genre',
                        contentContext.recordingData.program.s,
                        contentContext.recordingData.program.e,
                        0
                    );

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Recordings',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    playerContext.setStream({ url: makeStreamUnique(stream), vast: vastUrl, type: getStreamTypeCasting(stream) });
                    setIsLoaded(true);
                }
                break;
            case 'CatchupTV':
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (checkParentalLockShouldApply(userContext, contentContext, contentContext.channelData.program, contentContext.channelData.channel) && !parentalApproved) {
                    setShowParental(true);
                } else {
                    // if (deviceContext.isSmartTV || deviceContext.isWebTV || deviceContext.isAndroidTV || deviceContext.isFireTV || deviceContext.isAndroid) {
                    //     stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.tizen_webos, deviceContext, userContext);
                    // } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                    stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.ios_tvos, deviceContext, userContext);
                    // } else {
                    //     stream = await getToken(contentContext.channelData.channel, playerContext.tk, contentContext.channelData.channel.streams.channels?.urls.standard_url, deviceContext, userContext);
                    // }

                    if (params != undefined && params.startType == 'live') {
                        stream = getCatchupLiveTVStream(contentContext.channelData.channel, contentContext.channelData.program, stream);
                    } else {
                        stream = getCatchupTVStream(contentContext.channelData.channel, contentContext.channelData.program, stream);
                    }

                    var drm = await getDrm(contentContext.channelData.channel, contentContext.channelData.channel._id, 'channels', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }

                    var vastUrl = getCatoolaVast(
                        appContext,
                        userContext,
                        deviceContext,
                        contentContext.channelData.channel._id,
                        contentContext.channelData.channel.name,
                        'genre',
                        contentContext.channelData.program?.s,
                        contentContext.channelData.program?.e,
                        0
                    );

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'CatchupTV',
                        error: '',
                    });

                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    if ((params != undefined && params.startType == 'live') || startType == 'live') {
                        setStartType('');
                        setTimeout(() => {
                            playerContext.setSeekSetPosition(1);
                        }, 3000);
                    }
                    playerContext.setStream({ url: makeStreamUnique(stream), vast: vastUrl, type: getStreamTypeCasting(stream) });
                    setIsLoaded(true);
                }
                break;
            case 'Cams':
                if (contentContext.camData.cam.payperview.enable && !payperviewStatus('Channel', contentContext.camData.cam._id, userContext).isPayPerView) {
                    setIsPayPerView(true);
                } else if (contentContext.camData.cam?.parental?.enabled && !parentalApproved) {
                    setShowParental(true);
                } else {
                    if (deviceContext.isSmartTV || deviceContext.isWebTV || deviceContext.isAndroidTV || deviceContext.isFireTV || deviceContext.isAndroid) {
                        stream = await getToken(contentContext.camData.cam, playerContext.tk, contentContext.camData.cam.streams?.cams.urls.tizen_webos, deviceContext, userContext);
                    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                        stream = await getToken(contentContext.camData.cam, playerContext.tk, contentContext.camData.cam.streams.cams?.urls.ios_tvos, deviceContext, userContext);
                    } else {
                        stream = await getToken(contentContext.camData.cam, playerContext.tk, contentContext.camData.cam.streams.cams?.urls.standard_url, deviceContext, userContext);
                    }

                    var drm = await getDrm(contentContext.camData.cam, contentContext.camData.cam._id, 'cams', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }

                    var vastUrl = getCatoolaVast(appContext, userContext, deviceContext, contentContext.camData.cam._id, contentContext.camData.cam.name, 'genre', contentContext.camData.program?.s, contentContext.camData.program?.e, 0);

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: true,
                        type: 'Cams',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.camData.cam._id, userContext));
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, type, contentContext.camData.cam, appContext.application, false, 0, 0, [], [], [], [], contentContext);
                    }
                    setRecommendations(contentContext.camData.cam.tags, appContext.application, userContext);

                    playerContext.setStream({ url: makeStreamUnique(stream), vast: vastUrl, type: getStreamTypeCasting(stream) });
                    setIsLoaded(true);
                }
                break;
            case 'Movies':
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (contentContext.movieData.movie?.parental?.enabled && !parentalApproved) {
                    setShowParental(true);
                } else {
                    stream = params != undefined && params.startType == 'trailer' ? contentContext.movieData.movie?.streams?.movies?.url : contentContext.movieData.movie?.streams?.movies?.url ?? '';

                    var drm = await getDrm(contentContext.movieData.movie, contentContext.movieData.movie._id, 'movies', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, contentContext.movieData.movie.name, contentContext.movieData.movie._id, type, deviceContext, userContext, appContext, contentContext.movieData.movie?.streams?.params);
                    var vastUrl = getCatoolaVast(appContext, userContext, deviceContext, contentContext.movieData.movie._id, contentContext.movieData.movie.name, 'genre', 0, 0, contentContext.movieData.movie.duration ?? 3600);

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Movies',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.movieData.movie._id, userContext));
                    setRecommendations(contentContext.movieData.movie.tags, appContext.application, userContext);

                    playerContext.setStream({
                        url: makeStreamUnique(stream),
                        vast: '',
                        type: getStreamTypeCasting(stream),
                    });

                    setIsLoaded(true);
                }
                break;
            case 'Shorts':
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (contentContext.shortData.short?.parental?.enabled && !parentalApproved) {
                    setShowParental(true);
                } else {
                    stream = contentContext.shortData.short?.streams?.shorts?.url;
                    var vastUrl = getCatoolaVast(appContext, userContext, deviceContext, contentContext.shortData.short._id, contentContext.shortData.short.name, 'genre', 0, 0, contentContext.shortData.short.duration ?? 600);

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Shorts',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.shortData.short._id, userContext));

                    playerContext.setStream({
                        url: makeStreamUnique(stream),
                        vast: vastUrl,
                        type: getStreamTypeCasting(stream),
                    });
                    setIsLoaded(true);
                }
                break;

            case 'Series':
                if (prepareNextEpisode) {
                    global.prepareNextEpisode = false;
                    setPrepareNextEpisode(false);
                }
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (contentContext.seriesData.series?.parental?.enabled && !parentalApproved) {
                    setShowParental(true);
                } else {
                    stream = params != undefined && params.startType == 'trailer' ? contentContext.seriesData.series?.trailer : contentContext.seriesData.episode?.streams?.series?.url ?? '';
                    var drm = await getDrm(contentContext.seriesData.series, contentContext.seriesData.series._id, 'series', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    var vastUrl = getCatoolaVast(appContext, userContext, deviceContext, contentContext.seriesData.episode._id, contentContext.seriesData.episode.name, 'genre', 0, 0, contentContext.seriesData.episode.duration ?? 1800);
                    stream = getStreamParams(stream, contentContext.seriesData.episode.name, contentContext.seriesData.episode._id, type, deviceContext, userContext, appContext, contentContext.seriesData?.episode?.streams?.params);

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Series',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.seriesData.series._id, userContext));
                    setRecommendations(contentContext.seriesData.series.tags, appContext.application, userContext);

                    playerContext.setStream({
                        url: makeStreamUnique(stream),
                        vast: vastUrl,
                        type: getStreamTypeCasting(stream),
                    });
                    setIsLoaded(true);
                }
                break;
            case 'Courses':
                playerContext.setPosition(0);
                global.seekPosition = 0;

                if (contentContext.courseData.course?.parental?.enabled && !parentalApproved) {
                    setShowParental(true);
                } else {
                    stream = contentContext.courseData.lesson?.streams?.courses?.url;

                    var drm = await getDrm(contentContext.courseData.course, contentContext.courseData.course._id, 'courses', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, contentContext.seriesData.episode.name, contentContext.seriesData.episode._id, type, deviceContext, userContext, appContext, contentContext.courseData.lesson?.streams?.params);
                    var vastUrl = getCatoolaVast(appContext, userContext, deviceContext, contentContext.courseData.course._id, contentContext.courseData.course.name, 'genre', 0, 0, contentContext.courseData.lesson.duration ?? 600);

                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Courses',
                        error: '',
                    });
                    if (playerContext.paused) {
                        playerContext.setPaused(false);
                        setPaused(false);
                    }

                    playerContext.setSeekSetPosition(0);

                    setIsFavorite(favoriteStatus(type, contentContext.courseData.course._id, userContext));
                    setRecommendations(contentContext.courseData.course.tags, appContext.application, userContext);
                    playerContext.setStream({
                        url: makeStreamUnique(stream),
                        vast: vastUrl,
                        type: getStreamTypeCasting(stream),
                    });
                    setIsLoaded(true);
                }
                break;
        }
    };

    useEffect(() => {
        if (playerContext.playing == true) {
            if (type == 'Movies') {
                if (params?.startType != 'trailer') {
                    if (params != undefined && params.startType != 'start') {
                        var watching = watchingStatus('Movies', contentContext.movieData.movie._id, userContext);
                        if (watching != undefined && watching.position != undefined && watching.position > 0) {
                            setTimeout(() => {
                                onScrubberDrag(watching.position);
                            }, 1000);
                        }
                    }
                }
            }
            if (type == 'Shorts') {
                if (params?.startType != 'start') {
                    var watching = watchingStatus('Shorts', contentContext.shortData.short._id, userContext);
                    if (watching != undefined && watching.position != undefined && watching.position > 0) {
                        setTimeout(() => {
                            onScrubberDrag(watching.position);
                        }, 1000);
                    }
                }
            }
            if (type == 'Series') {
                if (params?.startType != 'trailer' && !global.prepareNextEpisode) {
                    var watching = watchingStatus('Series', contentContext.seriesData.series._id, userContext);
                    if (watching != undefined) {
                        var episode = watching?.continue?.episodes?.find((e) => e._id == contentContext.seriesData.episode._id);
                        //not seeking if serie is completely watched, not possible to watch it from beginning otherwise
                        if (episode != undefined && episode.position != undefined && episode.position > 0 && episode.duration / episode.position < 0.95) {
                            setTimeout(() => {
                                onScrubberDrag(episode.position);
                            }, 1000);
                        }
                    }
                }
            }
            if (type == 'Courses') {
                if (params?.startType != 'start') {
                    var watching = watchingStatus('Courses', contentContext.courseData.course._id, userContext);
                    if (watching != undefined) {
                        var lesson = watching.continue.lessons.find((e) => e._id == contentContext.courseData.lesson._id);
                        if (lesson != undefined && lesson.position != undefined && lesson.position > 0) {
                            setTimeout(() => {
                                onScrubberDrag(lesson.position);
                            }, 1000);
                        }
                    }
                }
            }
        }
    }, [playerContext.playing]);

    const onPressPlay = (channel, program, programs) => {
        setShowChannelList(false);
        global.showContentList = false;
        setControlsControllerTimeout();
        if (type == 'Channels') {
            contentContext.setChannelData((prev) => ({ ...prev, channel: channel, program: program, programList: programs }));
        } else {
            //we have to restart player to channel type
            global.playerOrigin = 'CatchupTV';
            global.showControls = false;
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: program,
                programList: programs,
            });
            navigation && navigation.navigate('Channel_Player');
        }
    };
    const onPressPlayCatchupTV = (channel, program, programs, startType) => {
        setShowChannelList(false);
        global.showContentList = false;
        if (type == 'CatchupTV') {
            setControlsControllerTimeout();
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: program,
                programList: programs,
            });
            setStartType(startType);
        } else {
            //we have to restart type to catchuptv type
            global.playerOrigin = 'Channels';
            global.showControls = false;
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: program,
                programList: programs,
            });
            settingsContext.setShowMainMenu(false);
            navigation &&
                navigation.navigate('CatchupTV_Player', {
                    startType: startType,
                });
        }
    };

    const onPressPurchase = () => {};
    const onPressPlayRecording = (channel, program, programs) => {
        setShowChannelList(false);
        global.showContentList = false;
        if (type == 'CatchupTV') {
            setControlsControllerTimeout();
            contentContext.setChannelData({
                ...contentContext.recordingData,
                channel: channel,
                program: program,
            });
            setStartType(startType);
        } else {
            //we have to restart type to catchuptv type
            global.playerOrigin = 'Recording';
            global.showControls = false;
            contentContext.setChannelData({
                ...contentContext.recordingData,
                channel: channel,
                program: program,
            });
            settingsContext.setShowMainMenu(false);
            navigation &&
                navigation.navigate('Recording_Player', {
                    startType: startType,
                });
        }
    };

    const playChannelIndex = (index, channel, dontShowPlayer?) => {
        if (contentContext.epgTv[0] != undefined) {
            var epg = contentContext.epgTv?.find((e) => e.i == channel._id)?.epg;
            var currentIndex = 0;
            if (epg != undefined) {
                var currentTime = moment().unix();
                currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
            }
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: epg[currentIndex],
                channelIndex: index,
                programIndex: currentIndex,
                programList: epg,
                channelListList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
                channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
            });
        } else {
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: [],
                channelIndex: index,
                programIndex: currentIndex,
                programList: [],
                channelListList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
                channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
            });
        }
    };
    const playCamsIndex = (index, cam) => {
        var epg = contentContext.epgCam;
        var currentIndex = 0;
        if (epg != undefined) {
            var currentTime = moment().unix();
            currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        }
        contentContext.setCamData({
            ...contentContext.camData,
            cam: cam,
            program: {
                i: 0,
                n: '',
                d: '',
                s: moment().startOf('hour').unix(),
                e: moment().startOf('hour').add('hour', 1).unix(),
                m: '',
                b: false,
                p: '',
                t: '',
            },
            camIndex: index,
            programIndex: currentIndex,
            programList: epg,
            camList: contentContext.cams.categories[contentContext.camData.camCategoryIndex].cams,
        });
    };

    const onPrevious = (screenName, liveTvCatchupTv) => {
        setShowParental(false);
        global.preventControlsFromHiding = true;
        if (liveTvCatchupTv) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'CatchupTV_Player' }],
                })
            );
        }
        if (screenName == 'Channel_Player') {
            var newIndex = contentContext.channelData.channelIndex - 1;
            if (newIndex >= 0) {
                var channel = contentContext.channelData.channelList[newIndex];
                playChannelIndex(newIndex, channel);
            } else {
                var channel = contentContext.channelData.channelList[contentContext.channelData.channelList.length - 1];
                playChannelIndex(contentContext.channelData.channelList.length - 1, channel);
            }
        }
        if (screenName == 'CatchupTV_Player') {
            var newIndex = contentContext.channelData.programIndex - 1;
            if (newIndex >= 0) {
                contentContext.setChannelData({
                    ...contentContext.channelData,
                    programIndex: newIndex,
                    program: contentContext.channelData.programList[newIndex],
                });
            }
        }
        if (screenName == 'Cam_Player') {
            var newIndex = contentContext.camData.camIndex - 1;
            if (newIndex >= 0) {
                var cam = contentContext.camData.camList[newIndex];
                playCamsIndex(newIndex, cam);
            }
        }
        if (screenName == 'Series_Player') {
            var newIndex = contentContext.seriesData.episodeIndex - 1;
            if (newIndex >= 0) {
                var episode = contentContext.seriesData.episodeList[newIndex];
                contentContext.setSeriesData({
                    ...contentContext.seriesData,
                    episode: episode,
                    episodeIndex: newIndex,
                });
            } else if (contentContext.seriesData.seasonCategoryIndex > 0) {
                var newseasonCategoryIndex = contentContext.seriesData.seasonCategoryIndex - 1;
                if (newseasonCategoryIndex >= 0) {
                    var season = contentContext.seriesData.series.seasons[newseasonCategoryIndex];
                    contentContext.setSeriesData({
                        ...contentContext.seriesData,
                        season: season,
                        seasonCategoryIndex: newseasonCategoryIndex,
                        episodeList: season.episodes,
                        episodeIndex: 0,
                        episode: season.episodes[0],
                    });
                }
            }
        }
        if (screenName == 'Course_Player') {
            var newIndex = contentContext.courseData.lessonIndex - 1;
            if (newIndex >= 0) {
                var lesson = contentContext.courseData.lessonList[newIndex];
                contentContext.setCourseData({
                    ...contentContext.courseData,
                    lessonIndex: newIndex,
                    lesson: lesson,
                });
            }
        }
        if (screenName == 'Short_Player') {
            var newIndex = contentContext.shortData.shortIndex - 1;
            if (newIndex >= 0) {
                var short = contentContext.shortData.shortList[newIndex];
                contentContext.setShortData({
                    ...contentContext.shortData,
                    shortIndex: newIndex,
                    short: short,
                });
            }
        }
    };

    const onNext = (screenName) => {
        setShowParental(false);
        global.preventControlsFromHiding = true;

        if (screenName == 'Channel_Player') {
            var newIndex = contentContext.channelData.channelIndex + 1;
            if (newIndex <= contentContext.channelData.channelList.length - 1) {
                var channel = contentContext.channelData.channelList[newIndex];
                playChannelIndex(newIndex, channel);
            } else {
                var channel = contentContext.channelData.channelList[0];
                playChannelIndex(0, channel);
            }
        }
        if (screenName == 'CatchupTV_Player') {
            var newIndex = contentContext.channelData.programIndex + 1;
            if (newIndex <= contentContext.channelData.programList.length - 1) {
                contentContext.setChannelData({
                    ...contentContext.channelData,
                    programIndex: newIndex,
                    program: contentContext.channelData.programList[newIndex],
                });
            }
        }
        if (screenName == 'Cam_Player') {
            var newIndex = contentContext.camData.camIndex + 1;
            if (newIndex <= contentContext.camData.camList.length - 1) {
                var cam = contentContext.camData.camList[newIndex];
                playCamsIndex(newIndex, cam);
            } else {
                var cam = contentContext.camData.camList[0];
                playCamsIndex(0, cam);
            }
        }
        if (screenName == 'Series_Player') {
            var newIndex = contentContext.seriesData.episodeIndex + 1;
            if (newIndex <= contentContext.seriesData.episodeList.length - 1) {
                var episode = contentContext.seriesData.episodeList[newIndex];
                contentContext.setSeriesData({
                    ...contentContext.seriesData,
                    episode: episode,
                    episodeIndex: newIndex,
                });
            } else if (contentContext.seriesData.seasonCategoryIndex <= contentContext.seriesData.series.seasons.length - 1) {
                var newseasonCategoryIndex = contentContext.seriesData.seasonCategoryIndex + 1;
                if (newseasonCategoryIndex < contentContext.seriesData.series.seasons.length - 1) {
                    var season = contentContext.seriesData.series.seasons[newseasonCategoryIndex];
                    contentContext.setSeriesData({
                        ...contentContext.seriesData,
                        season: season,
                        seasonCategoryIndex: newseasonCategoryIndex,
                        episodeList: season.episodes,
                        episodeIndex: 0,
                        episode: season.episodes[0],
                    });
                }
            }
        }
        if (screenName == 'Course_Player') {
            var newIndex = contentContext.courseData.lessonIndex + 1;
            if (newIndex <= contentContext.courseData.lessonList.length - 1) {
                var lesson = contentContext.courseData.lessonList[newIndex];
                contentContext.setCourseData({
                    ...contentContext.courseData,
                    lessonIndex: newIndex,
                    lesson: lesson,
                });
            }
        }
        if (screenName == 'Short_Player') {
            var newIndex = contentContext.shortData.shortIndex + 1;
            if (newIndex <= contentContext.shortData.shortList.length - 1) {
                var short = contentContext.shortData.shortList[newIndex];
                contentContext.setShortData({
                    ...contentContext.shortData,
                    shortIndex: newIndex,
                    short: short,
                });
            }
        }
    };

    const onPreviousCatchupTV = () => {
        var newIndex = contentContext.channelData.programIndex - 1;
        if (newIndex >= 0) {
            playerContext.setFromLive(true);
            contentContext.setChannelData({
                ...contentContext.channelData,
                programIndex: newIndex,
                program: contentContext.channelData.programList[newIndex],
            });

            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'CatchupTV_Player' }],
                })
            );
        }
    };

    const getPlayer = (type, styling) => {
        return (
            <Player_Standard
                onTest={startPiP}
                onClose={onClose}
                paused={paused}
                startPiP={startPiP}
                isRestart={isRestart}
                onBack={onBackViaLocal}
                showEpisodes={showEpisodes}
                showLessons={showLessons}
                showShorts={showShorts}
                isFavorite={isFavorite}
                focusContext={focusContext}
                onScrubberDrag={onScrubberDrag}
                onRestart={onRestart}
                onForward={onForward}
                onRewind={onRewind}
                styling={styling}
                onPlayPause={onPlayPause}
                onPrevious={onPrevious}
                onNext={onNext}
                showChannels={showChannels}
                showSettings={showSettings}
                showSupport={showSupport}
                navigation={navigation}
                openAirplay={openAirplay}
                openChromecast={openChromecast}
                manageFavorite={manageFavoriteLocal}
                onPreviousCatchupTV={onPreviousCatchupTV}
                showPayPerView={setShowPayPerView}
            ></Player_Standard>
        );
    };
    const getElement = (type: string) => {
        switch (type) {
            case 'Channels':
                return getPlayer(appContext.application.players?.channels.type, appContext.application.players?.channels);
            case 'Cams':
                return getPlayer(appContext.application.players?.cams.type, appContext.application.players?.cams);
            case 'Movies':
                return getPlayer(appContext.application.players?.movies.type, appContext.application.players?.movies);
            case 'Shorts':
                return getPlayer(appContext.application.players?.shorts.type, appContext.application.players?.shorts);
            case 'Recordings':
                return getPlayer(appContext.application.players?.recordings.type, appContext.application.players?.recordings);
            case 'Courses':
                return getPlayer(appContext.application.players?.courses.type, appContext.application.players?.courses);
            case 'Series':
                return getPlayer(appContext.application.players?.series.type, appContext.application.players?.series);
            case 'CatchupTV':
                return getPlayer(appContext.application.players?.catchuptv.type, appContext.application.players?.catchuptv);
        }
    };
    const getBackdrop = () => {
        if (screenName == 'Movie_Player') {
            return (
                <Image_
                    resizeMethod={'scale'}
                    resizeMode={'cover'}
                    source={{
                        uri: contentContext.movieData.movie.images?.background.indexOf('https://') > -1 ? contentContext.movieData.movie.images?.background : appContext.cloudUrl + contentContext.movieData.movie.images?.background,
                    }}
                    style={{ width: '100%', height: '100%' }}
                ></Image_>
            );
        } else if (screenName == 'Series_Player') {
            return <Image_ resizeMethod={'scale'} resizeMode={'cover'} source={{ uri: appContext.cloudUrl + contentContext.seriesData.season.images?.background }} style={{ width: '100%', height: '100%' }}></Image_>;
        } else if (screenName == 'Course_Player') {
            return <Image_ resizeMethod={'scale'} resizeMode={'cover'} source={{ uri: appContext.cloudUrl + contentContext.courseData.course.images?.background }} style={{ width: '100%', height: '100%' }}></Image_>;
        } else if (screenName == 'Short_Player') {
            return <Image_ resizeMethod={'scale'} resizeMode={'cover'} source={{ uri: contentContext.shortData.short.images?.widescreen }} style={{ width: '100%', height: '100%' }}></Image_>;
        } else {
            return null;
        }
    };

    React.useEffect(() => {
        settingsContext.setShowMainMenu(false);
        setIsLoaded(false);
        if (deviceContext.isPhone || deviceContext.isTablet) {
            SystemNavigationBar.fullScreen(true);
        }
        return () => {
            if (deviceContext.isPhone || deviceContext.isTablet) {
                SystemNavigationBar.fullScreen(false);
            }
        };
    }, [navigation]);

    React.useEffect(() => {
        const eventListener = Event.subscribe('onPlayerTimeUpdate', (value) => {
            if (screenName == 'Series_Player' && prepareNextEpisode == false) {
                if (Math.round(value) == Math.round(playerContext.duration - 20)) {
                    setCountDown(20);
                    global.prepareNextEpisode = true;
                    setPrepareNextEpisode(true);
                }
            }
        });
        return () => {
            eventListener();
        };
    }, []);

    React.useEffect(() => {
        if (playerContext.player.ended == true) {
            if (screenName == 'Series_Player' && params?.startType != 'trailer') {
                manageWatching(
                    userContext,

                    'Series',
                    contentContext.seriesData,
                    appContext.application,
                    false,
                    playerContext.duration,
                    playerContext.duration,
                    [],
                    contentContext.seriesData.episode,
                    [],
                    [],
                    contentContext
                );
                onNext(screenName);
            } else if (screenName == 'Shorts_Player') {
                manageWatching(userContext, 'Shorts', contentContext.shortData.short, appContext.application, false, 0, 0, [], [], [], [], contentContext);
                onNext(screenName);
            } else if (screenName == 'Course_Player') {
                manageWatching(userContext, 'Movies', contentContext.courseData.course, appContext.application, false, 0, 0, [], [], [], [], contentContext);
                onNext(screenName);
            } else {
                if (screenName == 'Movie_Player' && params?.startType != 'trailer' && !playerContext.paused) {
                    manageWatching(userContext, 'Movies', contentContext.movieData.movie, appContext.application, false, 0, 0, [], [], [], [], contentContext);
                }
            }
        }
    }, [playerContext.player.ended]);

    const onRewind = (value) => {
        if (value == undefined) {
            value = 60;
        }
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_REW);
        var seekGlobal = global.seekPosition;
        if ((type == 'Channels' && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || type == 'ChatchupTV' || type == 'Cams') {
            if (seekGlobal > 86400) {
                seekGlobal = seekGlobal - contentContext.channelData.program?.s;
            }
        }
        var seek = seekGlobal - value;
        if (seek > 0) {
            playerContext.setSeekSetPosition(seek);
        } else {
            if (type != 'Channels' && type != 'Cams') {
                playerContext.setSeekSetPosition(0);
            }
        }
    };
    const onForward = (value) => {
        if (value == undefined) {
            value = 60;
        }
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_FWD);
        var seekGlobal = global.seekPosition;
        if ((type == 'Channels' && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || type == 'ChatchupTV' || type == 'Cams') {
            if (seekGlobal > 86400) {
                seekGlobal = seekGlobal - contentContext.channelData.program?.s;
            }
        }
        var seek = seekGlobal + value;
        if (seek < playerContext.duration || type != 'Channels' || type != 'Cams') {
            playerContext.setSeekSetPosition(seek);
        }
    };

    const onRestart = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_RESTART);
        playerContext.setSeekSetPosition(0);
    };
    const onScrubberDrag = (value: any) => {
        global.preventControlsFromHiding = false;
        setControlsControllerTimeout();
        if (
            type == 'Movies' ||
            type == 'Series' ||
            type == 'CatchupTV' ||
            type == 'Recordings' ||
            type == 'Courses' ||
            type == 'Cams' ||
            type == 'Shorts' ||
            (type == 'Channels' && contentContext.channelData.channel.interactivetv.catchuptv_enabled)
        ) {
            sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_SCRUBBER);
            playerContext.setSeekSetPosition(value);
            playerContext.setSeekControls(!playerContext.seekControls);
        }
    };

    const openChromecast = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_CHROMECAST);
        if (playerContext.casting.type != 'Chromecast') {
            playerContext.setCasting({
                type: 'Chromecast',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const openAirplay = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_AIRPLAY);
        if (playerContext.casting.type != 'Airplay') {
            playerContext.setCasting({
                type: 'Airplay',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };

    const playVideo = () => {
        setPaused(false);
        global.playerPaused = false;
        playerContext.setPaused(false);
    };

    const pauseVideo = () => {
        clearTimeout(timeout.current);
        timeout.current = null;
        global.preventControlsFromHiding = true;
        global.showControls = true;
        global.playerPaused = true;
        setPaused(true);
        playerContext.setPaused(true);
    };

    const onPlayPause = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_PAUSED);
        if (global.playerPaused) {
            showControls();
            playVideo();
        } else {
            pauseVideo();
        }
    };

    const showControls = () => {
        if (!global.showControls && canShowControls) {
            global.showControls = true;
            global.scrubberIsFocus = false;
            setCode('showcontrols');
            if (!isPlatformAndroid && !isPlatformIos) {
                playerContext.setShowControls(true);
            }
        }
    };
    const hideControls = () => {
        if (global.showControls && !global.preventControlsFromHiding) {
            global.showControls = false;
            global.preventControlsFromHiding = false;
            global.scrubberIsFocus = false;
            setCode('hidecontrols');
            if (!isPlatformAndroid && !isPlatformIos) {
                playerContext.setShowControls(false);
            }
        }
    };
    const hideLists = () => {
        setShowChannelList(false);
        setShowEpisodeList(false);
        setShowLessonList(false);
        setShowShortList(false);
        setShowSettingMenu(false);
        setshowSupportMenu(false);
        setShowProgramDetails(false);
        global.showContentList = false;
        global.showProgramDetails = false;
    };

    const showChannels = () => {
        if (!showChannelList && screenName == 'Channel_Player') {
            setShowChannelList(true);
            global.showContentList = true;
        }
    };
    const showEpisodes = () => {
        if (!showEpisodeList) {
            setShowEpisodeList(true);
            pauseVideo();
            global.showContentList = true;
        }
    };
    const showLessons = (styling) => {
        if (!showLessonList) {
            setShowLessonList(true);
            global.showContentList = true;
        }
    };
    const showShorts = (styling) => {
        if (!showShortList) {
            setShowShortList(true);
            global.showContentList = true;
        }
    };
    const showSettings = (styling) => {
        if (!showSettingsMenu) {
            setShowSettingMenu(true);
            setStyling(styling);
            global.showContentList = true;
        }
    };
    const showSupport = (styling) => {
        if (!showSupportMenu) {
            setshowSupportMenu(true);
            setStyling(styling);
            global.showContentList = true;
        }
    };

    const manageFavoriteLocal = () => {
        switch (type) {
            case 'Channels':
                manageFavorite(contentContext, 'Channels', contentContext.channelData.channel, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'CatchupTV':
                manageFavorite(contentContext, 'Channels', contentContext.channelData.channel, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Cams':
                manageFavorite(contentContext, 'Cams', contentContext.camData.cam, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Radio':
                manageFavorite(contentContext, 'Radio', contentContext.radioData.radio, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Movies':
                manageFavorite(contentContext, 'Movies', contentContext.movieData.movie, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Shorts':
                manageFavorite(contentContext, 'Shorts', contentContext.movieData.movie, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Series':
                manageFavorite(contentContext, 'Series', contentContext.seriesData.series, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Courses':
                manageFavorite(contentContext, 'Courses', contentContext.courseData.course, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            default:
                break;
        }
    };

    const changeScreenSetting = (name: any) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_SELECT_SCREENSZIE);
        playerContext.setAspectRatio(name);
        manageProfiles(userContext, userContext.profile, 'aspectRatio', name, appContext.application);
    };
    const changeAudioSetting = (index: any, language: any) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_SELECT_AUDIO);
        playerContext.setAudioTrackIndex(index);
        manageProfiles(userContext, userContext.profile, 'audio', language, appContext.application);
    };
    const changeTextSetting = (index: any, language: any) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_SELECT_SUBTITLE);
        playerContext.setTextTrackIndex(index);
        manageProfiles(userContext, userContext.profile, 'subtitles', language, appContext.application);
    };

    //test via
    const openChannel = (channel, index, dontshowcontrols) => {
        setShowChannelList(false);
        global.showContentList = false;
        setShowZapper(false);
        setShowOsd(false);
        playChannelIndex(index, channel, dontshowcontrols);
    };

    const cancelNext = () => {
        navigation.goBack(null);
    };

    React.useEffect(() => {
        if (showParental == true) {
            setShowParental(false);
            startPlayer();
        }
    }, [parentalApproved]);

    const setParentalApprovedLocal = () => {
        setShowParental(false);
        setParentalApproved(true);
    };

    const startPiP = () => {
        if (!showPip) {
            setShowPip(true);
            sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_PIP);
            playerContext.setPip(true);
        }
    };

    const getCorrectPageType = () => {
        switch (type) {
            case 'Channels':
                return pageType.CHANNEL_PLAYER;
                break;
            case 'Cams':
                return pageType.CAMS_PLAYER;
                break;
            case 'Radio':
                return pageType.RADIO_PLAYER;
                break;
            case 'Recordings':
                return pageType.RECORDING_PLAYER;
                break;
            case 'Movies':
                return pageType.MOVIE_PLAYER;
                break;
            case 'Shorts':
                return pageType.SHORT_PLAYER;
                break;
            case 'Series':
                return pageType.SERIES_PLAYER;
                break;
            case 'Courses':
                return pageType.COURSE_DETAILS;
                break;
            case 'CatchupTV':
                return pageType.CATCHUPTV_PLAYER;
                break;
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, getCorrectPageType(), duration);
            };
        }, [])
    );

    React.useEffect(() => {
        if (screenName == 'Channel_Player') {
            clearTimeout(zaptimeout.current);
            zaptimeout.current = setTimeout(() => {
                openChannel(contentContext.channelData.channelList[zapperIndex], zapperIndex, undefined);
            }, 1000);
            return () => clearTimeout(zaptimeout.current);
        }
    }, [zapperIndex]);

    React.useEffect(() => {
        if (screenName == 'Channel_Player') {
            if (showOsd) {
                var test = contentContext.channelData.channelList.findIndex((c) => c.number == Number(osdNumber));
                if (test > -1) {
                    setOsdIndex(test);
                }
                const timer = setTimeout(() => {
                    var test = contentContext.channelData.channelList.findIndex((c) => c.number == Number(osdNumber));
                    if (test > -1) {
                        setOsdColor('green');
                        setTimeout(() => {
                            setShowOsd(false);
                            showControls();
                            openChannel(contentContext.channelData.channelList[test], test, undefined);
                            setOsdNumber(0);
                            setOsdColor('#999');
                        }, 500);
                    } else {
                        setOsdColor('crimson');
                        setTimeout(() => {
                            setShowOsd(false);
                            setOsdNumber(0);
                            setOsdColor('#999');
                        }, 1000);
                    }
                }, 2000);
                return () => clearTimeout(timer);
            }
        }
    }, [osdNumber, showOsd]);

    React.useEffect(() => {
        if (deviceContext.isWebTV || deviceContext.isPhone || deviceContext.isTablet || appContext.application.settings.sleep_mode_setting == 0) {
            return;
        }
        if (showSleeptimerModal == true) {
            setShowSleeptimerModal(false);
        }
        var sleeper = appContext.application.settings.sleep_mode_setting * 60 * 1000;
        if (sleeper != undefined && sleeper == 0) {
            return;
        }
        const timer = setTimeout(() => {
            setShowSleeptimerModal(true);
        }, sleeper);

        return () => clearTimeout(timer);
    }, [timerInternal]);

    const onClose = () => {
        global.preventControlsFromHiding = false;
        global.scrubberIsFocus = false;
        hideControls();
    };
    const onBackViaLocal = () => {
        onBack(true);
    };
    const onBack = (skipHideControls?) => {
        // if (showEpisodeList == true) {
        //     setShowEpisodeList(false);
        // } else if (showChannelList == true) {
        //     setShowChannelList(false);
        // } else

        if (global.showControls == true && skipHideControls == undefined) {
            global.preventControlsFromHiding = false;
            hideControls();
        } else if (global.preventControlsFromHiding == true && skipHideControls == undefined) {
            global.preventControlsFromHiding = false;
            hideControls();
        } else {
            setTimeout(() => {
                if (screenName == 'Channel_Player') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Channels', contentContext.channelData.channel, appContext.application, false, playerContext.duration, global.seekPosition, [], [], [], [], contentContext);
                    }
                }
                if (screenName == 'Cam_Player') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Cams', contentContext.camData.cam, appContext.application, false, playerContext.duration, global.seekPosition, [], [], [], [], contentContext);
                    }
                }
                // if (screenName == 'CatchupTV_Player') {
                //     if (appContext.application.settings.allow_watchlist) {
                //         manageWatching(userContext, userContext.profile.watchings, 'CatchupTV', contentContext.catchuptvData.program, appContext.application, true, playerContext.duration, global.seekPosition, [], [], [], [], contentContext);
                //     }
                // }
                if (screenName == 'Movie_Player' && params?.startType != 'trailer') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Movies', contentContext.movieData.movie, appContext.application, false, playerContext.duration, global.seekPosition, [], [], [], [], contentContext);
                    }
                }
                if (screenName == 'Shorts_Player') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Shorts', contentContext.shortData.short, appContext.application, false, playerContext.duration, global.seekPosition, [], [], [], [], contentContext);
                    }
                }
                if (screenName == 'Series_Player' && params?.startType != 'trailer') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Series', contentContext.seriesData, appContext.application, false, playerContext.duration, global.seekPosition, [], contentContext.seriesData.episode, [], [], contentContext);
                    }
                }
                if (screenName == 'Course_Player') {
                    if (appContext.application.settings.allow_watchlist) {
                        manageWatching(userContext, 'Courses', contentContext.courseData, appContext.application, false, playerContext.duration, global.seekPosition, [], [], contentContext.courseData.lesson, [], contentContext);
                    }
                }
                if (navigation.canGoBack() == false) {
                    settingsContext.setShowMainMenu(true);
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        })
                    );
                    contentContext.setActiveMenu('Home');
                } else {
                    if (global.playerOrigin != 'Channels' && global.playerOrigin != 'CatchupTV') {
                        settingsContext.setShowMainMenu(true);
                    } else {
                        global.playerOrigin = '';
                    }
                    navigation.goBack();
                }
            }, 100);
        }
    };

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;

            if (showSleeptimerModal) {
                setShowSleeptimerModal(false);
                setTimerInteral(!timerInternal);
                return;
            }
            setTimerInteral(!timerInternal);

            //scrubber focussed then do left right to scrubber
            if (global.scrubberIsFocus) {
                if (keyCode == 37) {
                    onRewind(60);
                }
                if (keyCode == 39) {
                    onForward(5);
                }
                return;
            }

            if (keyCode == 428 || keyCode == 167 || keyCode == 34 || keyCode == 49) {
                //channel down
                if (type == 'Channels') {
                    onPrevious(screenName, false);
                }
            }
            if (keyCode == 427 || keyCode == 166 || keyCode == 33 || keyCode == 48) {
                //channel up
                if (type == 'Channels') {
                    onNext(screenName);
                }
            }
            if (keyCode == 10252) {
                //play
                onPlayPause();
            }
            if (keyCode == 19) {
                //pause
                onPlayPause();
            }
            if (keyCode == 417) {
                //ff
                onForward(60);
            }
            if (keyCode == 412) {
                //rew
                onRewind(60);
            }

            if (global.showContentList || global.showProgramDetails) {
                if (global.showProgramDetails) {
                    if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                        if (global.showProgramDetails) {
                            setShowProgramDetails(false);
                            global.showProgramDetails = false;
                        } else if (global.showContentList) {
                            setShowChannelList(false);
                            global.showContentList = false;
                        }
                    }
                } else {
                    if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                        hideLists();
                    }
                }
                return;
            } else {
                if (keyCode == 39) {
                    //right
                    if (!global.showControls) {
                        if (screenName == 'Channel_Player') {
                            var next = zapperIndex + 1;
                            if (next < contentContext.channelData.channelList.length) {
                                setShowZapper(true);
                                setZapperIndex(next);
                            } else {
                                setShowZapper(true);
                                setZapperIndex(0);
                            }
                        }
                    }
                }
                if (keyCode == 37) {
                    //left
                    if (!global.showControls) {
                        if (screenName == 'Channel_Player') {
                            var previous = zapperIndex - 1;
                            if (previous >= 0) {
                                setShowZapper(true);
                                setZapperIndex(previous);
                            } else {
                                setShowZapper(true);
                                setZapperIndex(contentContext.channelData.channelList.length - 1);
                            }
                        }
                    }
                }
                if (keyCode == 38 || keyCode == 40) {
                    //down / up
                    global.preventControlsFromHiding = true;
                    showControls();
                }
                if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                    //back
                    onBack();
                }
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, [zapperIndex]);

    //For TV to exit with back button
    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                onBack();
                return true;
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);

        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);

    const disableNativeHardwareBackPress = () => {
        global.preventControlsFromHiding = false;
        onBack();
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
            global.showControls = false;
            global.playerPaused = false;
        };
    }, []);

    const setControlsControllerTimeout = () => {
        showControls();
        clearTimeout(timeout.current);
        timeout.current = null;
        timeout.current = setTimeout(() => {
            hideControls();
            clearTimeout(timeout.current);
            timeout.current = null;
        }, HIDE_CONTROLS_TIMEOUT_MS);
    };

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { keyCode } = event;
            const zapperEvent = keyCode === 37 || keyCode === 39;
            if (!global.showControls && zapperEvent) {
                // skip events
                return;
            }
            setControlsControllerTimeout();
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }

        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    useTVRemoteHandler(
        ({ eventType, eventKeyAction }: any) => {
            if (showParental) {
                return;
            }
            if (showSleeptimerModal) {
                setShowSleeptimerModal(false);
                setTimerInteral(!timerInternal);
                return;
            }
            if (prepareNextEpisode) {
                return;
            }

            setTimerInteral(!timerInternal);

            //scrubber focussed then do left right to scrubber
            if (global.scrubberIsFocus == true) {
                if (eventType == 'left' && eventKeyAction == 'down') {
                    onRewind(60);
                }
                if (eventType == 'right' && eventKeyAction == 'down') {
                    onForward(60);
                }
                return;
            }
            if (screenName == 'Channel_Player' && eventKeyAction === 'down') {
                if (eventType >= 0 && eventType <= 9) {
                    hideControls();
                    setShowOsd(true);
                    var newNumber = osdNumber + '' + Number(eventType);
                    setOsdNumber(Number(newNumber));
                    return;
                }
            }
            if (screenName != 'Channel_Player' || contentContext.channelData.channel.interactivetv.catchuptv_enabled) {
                if ((eventType == 'play' || eventType == 'pause' || eventType == 'playPause') && eventKeyAction == 'down') {
                    if (screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.pausetv_enabled) {
                        onPlayPause();
                    } else if (screenName != 'Channel_Player') {
                        onPlayPause();
                    }
                }
                if ((eventType == 'rewind' || eventType == 'Rewind') && eventKeyAction == 'down') {
                    onRewind(60);
                }
                if ((eventType == 'forward' || eventType == 'fastForward') && eventKeyAction == 'down') {
                    onForward(60);
                }
            }
            if (global.showContentList || global.showProgramDetails) {
                //nothing
            } else {
                if (eventType == 'right' && eventKeyAction == 'down') {
                    if (!global.showControls && screenName == 'Channel_Player') {
                        var next = zapperIndex + 1;
                        if (next < contentContext.channelData.channelList.length) {
                            setShowZapper(true);
                            setZapperIndex(next);
                        } else {
                            setShowZapper(true);
                            setZapperIndex(0);
                        }
                    }
                }
                if ((eventType == 'next' || eventType == 'channelUp') && eventKeyAction == 'down') {
                    if (global.showControls) {
                        hideControls();
                    }
                    if (screenName == 'Channel_Player') {
                        var next = zapperIndex + 1;
                        if (next < contentContext.channelData.channelList.length) {
                            setShowZapper(true);
                            setZapperIndex(next);
                        } else {
                            setShowZapper(true);
                            setZapperIndex(0);
                        }
                    }
                }

                if (eventType == 'left' && eventKeyAction == 'down') {
                    if (!global.showControls && screenName == 'Channel_Player') {
                        var previous = zapperIndex - 1;
                        if (previous >= 0) {
                            setShowZapper(true);
                            setZapperIndex(previous);
                        } else {
                            setShowZapper(true);
                            setZapperIndex(contentContext.channelData.channelList.length - 1);
                        }
                    }
                }

                if ((eventType == 'previous' || eventType == 'channelDown') && eventKeyAction == 'down') {
                    if (global.showControls) {
                        hideControls();
                    }
                    if (screenName == 'Channel_Player') {
                        var previous = zapperIndex - 1;
                        if (previous >= 0) {
                            setShowZapper(true);
                            setZapperIndex(previous);
                        } else {
                            setShowZapper(true);
                            setZapperIndex(contentContext.channelData.channelList.length - 1);
                        }
                    }
                }
                if (eventType == 'captions' && eventKeyAction == 'down' && !global.showControls) {
                    showSettings(styling);
                }
                if (eventType == 'down' || eventType == 'up' || eventType == 'info' || eventType === 'select') {
                    global.preventControlsFromHiding = true;
                    showControls();
                }
                if (eventType == 'stop' && eventKeyAction == 'down') {
                    onBack();
                }
                if (eventKeyAction === 'down' && eventType !== 'back') {
                    const zapperEvent =
                        eventType == '0' ||
                        eventType == '1' ||
                        eventType == '2' ||
                        eventType == '3' ||
                        eventType == '4' ||
                        eventType == '5' ||
                        eventType == '6' ||
                        eventType == '7' ||
                        eventType == '8' ||
                        eventType == '9' ||
                        eventType == 'previous' ||
                        eventType == 'channelDown' ||
                        eventType == 'left' ||
                        eventType == 'next' ||
                        eventType == 'channelUp' ||
                        eventType == 'right';
                    if (!global.showControls && zapperEvent) {
                        // skip events
                        return;
                    } else {
                        const zapperEvent = eventType == '0' || eventType == '1' || eventType == '2' || eventType == '3' || eventType == '4' || eventType == '5' || eventType == '6' || eventType == '7' || eventType == '8' || eventType == '9';
                        if (zapperEvent) {
                            return;
                        } else {
                            setControlsControllerTimeout();
                        }
                    }
                }
            }
        },
        [zapperIndex, osdNumber, showSleeptimerModal]
    );

    useEffect(() => {
        if (!isFactorTv) {
            if (code === 'showcontrols') {
                clearTimeout(timeout.current);
                setControlsControllerTimeout();
            } else if (code === 'hidecontrols') {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
        }
    }, [code]);

    const ControlToggleWrapper = deviceContext.isTizen || deviceContext.isAndroidTV || deviceContext.isFireTV || deviceContext.isAppleTV || deviceContext.isWebos ? View : TouchableOpacity;

    const getZapperProgram = () => {
        var now = moment().unix();
        var epgAll = contentContext.epgTv?.find((e) => e.i == contentContext.channelData.channelList[zapperIndex]._id);
        if (epgAll != undefined) {
            var program = epgAll?.epg?.find((e) => e.s <= now && e.e > now);
            if (program != undefined) {
                return program.n;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };
    const getZapperTime = () => {
        var now = moment().unix();
        var epgAll = contentContext.epgTv?.find((e) => e.i == contentContext.channelData.channelList[zapperIndex]._id);
        if (epgAll != undefined) {
            var program = epgAll?.epg?.find((e) => e.s <= now && e.e > now);
            if (program != undefined) {
                return moment.unix(program?.s).format('dd ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format('dd ' + deviceContext.clockSetting);
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const getNextEpisodeDescription = () => {
        if (contentContext.seriesData.season.episodes.length - 1 > contentContext.seriesData.episodeIndex + 1) {
            //next from this season
            var desc = contentContext.seriesData.season.episodes[contentContext.seriesData.episodeIndex + 1].translations;
            if (desc.find((d) => d.name == userContext.selectedLanguage) != undefined) {
                return desc.find((d) => d.name == userContext.selectedLanguage)?.description;
            } else {
                return desc[0]?.description;
            }
        } else {
            //next from next season
            if (contentContext.seriesData.seasons.length - 1 > contentContext.seriesData.seasonCategoryIndex + 1) {
                var desc = contentContext.seriesData.seasons[contentContext.seriesData.seasonCategoryIndex + 1].episodes[0].translations;
                if (desc.find((d) => d.name == userContext.selectedLanguage) != undefined) {
                    return desc.find((d) => d.name == userContext.selectedLanguage)?.description;
                } else {
                    return desc[0]?.description;
                }
            } else {
                return '';
            }
        }
    };
    const getNextEpisodeTitle = () => {
        if (contentContext.seriesData.season.episodes.length - 1 > contentContext.seriesData.episodeIndex + 1) {
            //next from this season
            return contentContext.seriesData.season.episodes[contentContext.seriesData.episodeIndex + 1].name;
        } else {
            //next from next season
            if (contentContext.seriesData.seasons.length - 1 > contentContext.seriesData.seasonCategoryIndex + 1) {
                return contentContext.seriesData.seasons[contentContext.seriesData.seasonCategoryIndex + 1].episodes[0].name;
            } else {
                return '';
            }
        }
    };
    let timer;
    useEffect(() => {
        if (prepareNextEpisode) {
            timer = setInterval(() => {
                var newTimer = countDown - 1;
                if (newTimer > 0) {
                    setCountDown(newTimer);
                } else {
                    clearInterval(timer);
                    onNext(screenName);
                }
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [prepareNextEpisode, countDown]);

    React.useEffect(() => {
        if (playerContext.player.error != '') {
            const timer = setTimeout(() => {
                playerContext.setPlayer({
                    ...playerContext.player,
                    error: '',
                });
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [playerContext.player.error]);

    const [insetBottom, setInsetBottom] = useState(0);
    React.useEffect(() => {
        if (insets.bottom != 0 && insetBottom == 0) {
            setInsetBottom(insets.bottom);
        }
    }, [insets]);

    const playerWidth = Dimensions.get('window').height + (deviceContext.isAndroid ? insets.bottom + insets.top + 20 : -(insets.bottom + insets.top));
    const playerHeight = Dimensions.get('screen').width;
    const playerLeft = (playerWidth - playerHeight) * 0.5 * -1;
    const playerTop = (playerWidth - playerHeight) * 0.5;

    return (
        <View ref={wrapperRef} focusContext={focusContext}>
            {showPayPerView && (
                <PayPerView
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.payperview, appContext)}
                    stylingContent={styling}
                    setShowModal={() => setShowPayPerView(false)}
                    type={type == 'Channels' ? 'Program' : type}
                    data={contentContext.channelData.channel}
                    startContent={() => {
                        setShowPayPerView(false);
                        setIsPayPerView(false);
                        startPlayer();
                    }}
                ></PayPerView>
            )}
            {deviceContext.isPhone && !prepareNextEpisode && (
                <View
                    style={{
                        width: playerHeight,
                        height: playerWidth,
                        backgroundColor: '#000000',
                    }}
                >
                    <View
                        ref={wrapperRef}
                        style={{
                            width: playerHeight,
                            height: playerWidth,
                            zIndex: 1,
                        }}
                        focusContext={focusContext}
                    >
                        <StatusBar hidden />
                        {isPayPerView && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView && (
                            <View
                                pointerEvents="none"
                                style={{
                                    flex: 1,
                                    zIndex: 9999,
                                    borderRadius: getCorrectWidth(deviceContext, 5),
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignSelf: 'center',
                                    backgroundColor: 'transparent',
                                    position: 'absolute',
                                    height: playerHeight,
                                    width: playerWidth,
                                    left: playerLeft,
                                    top: playerTop,
                                    transform: [{ rotate: '90deg' }],
                                }}
                            >
                                <View style={{ borderRadius: 5, padding: 10, backgroundColor: '#111', alignItems: 'center', justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                    <Text
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#ffffff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'get_access_paid_content')}
                                    </Text>
                                </View>
                            </View>
                        )}
                        {playerContext.player.error != '' && playerContext.player.error != undefined && (
                            <View
                                pointerEvents="none"
                                style={{
                                    flex: 1,

                                    zIndex: 9999,
                                    borderRadius: getCorrectWidth(deviceContext, 5),
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignSelf: 'center',
                                    backgroundColor: 'transparent',
                                    position: 'absolute',
                                    height: playerHeight,
                                    width: playerWidth,
                                    left: playerLeft,
                                    top: playerTop,
                                    transform: [{ rotate: '90deg' }],
                                }}
                            >
                                <View style={{ borderRadius: 5, padding: 10, backgroundColor: '#111', alignItems: 'center', justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                    <Text
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#ffffff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {playerContext.player.error}
                                    </Text>
                                </View>
                            </View>
                        )}

                        {/* {showNext && nextContent?.length > 0 && (
                            <View
                                style={{
                                    zIndex: 5,
                                    flexDirection: 'row',
                                    backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                    position: 'absolute',
                                    height: playerHeight,
                                    width: playerWidth * 0.45,
                                    left: playerLeft,
                                    top: playerTop,
                                    transform: [{ rotate: '90deg' }],
                                }}
                            >
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 100),
                                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 177),
                                        backgroundColor: appContext.application.theme.progresses.base,
                                    }}
                                >
                                    <NextContentTransform onNext={() => onNext(screenName)}>
                                        <View style={{ flexDirection: 'row', padding: getCorrectHeight(deviceContext, 5) }}>
                                            <Image_
                                                resizeMethod={'scale'}
                                                resizeMode={'cover'}
                                                source={{ uri: appContext.cloudUrl + nextContent?.images?.widescreen }}
                                                style={{
                                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                                    width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 167),
                                                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 90),
                                                }}
                                            ></Image_>
                                        </View>
                                    </NextContentTransform>
                                </View>
                                <View
                                    style={{
                                        flex: 2,
                                        alignItems: 'flex-start',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                    }}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                color: '#fff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                            }}
                                        >
                                            {nextContent?.name}
                                        </Text>

                                        {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                                            <Text
                                                numberOfLines={15}
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: '#fff',
                                                }}
                                            >
                                                {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                                            </Text>
                                        )}
                                        {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && nextContent.translations.length > 0 && (
                                            <Text
                                                numberOfLines={15}
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: '#fff',
                                                }}
                                            >
                                                {nextContent.translations[0]?.description}
                                            </Text>
                                        )}
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            margin: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onNext(screenName)}>
                                            <View
                                                style={{
                                                    marginTop: 10,
                                                    width: 200,
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    backgroundColor: '#555',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'continue')}
                                                </Text>
                                            </View>
                                        </Pressable>
                                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => cancelNext()}>
                                            <View
                                                style={{
                                                    marginTop: 10,
                                                    width: 200,
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    backgroundColor: '#333',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        )} */}
                        <ControlToggleWrapper
                            activeOpacity={1}
                            style={{
                                position: 'absolute',
                                height: playerHeight,
                                width: playerWidth,
                                left: playerLeft,
                                top: playerTop,
                                transform: [{ rotate: '90deg' }],
                                justifyContent: 'center',
                            }}
                            onPress={() => {
                                if (global.showControls) {
                                    hideControls();
                                } else {
                                    showControls();
                                }
                            }}
                        >
                            {!isLoaded && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        flexDirection: 'row',
                                        backgroundColor: '#000000',
                                        width: deviceContext.isPhone ? playerWidth + 20 : getRealWidth(deviceContext),
                                        height: deviceContext.isPhone ? playerHeight : getRealHeight(deviceContext),
                                    }}
                                >
                                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                        <Image_
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            style={{
                                                width: getCorrectWidth(deviceContext, 150),
                                                height: getCorrectWidth(deviceContext, 50),
                                            }}
                                            source={{ uri: appContext.application.branding.logos.logo }}
                                        />
                                        <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                                    </View>
                                </View>
                            )}
                            <PlayerFadeInOut paused={playerContext.paused} showControls={code === 'showcontrols'}>
                                {global.showControls && canShowControls && (
                                    <View
                                        style={{
                                            zIndex: 999,
                                            position: 'absolute',
                                            height: playerHeight,
                                            width: playerWidth,
                                        }}
                                    >
                                        {getElement(type)}
                                    </View>
                                )}
                                {paused && (screenName == 'Short_Player' || screenName == 'Movie_Player' || screenName == 'Series_Player' || screenName == 'Course_Player') && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            height: playerHeight,
                                            width: playerWidth,
                                        }}
                                    >
                                        {getBackdrop()}
                                    </View>
                                )}

                                {!showPip && global.showControls && canShowControls && (
                                    <View style={{ position: 'absolute', zIndex: 3, top: 0, left: 0, right: 0, bottom: 0 }}>
                                        <Image_
                                            source={require('../../../assets/images/player.png')}
                                            resizeMethod={'scale'}
                                            resizeMode={'stretch'}
                                            style={{
                                                position: 'absolute',
                                                height: playerHeight,
                                                width: playerWidth,
                                            }}
                                        ></Image_>
                                    </View>
                                )}
                            </PlayerFadeInOut>

                            {showChannelList && (
                                <MiniEPG
                                    onPressPlay={onPressPlay}
                                    onPressPurchase={onPressPurchase}
                                    onPressPlayRecording={onPressPlayRecording}
                                    onPressPlayCatchupTV={onPressPlayCatchupTV}
                                    navigation={navigation}
                                    onHide={() => {
                                        setShowChannelList(false);
                                        setControlsControllerTimeout();
                                    }}
                                    playChannelIndex={(index, channel) => {
                                        setShowChannelList(false);
                                        playChannelIndex(index, channel);
                                        setControlsControllerTimeout();
                                    }}
                                    styling={styling}
                                />
                            )}

                            {showEpisodeList && (
                                <MiniSeries
                                    navigation={navigation}
                                    onHide={() => {
                                        setShowEpisodeList(false);
                                        playVideo();
                                        setControlsControllerTimeout();
                                    }}
                                    playEpisodeIndex={() => {
                                        setShowEpisodeList(false);
                                        startPlayer();
                                        setControlsControllerTimeout();
                                    }}
                                    styling={styling}
                                />
                            )}
                            {showShortList && (
                                <MiniShorts
                                    navigation={navigation}
                                    onHide={() => {
                                        setShowShortList(false);
                                        setControlsControllerTimeout();
                                    }}
                                    playShortIndex={() => {
                                        setShowShortList(false);
                                        startPlayer();
                                        setControlsControllerTimeout();
                                    }}
                                    styling={styling}
                                />
                            )}
                            {showLessonList && (
                                <MiniCourses
                                    navigation={navigation}
                                    onHide={() => {
                                        setShowLessonList(false);
                                        setControlsControllerTimeout();
                                    }}
                                    playLessonIndex={() => {
                                        setShowLessonList(false);
                                        startPlayer();
                                        setControlsControllerTimeout();
                                    }}
                                    styling={styling}
                                />
                            )}
                            {showSettingsMenu && (
                                <SettingsView
                                    focusContext={focusContext}
                                    userContext={userContext}
                                    playerContext={playerContext}
                                    changeScreenSetting={changeScreenSetting}
                                    changeAudioSettings={changeAudioSetting}
                                    changeTextSetting={changeTextSetting}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    setshowTextSizingMenu={setshowTextSizingMenu}
                                    setShowSettingMenu={setShowSettingMenu}
                                ></SettingsView>
                            )}
                            {showTextSizingMenu && (
                                <TextSizingView
                                    focusContext={focusContext}
                                    userContext={userContext}
                                    playerContext={playerContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    setshowTextSizingMenu={setshowTextSizingMenu}
                                    setShowSettingMenu={setShowSettingMenu}
                                ></TextSizingView>
                            )}
                            {playerContext.castingStatus == 'connected' && playerContext.casting.type == 'Chromecast' && (
                                <View
                                    style={{
                                        zIndex: 1000,
                                        backgroundColor: 'transparent',
                                        position: 'absolute',
                                        height: playerHeight,
                                        width: playerWidth,
                                    }}
                                >
                                    {showChromecastPlaying(deviceContext, userContext)}
                                </View>
                            )}
                            {playerContext.castingStatus == 'connected' && playerContext.casting.type == 'Airplay' && (
                                <View
                                    style={{
                                        zIndex: 1000,
                                        backgroundColor: 'transparent',
                                        position: 'absolute',
                                        height: playerHeight,
                                        width: playerWidth,
                                    }}
                                >
                                    {showAirplayPlaying(deviceContext, userContext)}
                                </View>
                            )}
                            {showParental && (
                                <View
                                    style={{
                                        zIndex: 1000,
                                        backgroundColor: 'transparent',
                                        position: 'absolute',
                                        height: playerHeight,
                                        width: playerWidth,
                                    }}
                                >
                                    <Parental
                                        focusContext={focusContext}
                                        styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                                        setParentalApproved={setParentalApprovedLocal}
                                        setShowParental={setShowParental}
                                        profile={userContext.profile}
                                        type={'access_content'}
                                    ></Parental>
                                </View>
                            )}
                        </ControlToggleWrapper>
                    </View>
                    {(!isPayPerView || payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView) && (
                        <TheoPlayer
                            style={{
                                position: 'absolute',
                                zIndex: 0,
                                height: showPip ? '100%' : playerHeight,
                                width: showPip ? '100%' : playerWidth,
                                left: showPip ? 0 : playerLeft,
                                top: showPip ? 0 : playerTop,
                                transform: showPip ? [{ rotate: '0deg' }] : [{ rotate: '90deg' }],
                            }}
                            startPiP={startPiP}
                            key={playerContext.stream.url}
                        />
                    )}
                    {!showPip && isPayPerView && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView && (
                        <View
                            style={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 0,
                                backgroundColor: '#000',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext),
                            }}
                        />
                    )}
                </View>
            )}
            {!deviceContext.isPhone && !prepareNextEpisode && (
                <View
                    style={[
                        {
                            backgroundColor: '#000000',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    ]}
                >
                    <StatusBar hidden />
                    {isPayPerView && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView && (
                        <View
                            pointerEvents="none"
                            style={{
                                flex: 1,
                                position: 'absolute',
                                zIndex: 9999,
                                borderRadius: getCorrectWidth(deviceContext, 5),

                                alignContent: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            <View style={{ padding: getCorrectHeight(deviceContext, 30), backgroundColor: '#222', alignItems: 'center', justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#ffffff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'get_access_paid_content')}
                                </Text>
                            </View>
                        </View>
                    )}
                    {playerContext.player.error != '' && playerContext.player.error != undefined && (
                        <View
                            pointerEvents="none"
                            style={{
                                flex: 1,
                                position: 'absolute',
                                zIndex: 9999,
                                borderRadius: getCorrectWidth(deviceContext, 5),
                                top: getCorrectHeight(deviceContext, 50),

                                alignContent: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            <View style={{ padding: 10, backgroundColor: '#222', alignItems: 'center', justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#ffffff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {playerContext.player.error}
                                </Text>
                            </View>
                        </View>
                    )}
                    {showZapper && (
                        <View
                            style={{
                                position: 'absolute',
                                zIndex: 5,
                                flexDirection: 'row',
                                backgroundColor: 'rgba(0, 0, 0, 0.20)',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.30)',
                                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 20,
                                        marginBottom: 10,
                                    }}
                                >
                                    <Image_
                                        source={{
                                            uri: appContext.cloudUrl + contentContext.channelData.channelList[zapperIndex]?.images?.square,
                                        }}
                                        resizeMethod={'resize'}
                                        resizeMode={'cover'}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 100),
                                            height: getCorrectWidth(deviceContext, 100),
                                        }}
                                    ></Image_>
                                </View>
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.30)',
                                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 20,
                                        width: getCorrectWidth(deviceContext, 300) + 40,
                                    }}
                                >
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 200),
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {contentContext.channelData.channelList[zapperIndex].number}. {contentContext.channelData.channelList[zapperIndex].name}
                                    </Text>
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 200),
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {getZapperTime()}
                                    </Text>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 200),
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {getZapperProgram()}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}
                    {showOsd && (
                        <View
                            style={{
                                position: 'absolute',
                                zIndex: 5,
                                flexDirection: 'row',
                                backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <View
                                style={{
                                    position: 'absolute',
                                    zIndex: 5,
                                    flexDirection: 'row',
                                    backgroundColor: osdColor,
                                    justifyContent: 'flex-end',
                                    right: 75,
                                    top: 75,
                                    padding: 10,
                                    borderRadius: 5,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                    }}
                                >
                                    {osdNumber}
                                </Text>
                            </View>
                        </View>
                    )}
                    {/* {showNext && nextContent?.length > 0 && (
                        <View
                            style={{
                                position: 'absolute',
                                zIndex: 5,
                                bottom: 0,
                                left: 10,
                                right: 10,

                                flexDirection: 'row',
                                backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                width: '100%',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 100),
                                    width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 177),
                                    backgroundColor: appContext.application.theme.progresses.base,
                                }}
                            >
                                <NextContentTransform onNext={() => onNext(screenName)}>
                                    <View style={{ flexDirection: 'row', padding: getCorrectHeight(deviceContext, 5) }}>
                                        <Image_
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            source={{ uri: appContext.cloudUrl + nextContent?.images?.widescreen }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                                width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 167),
                                                height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 90),
                                            }}
                                        ></Image_>
                                    </View>
                                </NextContentTransform>
                            </View>
                            <View
                                style={{
                                    flex: 2,
                                    alignItems: 'flex-start',
                                    padding: getCorrectHeight(deviceContext, 10),
                                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                }}
                            >
                                <View>
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                        }}
                                    >
                                        {nextContent?.name}
                                    </Text>

                                    {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                                        <Text
                                            numberOfLines={15}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: '#fff',
                                            }}
                                        >
                                            {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                                        </Text>
                                    )}
                                    {nextContent.translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && nextContent.translations.length > 0 && (
                                        <Text
                                            numberOfLines={15}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: '#fff',
                                            }}
                                        >
                                            {nextContent.translations[0]?.description}
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    alignItems: 'flex-end',
                                    padding: getCorrectHeight(deviceContext, 10),
                                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'flex-end',
                                        margin: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onNext(screenName)}>
                                        <View
                                            style={{
                                                marginTop: 10,
                                                width: 200,
                                                height: getCorrectHeight(deviceContext, 30),
                                                backgroundColor: '#555',
                                                borderRadius: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: 'bold',
                                                    color: '#fff',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'continue')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                    <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => cancelNext()}>
                                        <View
                                            style={{
                                                marginTop: 10,
                                                width: 200,
                                                height: getCorrectHeight(deviceContext, 30),
                                                backgroundColor: '#333',
                                                borderRadius: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: 'bold',
                                                    color: '#fff',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'cancel')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    )} */}
                    <ControlToggleWrapper
                        activeOpacity={1}
                        style={{
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                        }}
                        onPress={() => {
                            if (global.showControls) {
                                hideControls();
                            } else {
                                showControls();
                            }
                        }}
                    >
                        {!isLoaded && (
                            <View
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    flexDirection: 'row',
                                    backgroundColor: '#000000',
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext),
                                }}
                            >
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Image_
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 150),
                                            height: getCorrectWidth(deviceContext, 50),
                                        }}
                                        source={{ uri: appContext.application.branding.logos.logo }}
                                    />
                                    <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                                </View>
                            </View>
                        )}
                        <PlayerFadeInOut paused={playerContext.paused} showControls={code === 'showcontrols'}>
                            {global.showControls && canShowControls && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        zIndex: 9999,
                                        width: isPlatformAndroid ? '100%' : getRealWidth(deviceContext, 'screen'),
                                        height: getRealHeight(deviceContext),
                                    }}
                                >
                                    {getElement(type)}
                                </View>
                            )}
                            {paused && (screenName == 'Short_Player' || screenName == 'Movie_Player' || screenName == 'Series_Player' || screenName == 'Course_Player') && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        width: isPlatformAndroid ? '100%' : getRealWidth(deviceContext, 'screen'),
                                        height: '100%',
                                    }}
                                >
                                    {getBackdrop()}
                                </View>
                            )}

                            {global.showControls && canShowControls && (
                                <View style={{ position: 'absolute', zIndex: 3, top: 0, left: 0, right: 0, bottom: 0 }}>
                                    <Image_
                                        source={require('../../../assets/images/player.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'stretch'}
                                        style={{
                                            width: isPlatformAndroid ? '100%' : getRealWidth(deviceContext, 'screen'),
                                            height: '100%',
                                        }}
                                    ></Image_>
                                </View>
                            )}
                            {global.showControls && canShowControls && (
                                <View style={{ position: 'absolute', zIndex: 3, top: 0, left: 0, right: 0, bottom: 0 }}>
                                    <Image_
                                        source={require('../../../assets/images/player.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'stretch'}
                                        style={{
                                            width: isPlatformAndroid ? '100%' : getRealWidth(deviceContext, 'screen'),
                                            height: '100%',
                                        }}
                                    ></Image_>
                                </View>
                            )}
                        </PlayerFadeInOut>

                        {showChannelList && (
                            <MiniEPG
                                onPressPlay={onPressPlay}
                                onPressPurchase={onPressPurchase}
                                onPressPlayRecording={onPressPlayRecording}
                                onPressPlayCatchupTV={onPressPlayCatchupTV}
                                navigation={navigation}
                                onHide={() => {
                                    setShowChannelList(false);
                                    setControlsControllerTimeout();
                                }}
                                playChannelIndex={playChannelIndex}
                                styling={styling}
                            />
                        )}

                        {showEpisodeList && (
                            <MiniSeries
                                navigation={navigation}
                                onHide={() => {
                                    setShowEpisodeList(false);
                                    playVideo();
                                    setControlsControllerTimeout();
                                }}
                                playEpisodeIndex={() => {
                                    setShowEpisodeList(false);
                                    startPlayer();
                                    setControlsControllerTimeout();
                                }}
                                styling={styling}
                            />
                        )}
                        {showShortList && (
                            <MiniShorts
                                navigation={navigation}
                                onHide={() => {
                                    setShowShortList(false);
                                    setControlsControllerTimeout();
                                }}
                                playShortIndex={() => {
                                    setShowShortList(false);
                                    startPlayer();
                                    setControlsControllerTimeout();
                                }}
                                styling={styling}
                            />
                        )}
                        {showLessonList && (
                            <MiniCourses
                                navigation={navigation}
                                onHide={() => {
                                    setShowLessonList(false);
                                    setControlsControllerTimeout();
                                }}
                                playLessonIndex={() => {
                                    setShowLessonList(false);
                                    startPlayer();
                                    setControlsControllerTimeout();
                                }}
                                styling={styling}
                            />
                        )}
                        {showSettingsMenu && (
                            <SettingsView
                                focusContext={focusContext}
                                userContext={userContext}
                                playerContext={playerContext}
                                changeScreenSetting={changeScreenSetting}
                                changeAudioSettings={changeAudioSetting}
                                changeTextSetting={changeTextSetting}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                styling={styling}
                                setshowTextSizingMenu={setshowTextSizingMenu}
                                setShowSettingMenu={setShowSettingMenu}
                            ></SettingsView>
                        )}
                        {showTextSizingMenu && (
                            <TextSizingView
                                focusContext={focusContext}
                                userContext={userContext}
                                playerContext={playerContext}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                styling={styling}
                                setshowTextSizingMenu={setshowTextSizingMenu}
                                setShowSettingMenu={setShowSettingMenu}
                            ></TextSizingView>
                        )}

                        {playerContext.castingStatus == 'connected' && playerContext.casting.type == 'Chromecast' && <ScreenFadeIn>{showChromecastPlaying(deviceContext, userContext)}</ScreenFadeIn>}
                        {playerContext.castingStatus == 'connected' && playerContext.casting.type == 'Airplay' && <ScreenFadeIn>{showAirplayPlaying(deviceContext, userContext)}</ScreenFadeIn>}
                        {showParental && (
                            <Parental
                                focusContext={focusContext}
                                styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                                setParentalApproved={setParentalApprovedLocal}
                                setShowParental={setShowParental}
                                profile={userContext.profile}
                                type={'access_content'}
                            ></Parental>
                        )}
                        {showSleeptimerModal && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.sleeptimer, appContext)} type={'sleeptimer'} submitChoice={() => onBack()} timerTime={60}></Modal>}
                        <View
                            style={[
                                {
                                    ...(isPlatformTizen || isPlatformWebos
                                        ? {
                                              height: Dimensions.get('screen').height,
                                              width: Dimensions.get('screen').width,
                                          }
                                        : {
                                              flex: 1,
                                          }),
                                },
                            ]}
                        >
                            {(!isPayPerView || payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView) && <TheoPlayer startPiP={startPiP} key={playerContext.stream.url} />}
                            {isPayPerView && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView && (
                                <View
                                    style={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        zIndex: 0,
                                        backgroundColor: '#000',
                                        width: getRealWidth(deviceContext),
                                        height: getRealHeight(deviceContext),
                                    }}
                                ></View>
                            )}
                        </View>
                    </ControlToggleWrapper>
                </View>
            )}
            {prepareNextEpisode && (
                <View
                    style={{
                        position: 'absolute',
                        zIndex: 99999,
                        flexDirection: 'row',
                        left: deviceContext.isPhone ? playerLeft - 10 : 0,
                        top: deviceContext.isPhone ? playerTop : 0,
                        backgroundColor: '#000000',
                        width: deviceContext.isPhone ? playerWidth + 20 : getRealWidth(deviceContext),
                        height: deviceContext.isPhone ? playerHeight : getRealHeight(deviceContext),
                        transform: [{ rotate: deviceContext.isPhone ? '90deg' : '0deg' }],
                    }}
                >
                    <View style={{ position: 'absolute', zIndex: 0 }}>
                        <Image_
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            source={{ uri: appContext.cloudUrl + contentContext.seriesData.season?.images?.background }}
                            style={{
                                width: deviceContext.isPhone ? getRealHeight(deviceContext) + 50 : getRealWidth(deviceContext),
                                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) + 70,
                            }}
                        ></Image_>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: '100%',
                                zIndex: 1,
                                opacity: 0.8,
                            }}
                        >
                            <Image_
                                source={require('../../../assets/images/vod_gradient.png')}
                                resizeMode={'cover'}
                                style={{
                                    width: deviceContext.isPhone ? getRealHeight(deviceContext) + 50 : getRealWidth(deviceContext),
                                    height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) + 70,
                                }}
                            ></Image_>
                        </View>
                    </View>

                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <View style={{ marginLeft: getCorrectWidth(deviceContext, 50), flexDirection: 'column' }}>
                            {contentContext.seriesData.series.images.logo != undefined && contentContext.seriesData.series.images.logo != '' && (
                                <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                                    <RemoteImageHeightWidth uri={appContext.cloudUrl + contentContext.seriesData.series?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={100} deviceContext={deviceContext} />
                                </View>
                            )}
                            {(contentContext.seriesData.series.images.logo == undefined || contentContext.seriesData.series.images.logo == '') && (
                                <View style={{ justifyContent: 'center' }}>
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                            fontWeight: 'bold',
                                            color: '#ffffff',
                                            includeFontPadding: false,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {contentContext.seriesData.season.name}
                                    </Text>
                                </View>
                            )}
                            <View style={{ marginTop: getCorrectHeight(deviceContext, deviceContext.isPhone ? 5 : 25) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        color: '#ffffff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {getNextEpisodeTitle()}
                                </Text>
                                <Text
                                    numberOfLines={5}
                                    style={{
                                        marginTop: 10,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#ffffff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {getNextEpisodeDescription()}
                                </Text>
                            </View>
                            <View style={{ marginTop: getCorrectHeight(deviceContext, deviceContext.isPhone ? 20 : 40), flexDirection: 'row' }}>
                                <Pressable
                                    hasPreferredFocus={true}
                                    focusContext={focusContext}
                                    style={{
                                        marginRight: getCorrectHeight(deviceContext, 7),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => onBack()}
                                >
                                    <Text
                                        style={{
                                            marginHorizontal: getCorrectWidth(deviceContext, 10),
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                            includeFontPadding: false,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'back_seasons')}
                                    </Text>
                                </Pressable>
                                <Pressable
                                    hasPreferredFocus={true}
                                    focusContext={focusContext}
                                    style={{
                                        marginRight: getCorrectHeight(deviceContext, 7),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        backgroundColor: '#999999',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => onNext(screenName)}
                                >
                                    <Text
                                        style={{
                                            marginHorizontal: getCorrectWidth(deviceContext, 10),
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            includeFontPadding: false,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'new_episode')} {countDown}
                                    </Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>
            )}

            {/* {showPip && !prepareNextEpisode && deviceContext.isPhone && <TheoPlayer startPiP={startPiP} style={{ width: getRealWidth(deviceContext), height: getRealHeight(deviceContext) }} key={playerContext.stream.url}></TheoPlayer>} */}
        </View>
    );
};

export default Screen_;
