import { FocusContext, Pressable, View, ScrollView, useTVRemoteHandler, CoreManager } from '@scriptx-com/xtv-toolkit';
import React from 'react';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import debounce from 'lodash.debounce';
import { FlashList } from '@scriptx-com/flash-list';
import { isFactorMobile, isFactorTv } from '@rnv/renative';
import { AppContext } from '../../../context/appContext';
import { Ratio } from '../../../../screens/wrapper/helpers/getFocusOptions';

type BaseRailProps = {
    contentLoaderWidth: number;
    contentLoaderHeight: number;
    data: any[];
    focusContext?: FocusContext;
    railIndex: number;
    railType: string;
    contentType?: string;
    getData?: () => void;
    emptyItemRenderer?: (props: any) => React.ReactNode;
    animator: any;
    isUserGeneratedListAndEmpty: boolean;
};

type BaseRailResponse = {
    Loader: any;
    flashListProps: any;
    loading: boolean;
    onViewableItemsChanged: any;
    scrollRight: any;
    scrollLeft: any;
    railsRef: any;
    viewableItemsCount: number;
};

const MAX_IN_LOAD_QUE = 2;

const useBaseRail = (props: BaseRailProps): BaseRailResponse => {
    const { data, railIndex, railType, focusContext, contentLoaderHeight, contentLoaderWidth, contentType, getData, emptyItemRenderer, animator, isUserGeneratedListAndEmpty } = props;
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const [loading, _setLoading] = React.useState(true);
    const currentFocusKey = React.useRef<null | string>(null);
    const [emptyItems] = React.useState(new Array(3).fill(0));
    const interval = React.useRef<NodeJS.Timeout>();
    const interval2 = React.useRef<NodeJS.Timeout>();
    const screenName = getCurrentScreenName();
    const dataFetched = React.useRef<boolean>(false);
    const railsRef = React.useRef<FlashList<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);

    const fetchData = () => {
        if (isFactorTv && screenName === 'Home') {
            if (!dataFetched.current) {
                const currentFocus = CoreManager.getCurrentFocus()?.getFocusKey();
                if (currentFocus) {
                    const [_railType, _railIndex] = currentFocus.split('-');
                    const match = `${_railType}-${_railIndex}` === `${railType}-${railIndex}`;
                    if (Object.values(global.RAILS_IN_LOADING_QUEUE).length < MAX_IN_LOAD_QUE && match) {
                        clearInterval(interval.current);
                        global.RAILS_IN_LOADING_QUEUE[`${railType}-${railIndex}`] = true;
                        dataFetched.current = true;
                        getData?.();
                    }
                }
            }
        } else {
            getData?.();
        }
    };

    const onEvent = React.useRef(
        debounce(() => {
            // Helper debug code
            // const currentFocus = CoreManager.getCurrentFocus()?.getFocusKey();
            // if (currentFocus) {
            //     const [_railType, _railIndex] = currentFocus.split('-');
            //     console.log({_railType})

            // }
            fetchData();
        }, 200)
    ).current;

    React.useEffect(() => {
        fetchData();

        return () => {
            clearInterval(interval.current);
            clearInterval(interval2.current);
        };
    }, []);

    useTVRemoteHandler(() => {
        onEvent();
    });

    React.useEffect(() => {
        if (dataFetched.current) {
            delete global.RAILS_IN_LOADING_QUEUE[`${railType}-${railIndex}`];
        }
    }, [data.length]);

    React.useEffect(() => {
        if (userContext.refreshData && ['Watched', 'Favorites', 'Downloads'].includes(contentType)) {
            getData?.();
        }
    }, [userContext.refreshData]);



    const Loader = React.useCallback(() => {
        if (loading && screenName === 'Home' && !isUserGeneratedListAndEmpty) {
            return null;
            return (
                <View>
                    <ScrollView style={{ flexDirection: 'row', height: contentLoaderHeight }} focusContext={focusContext} horizontal showsHorizontalScrollIndicator={false}>
                        {emptyItems.map((_, index) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={{ width: contentLoaderWidth, height: contentLoaderHeight, backgroundColor: appContext.application.theme.images.background, borderRadius: 10, marginHorizontal: 3 }}
                                    onFocus={() => (currentFocusKey.current = `_${railType}-${railIndex}-${index}`)}
                                    onBlur={() => (currentFocusKey.current = null)}
                                    focusOptions={{
                                        focusKey: `_${railType}-${railIndex}-${index}`,
                                        animator: animator,
                                    }}
                                />
                            );
                        })}
                    </ScrollView>
                </View>
            );
        }

        return null;
    }, [loading, emptyItems, screenName]);

    const flashListProps = {
        focusOptions: {
            nextFocusUp: railIndex === 0 ? ['btn-main', 'purchase_option', 'favorites', 'watchlist', 'download', 'replay', 'trailer'] : [],
        },
        contentContainerStyle: {
            //paddingLeft: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 ? getRailPadding() : 0
        },
    };

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === data.length) {
            railsRef.current?.scrollToEnd({ animated: true });
        } else {
            railsRef.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        railsRef.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    return {
        Loader,
        flashListProps,
        loading,
        onViewableItemsChanged,
        scrollRight,
        scrollLeft,
        railsRef,
        viewableItemsCount,
    };
};

export default useBaseRail;
