import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { KeyboardContext } from '../../../application/context/keyboardContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import HTMLRenderer from '../../../libs/react-native-render-html';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getKeyboardStyling, getFocusStyling, getInputStyling, getPressableStyling, getPressableTextStyling, getModalStyling } from '../../../application/builder/components/helpers/helper';
import { getAuthenticationText } from '../helper/helper';
import Modal from '../../../application/builder/components/modal/modal';
import { getGlobals } from '../../../globals';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showExit, setShowExit] = React.useState(false);
    const [appId, setAppId] = React.useState(userContext.appId);
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        if (userContext.appId != '') {
            checkAppId();
        } else {
            setShow(true);
        }
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.SERVICES, duration);
            };
        }, [])
    );

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (eventType == 'back' && eventKeyAction == 'down') {
            if (keyboardVisible) {
                setKeyboardVisible(false);
            } else {
                if (appContext.application.settings.languages.length > 1) {
                    userContext.setSelectedLanguage('');
                    userContext.setSelectedLanguageCode('');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Language' }],
                        })
                    );
                }
            }
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    if (appContext.application.settings.languages.length > 1) {
                        userContext.setSelectedLanguage('');
                        userContext.setSelectedLanguageCode('');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Language' }],
                            })
                        );
                    }
                }
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const checkAppId = async () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_SERVICE);
        setLoading(true);
        var test = appContext.application.settings.nested_apps.find((s) => s.appid == appId);
        if (test != undefined) {
            userContext.setOrginalPackageId(getGlobals().AppPackageID);
            fetchData(test.packageid);
        } else if (appId == appContext.application.branding.appid) {
            utils.storeJson('ServiceID', appId);
            userContext.setAppId(appId);
            appContext.setApplication(global.Application);

            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Login' }],
                })
            );
        } else {
            setShow(true);
            setError(lang.getTranslation(userContext, 'ERROR_SERVICE'));
            setAppId('');
            setLoading(false);
        }
    };
    const fetchData = async (packageId) => {
        const path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        await fetch(path)
            .then((response) => response.json())
            .then(async (responseJson) => {
                global.Application = responseJson;
                var pathCart = 'https://cloudtv.akamaized.net/' + responseJson.client + '/' + responseJson.deployment + '/' + responseJson.branding.service + '/carts/' + responseJson.settings.webshop_id + '_cart.json';
                await fetch(pathCart)
                    .then((responseCart) => responseCart.json())
                    .then((responseCartJson) => {
                        global.Cart = responseCartJson;
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    })
                    .catch(() => {
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);

                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    });
            })
            .catch(() => {
                setShow(true);
                setError(lang.getTranslation(userContext, 'ERROR_SERVICE'));
                setAppId('');
                userContext.setOrginalPackageId('');
                setLoading(false);
            });
    };
    const setAppIdLocal = (value: any) => {
        setAppId(value);
        setError('');
    };

    const cancelExit = () => {
        setShowExit(false);
    };
    const closeError = () => {
        setError('');
    };

    const goBack = () => {
        if (appContext.application.settings.languages.length > 1) {
            userContext.setSelectedLanguage('');
            userContext.setSelectedLanguageCode('');
            userContext.setAutoLogin(false);
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Language' }],
                })
            );
        }
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {keyboardVisible && (
                <Keyboard
                    onCloseKeyboard={() => setKeyboardVisible(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                    onChangText={setAppIdLocal}
                    value={appId}
                    focusOptions={{ nextFocusUp: 'submit-button', nextFocusDown: 'submit-button' }}
                ></Keyboard>
            )}
            {error != '' && <Modal error={error} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'error'} setShowModal={closeError} submitChoice={closeError}></Modal>}
            {show && (
                <View style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.services?.header.title_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'connectservice')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                                    fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.services?.header.sub_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'enterappid').toUpperCase()}
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 4,
                                flexDirection: appContext.application.authentication?.login?.translations?.translations?.length > 0 && !deviceContext.isKaiOs ? 'row' : 'column',
                            }}
                        >
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginBottom: 50 }}>
                                <Input
                                    onChangText={(value) => setAppIdLocal(value)}
                                    icon={faGlobe}
                                    width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                    isSecure={true}
                                    placeholder={'a1b...'}
                                    animation={getFocusStyling('Inputs', appContext)}
                                    styling={getInputStyling(appContext.application.authentication.services.components?.input?._id, appContext)}
                                    value={appId}
                                    focusOptions={{ focusKey: 'service' }}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                    showKeyboard={() => {
                                        openKeyboard({
                                            focusOptions: { nextFocusUp: 'service', nextFocusDown: 'service' },
                                        });
                                    }}
                                ></Input>
                                <View style={{ marginTop: 20, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                <Pressable
                                    style={{
                            
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    hasPreferredFocus={true}
                                    focusOptions={{ focusKey: 'submit-button' }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => checkAppId()}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                >
                                    <View>
                                        {!loading && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.services.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'submit')}
                                            </Text>
                                        )}
                                        {loading && <ActivityIndicator color={'#ffffff'} size={'small'}></ActivityIndicator>}
                                    </View>
                                </Pressable>
                                </View>
                                {appContext.application.settings.languages.length > 1 && (
                                    <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        style={{
                                         
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.services.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => goBack()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.services.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'back')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                    </View>
                                )}
                            </View>
                            {appContext.application.authentication?.services?.text.length > 0 && (
                                <View
                                    style={{
                                        borderColor: appContext.application.authentication?.services?.header.title_color,
                                        borderWidth: 1,
                                        marginVertical: 50,
                                    }}
                                ></View>
                            )}
                            {appContext.application.authentication?.services?.translations?.translations?.length > 0 && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <View
                                        style={{
                                            borderRadius: 5,
                                            
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.4),
                                            marginVertical: 50,
                                        }}
                                    >
                                        <HTMLRenderer
                                            html={getAuthenticationText(appContext.application.authentication?.services?.translations?.translations, userContext)}
                                            contentWidth={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                        />
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
