import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faAirplay, faArrowLeft, faBackward, faBackwardStep, faForward, faForwardStep, faHeart, faPause, faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { FocusContext, Image, View } from '@scriptx-com/xtv-toolkit';
import React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { Player_BigButton as styling } from '../../../models/elements/players/bigbuttons/bigbuttons';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import Pressable from '../../components/pressable/pressable';
import Text from '../../components/text';
import { getAudioBackground, getAudioCurrentTrack, getAudioImage, getAudioSub, getAudioTitle, getNext, getPauseControl, getPrevious } from './helpers/functions';
import { ProgressBars } from './helpers/progress';

export interface Props {
    navigation: any;
    focusContext?: FocusContext;
    styling: styling;
    onPrevious: any;
    onNext: any;
    onPreviousAudio: any;
    onNextAudio: any;
    openChromecast: any;
    openAirplay: any;
    manageFavorite: any;
    onPlayPause: any;
    onScrubberDrag: any;
    onForward: any;
    onRewind: any;
    isFavorite: boolean;
    onBack: any;
}

export const Player_Audio = ({ navigation, styling, onPrevious, onNext, onNextAudio, onPreviousAudio, openChromecast, openAirplay, manageFavorite, onPlayPause, onScrubberDrag, onForward, onRewind, isFavorite, onBack, focusContext }: Props) => {
    const playerContext = React.useContext(PlayerContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const screenName = getCurrentScreenName();
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);

    React.useEffect(() => {
        // seems like in audio player controls are always shown so setting this explicitly
        // because in theoplayer component there is a check in TIME_UPDATE event listener
        // and if global.showControls is false then time update is not emitted
        global.showControls = true;
        return () => {
            global.showControls = false;
        };
    }, []);
    return (
        <View style={{ flex: 1, paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }} focusContext={focusContext}>
            <View
                style={{
                    position: 'absolute',
                    width: getRealWidth(deviceContext),
                    height: getRealWidth(deviceContext),
                    zIndex: -1,
                    backgroundColor: '#000',
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'stretch'}
                    blurRadius={99}
                    style={{
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                    }}
                    source={{ uri: getAudioBackground(contentContext, screenName, appContext) }}
                />
            </View>
            <View
                style={{
                    flex: 1,
                    margin: getCorrectWidth(deviceContext, 20),
                    marginBottom: getCorrectHeight(deviceContext, 20),
                }}
            >
                <View style={{ height: getCorrectHeight(deviceContext, 40) }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                            }}
                        >
                            <Pressable
                                focusOptions={{
                                    focusKey: 'btn-back',
                                    nextFocusDown: ['audio-prev', 'audio-next', 'audio-controls'],
                                }}
                                style={{
                                    margin: 10,
                                    width: getCorrectWidth(deviceContext, 30),
                                    height: getCorrectWidth(deviceContext, 30),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    //backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                    borderRadius: 100,
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onBack()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')} style={{}}></FontAwesomeIcon>
                                </View>
                            </Pressable>
                            <View style={{ flex: 8 }}></View>
                            {(deviceContext.isChrome || deviceContext.isPhone || deviceContext.isTablet) && !deviceContext.isApple && (
                                <Pressable
                                    style={{
                                        margin: 10,
                                        width: getCorrectWidth(deviceContext, 30),
                                        height: getCorrectWidth(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: 100,
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => openChromecast()}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChromecast} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')} style={{}}></FontAwesomeIcon>
                                    </View>
                                </Pressable>
                            )}
                            {deviceContext.isChrome ||
                                deviceContext.isSafari ||
                                ((deviceContext.isPhone || (deviceContext.isTablet && deviceContext.isApple)) && (
                                    <Pressable
                                        style={{
                                            margin: 10,
                                            width: getCorrectWidth(deviceContext, 30),
                                            height: getCorrectWidth(deviceContext, 30),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: 100,
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => openAirplay()}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faAirplay} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')} style={{}}></FontAwesomeIcon>
                                        </View>
                                    </Pressable>
                                ))}
                            {appContext.application.settings.allow_favorites && (
                                <Pressable
                                    focusOptions={{
                                        focusKey: 'btn-fav',
                                        nextFocusDown: ['audio-next', 'audio-prev', 'audio-controls'],
                                    }}
                                    style={{
                                        margin: 10,
                                        width: getCorrectWidth(deviceContext, 30),
                                        height: getCorrectWidth(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: 100,
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => manageFavorite()}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={isFavorite ? faHeartSolid : faHeart}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'Large')}
                                            style={{}}
                                        ></FontAwesomeIcon>
                                    </View>
                                </Pressable>
                            )}
                        </View>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            alignContent: 'center',
                            width: getRealWidth(deviceContext) - getCorrectHeight(deviceContext, 20) * 2,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: getCorrectHeight(deviceContext, 25),
                            zIndex: 200,
                        }}
                    >
                        {playerContext.player.error != '' && playerContext.player.error != undefined && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    backgroundColor: '#111',
                                    height: getCorrectHeight(deviceContext, deviceContext.isPhone ? 40 : 30),
                                    borderRadius: getCorrectWidth(deviceContext, 5),
                                    alignItems: 'center',
                                    paddingHorizontal: 20,
                                }}
                            >
                                <Text
                                    style={{
                                        fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                        color: '#ffffff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {playerContext.player.error}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        {getPrevious(contentContext, screenName, styling, deviceContext, appContext, onPrevious, {
                            focusKey: 'audio-prev',
                            nextFocusDown: 'audio-controls',
                            nextFocusUp: ['btn-back'],
                        })}
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View
                            style={{
                                backgroundColor: appContext.application.theme.images?.background,
                                borderRadius: appContext.application.theme.images.radius,
                                width: getRealWidth(deviceContext) * 0.2,
                                height: getRealWidth(deviceContext) * 0.2,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: getAudioImage(contentContext, screenName, appContext) }}
                                style={{
                                    borderRadius: appContext.application.theme.images.radius,
                                    width: getRealWidth(deviceContext) * 0.2,
                                    height: getRealWidth(deviceContext) * 0.2,
                                }}
                            ></Image>
                        </View>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                        {getNext(contentContext, screenName, styling, deviceContext, appContext, onNext, {
                            focusKey: 'audio-next',
                            nextFocusDown: 'audio-controls',
                            nextFocusUp: ['btn-fav', 'btn-back'],
                        })}
                    </View>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Text
                        style={{
                            color: styling.texts.title_color,
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                            textShadowColor: 'rgba(0, 0, 0, 0.1',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {getAudioTitle(contentContext, screenName)}
                    </Text>
                    <Text
                        style={{
                            color: styling.texts.subtext_color,
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Large'),
                            fontWeight: styling.texts.subtext_bold ? 'bold' : 'normal',
                            textShadowColor: 'rgba(0, 0, 0, 0.1)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {getAudioSub(contentContext, screenName, userContext)}
                    </Text>
                </View>

                <View style={{ flex: 1, justifyContent: 'center' }}>
                    {screenName != 'Radio_Player' && (
                        <View style={{ flexDirection: 'row' }} focusOptions={{ group: 'audio-controls', focusKey: 'audio-controls', nextFocusUp: ['audio-next', 'audio-prev'] }}>
                            <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                <View
                                    style={{
                                        flex: 2,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        width: 200,
                                    }}
                                >
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getCorrectWidth(deviceContext, 50),
                                            height: getCorrectWidth(deviceContext, 50),
                                            padding: getCorrectHeight(deviceContext, 2),
                                            borderRadius: 100,
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => onPreviousAudio(screenName)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faBackwardStep}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                            style={{ paddingHorizontal: getCorrectHeight(deviceContext, 10) }}
                                        ></FontAwesomeIcon>
                                    </Pressable>
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getCorrectWidth(deviceContext, 50),
                                            height: getCorrectWidth(deviceContext, 50),
                                            padding: getCorrectHeight(deviceContext, 2),
                                            borderRadius: 100,
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => onRewind(10)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faBackward}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                            style={{ paddingHorizontal: getCorrectHeight(deviceContext, 10) }}
                                        ></FontAwesomeIcon>
                                    </Pressable>
                                    {getPauseControl(contentContext, screenName) && (
                                        <Pressable
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: getCorrectWidth(deviceContext, 65),
                                                height: getCorrectWidth(deviceContext, 65),
                                                padding: getCorrectHeight(deviceContext, 2),
                                                borderRadius: 100,
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => onPlayPause()}
                                        >
                                            <FontAwesomeIcon
                                                icon={playerContext.paused ? faPlay : faPause}
                                                color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                                size={getFontSize(deviceContext, 'ExtraExtraExtraLarge') * 1.5}
                                                style={{ padding: getCorrectHeight(deviceContext, 10) }}
                                            ></FontAwesomeIcon>
                                        </Pressable>
                                    )}
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getCorrectWidth(deviceContext, 50),
                                            height: getCorrectWidth(deviceContext, 50),
                                            padding: getCorrectHeight(deviceContext, 2),
                                            borderRadius: 100,
                                            alignContent: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => onForward(10)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faForward}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                            style={{ paddingHorizontal: getCorrectHeight(deviceContext, 10) }}
                                        ></FontAwesomeIcon>
                                    </Pressable>
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getCorrectWidth(deviceContext, 50),
                                            height: getCorrectWidth(deviceContext, 50),
                                            padding: getCorrectHeight(deviceContext, 2),
                                            borderRadius: 100,
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => onNextAudio(screenName)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faForwardStep}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                            style={{ paddingHorizontal: getCorrectHeight(deviceContext, 10) }}
                                        ></FontAwesomeIcon>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    )}
                    <View style={{ alignItems: 'center' }}>
                        <ProgressBars
                            onClose={null}
                            playerContext={playerContext}
                            contentContext={contentContext}
                            onScrubberDrag={onScrubberDrag}
                            deviceContext={deviceContext}
                            userContext={userContext}
                            appContext={appContext}
                            focusContext={focusContext}
                        />
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        {screenName == 'Album_Player' && (
                            <Text
                                style={{
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    fontWeight: styling.texts.subtext_bold ? 'bold' : 'normal',
                                    textShadowColor: 'rgba(0, 0, 0, 0.1)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.albumData.songIndex + 1}. {getAudioCurrentTrack(contentContext, screenName, userContext)}
                            </Text>
                        )}
                        {screenName == 'Podcast_Player' && (
                            <Text
                                style={{
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    fontWeight: styling.texts.subtext_bold ? 'bold' : 'normal',
                                    textShadowColor: 'rgba(0, 0, 0, 0.1)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.podcastData.episodeIndex + 1}. {getAudioCurrentTrack(contentContext, screenName, userContext)}
                            </Text>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
};

export default Player_Audio;
