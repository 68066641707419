import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faAirplay, faBackspace, faStar, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Image, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React from 'react';
import lang from '../../../../languages/languages';
import { colorShade } from '../../../../styling/colorShade';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFontSize } from '../../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../components/helpers/helper';
import Pressable from '../../../components/pressable/pressable';
import Text from '../../../components/text';

export const findChannelIndexAndCategoryIndex = (categories: any, channelId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        category.channels.forEach((channel_, index_) => {
            if (channelId == channel_._id) {
                response = {
                    category_index: index,
                    channel_index: index_,
                };
            }
        });
    });
    return response;
};
export const findCamIndexAndCategoryIndex = (categories: any, camId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.cams;
        if (test != undefined) {
            test.forEach((cam_, index_) => {
                if (cam_._id == camId) {
                    response = {
                        category_index: index,
                        cam_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findRadioIndexAndCategoryIndex = (categories: any, radioId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.radio;
        if (test != undefined) {
            test.forEach((radio_, index_) => {
                if (radio_._id == radioId) {
                    response = {
                        category_index: index,
                        radio_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findAlbumIndexAndCategoryIndex = (categories: any, albumId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.albums;
        if (test != undefined) {
            test.forEach((album_, index_) => {
                if (album_._id == albumId) {
                    response = {
                        category_index: index,
                        album_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findPodcastIndexAndCategoryIndex = (categories: any, podcastId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.podcasts;
        if (test != undefined) {
            test.forEach((podcast_, index_) => {
                if (podcast_._id == podcastId) {
                    response = {
                        category_index: index,
                        podcast_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findMovieIndexAndCategoryIndex = (categories: any, movieId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.movies;
        if (test != undefined) {
            test.forEach((movie_, index_) => {
                if (movie_._id == movieId) {
                    response = {
                        category_index: index,
                        movie_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findSeriesIndexAndCategoryIndex = (categories: any, seriesId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.series;
        if (test != undefined) {
            test.forEach((series_, index_) => {
                if (series_._id == seriesId) {
                    response = {
                        category_index: index,
                        series_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const findCourseIndexAndCategoryIndex = (categories: any, courseId: any) => {
    var response = [] as any;
    categories.forEach((category, index) => {
        var test = category.courses;
        if (test != undefined) {
            test.forEach((course_, index_) => {
                if (course_._id == courseId) {
                    response = {
                        category_index: index,
                        course_index: index_,
                    };
                }
            });
        }
    });
    return response;
};
export const hasLogo = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return false;
    }
    if (screenName == 'Recording_Player') {
        return false;
    }
    if (screenName == 'CatchupTV_Player') {
        return false;
    }
    if (screenName == 'Cam_Player') {
        return false;
    }
    if (screenName == 'Movie_Player') {
        if (contentContext.movieData.movie.images.logo != undefined && contentContext.movieData.movie.images.logo != '') {
            return true;
        } else {
            return false;
        }
    }
    if (screenName == 'Series_Player') {
        if (contentContext.seriesData.series.images.logo != undefined && contentContext.seriesData.series.images.logo != '') {
            return true;
        } else {
            return false;
        }
    }
    if (screenName == 'Course_Player') {
        return false;
    }
};
export const getTitle = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.channel.number + '. ' + contentContext.channelData.channel.name;
    }
    if (screenName == 'Recording_Player') {
        return contentContext.recordingData.program.n;
    }
    if (screenName == 'CatchupTV_Player') {
        return contentContext.channelData.channel.number + '. ' + contentContext.channelData.channel.name;
    }
    if (screenName == 'Cam_Player') {
        return contentContext.camData.cam.name;
    }
    if (screenName == 'Movie_Player') {
        return contentContext.movieData.movie.name;
    }
    if (screenName == 'Series_Player') {
        return contentContext.seriesData.series.name;
    }
    if (screenName == 'Course_Player') {
        return contentContext.courseData.course.name;
    }
};
export const getLogo = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return '';
    }
    if (screenName == 'Recording_Player') {
        return '';
    }
    if (screenName == 'CatchupTV_Player') {
        return '';
    }
    if (screenName == 'Cam_Player') {
        return '';
    }
    if (screenName == 'Movie_Player') {
        return contentContext.movieData.movie.images.logo ?? '';
    }
    if (screenName == 'Series_Player') {
        return contentContext.seriesData.series.images.logo ?? '';
    }
    if (screenName == 'Course_Player') {
        return '';
    }
};
export const getTitleNext = (contentContext, screenName, userContext) => {
    if (screenName == 'Channel_Player') {
        if (contentContext.channelData.program != undefined) {
            return lang.getTranslation(userContext, 'next_program');
        } else {
            return null;
        }
    }
    if (screenName == 'Recording_Player') {
        return null;
    }
    if (screenName == 'CatchupTV_Player') {
        if (contentContext.channelData.program != undefined) {
            return lang.getTranslation(userContext, 'next_program');
        } else {
            return null;
        }
    }
    if (screenName == 'Cam_Player') {
        if (contentContext.channelData.program != undefined) {
            return lang.getTranslation(userContext, 'next_timeblock');
        } else {
            return null;
        }
    }
    if (screenName == 'Movie_Player') {
        return null;
    }
    if (screenName == 'Series_Player') {
        var index = contentContext.seriesData.episodeIndex <= contentContext.seriesData.episodeList.length - 1 ? contentContext.seriesData.episodeIndex + 1 : -1;
        if (index > -1) {
            var episode = contentContext.seriesData.episodeList[index];
            if (episode) {
                return episode.number + ' ' + episode.name;
            } else {
                return '';
            }
        }
    }
    if (screenName == 'Course_Player') {
        var index = contentContext.courseData.lessonIndex <= contentContext.courseData.lessonList.length - 1 ? contentContext.courseData.lessonIndex + 1 : -1;
        if (index > -1) {
            var lesson = contentContext.courseData.lessonList[index];
            return lesson.name;
        }
    }
    if (screenName == 'Short_Player') {
        var index = contentContext.shortData.shortIndex <= contentContext.shortData.shortList.length - 1 ? contentContext.shortData.shortIndex + 1 : -1;
        if (index > -1) {
            var short = contentContext.shortData.shortList[index];
            return short.name;
        }
    }
};
export const getSubText = (contentContext, screenName, deviceContext) => {
    if (screenName == 'Channel_Player') {
        if (contentContext.channelData.program?.s != undefined) {
            return moment.unix(contentContext.channelData.program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.channelData.program?.e).format(deviceContext.clockSetting);
        } else {
            return '';
        }
    }
    if (screenName == 'Recording_Player') {
        return moment.unix(contentContext.recordingData.program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.recordingData.program?.e).format(deviceContext.clockSetting);
    }
    if (screenName == 'CatchupTV_Player') {
        return moment.unix(contentContext.channelData.program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.channelData.program?.e).format(deviceContext.clockSetting);
    }
    if (screenName == 'Cam_Player') {
        return moment.unix(contentContext.camData.program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.camData.program?.e).format(deviceContext.clockSetting);
    }
    if (screenName == 'Movie_Player') {
        return contentContext.movieData.movie.name;
    }
    if (screenName == 'Series_Player') {
        return contentContext.seriesData.episode.number + ' ' + contentContext.seriesData.episode.name;
    }
    if (screenName == 'Course_Player') {
        return contentContext.courseData.lesson.name;
    }
    if (screenName == 'Short_Player') {
        return contentContext.shortData.short.name;
    }
};
export const getSubTextNext = (contentContext, screenName, deviceContext) => {
    if (screenName == 'Channel_Player') {
        var program = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
        if (program != undefined) {
            return moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting);
        } else {
            return null;
        }
    }
    if (screenName == 'Recording_Player') {
        return null;
    }
    if (screenName == 'CatchupTV_Player') {
        var program = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
        if (program != undefined) {
            return moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting);
        } else {
            return null;
        }
    }
    if (screenName == 'Cam_Player') {
        var program = contentContext.camData.programList[contentContext.camData.programIndex + 1];
        if (program != undefined) {
            return moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting);
        } else {
            return null;
        }
    }
    if (screenName == 'Movie_Player') {
        return '';
    }
    if (screenName == 'Series_Player') {
        var episode = contentContext.seriesData.episodeList[contentContext.episodeIndex + 1];
        if (episode != undefined) {
            return episode.number + ' ' + episode.name;
        } else {
            var season = contentContext.seriesData.series.seasons[contentContext.seriesData.seasonCategoryIndex + 1];
            if (season != undefined) {
                return season.episodes[0].number + ' ' + season.episodes[0].name;
            } else {
                return null;
            }
        }
    }
    if (screenName == 'Course_Player') {
        var lesson = contentContext.courseData.lessonList[contentContext.lessonIndex + 1];
        if (lesson != undefined) {
            return lesson.name;
        } else {
            return null;
        }
    }
};
export const getSubSubText = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.program?.n ?? '';
    }
    if (screenName == 'Recording_Player') {
        return '';
    }
    if (screenName == 'CatchupTV_Player') {
        return contentContext.channelData.program?.n ?? '';
    }
    if (screenName == 'Cam_Player') {
        return contentContext.channelData.program?.n ?? '';
    }
    if (screenName == 'Movie_Player') {
        return '';
    }
    if (screenName == 'Series_Player') {
        return '';
    }
    if (screenName == 'Course_Player') {
        return '';
    }
};
export const getSubSubTextNext = (contentContext, screenName, userContext) => {
    if (screenName == 'Channel_Player') {
        var program = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
        if (program != undefined) {
            return program.n;
        } else {
            return null;
        }
    }
    if (screenName == 'Recording_Player') {
        return '';
    }
    if (screenName == 'CatchupTV_Player') {
        var program = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
        if (program != undefined) {
            return program.n;
        } else {
            return null;
        }
    }
    if (screenName == 'Cam_Player') {
        var program = contentContext.camData.programList[contentContext.channelData.programIndex + 1];
        if (program != undefined) {
            return program.n;
        } else {
            return null;
        }
    }
    if (screenName == 'Movie_Player') {
        return '';
    }
    if (screenName == 'Series_Player') {
        var index = contentContext.seriesData.episodeIndex <= contentContext.seriesData.episodeList.length - 1 ? contentContext.seriesData.episodeIndex + 1 : -1;
        if (index > -1) {
            var episode = contentContext.seriesData.episodeList[index];
            return getDescription(episode, userContext);
        }
    }
    if (screenName == 'Course_Player') {
        var index = contentContext.courseData.lessonIndex <= contentContext.courseData.lessonList.length - 1 ? contentContext.courseData.lessonIndex + 1 : -1;
        if (index > -1) {
            var lesson = contentContext.courseData.lessonList[index];
            return getDescription(lesson, userContext);
        }
    }
    if (screenName == 'Shorts_Player') {
        var index = contentContext.shortData.shortIndex <= contentContext.shortData.shortList.length - 1 ? contentContext.shortData.shortIndex + 1 : -1;
        if (index > -1) {
            var short = contentContext.shortData.shortList[index];
            return getDescription(short, userContext);
        }
    }
};
const getDescription = (item, userContext) => {
    var test = item?.translations.find((trans) => trans.name == userContext.selectedLanguage);
    if (test != undefined) {
        return test?.description;
    } else {
        return item?.translations[0]?.description;
    }
};

export const getPrevious = (contentContext, screenName, styling, deviceContext, appContext, onPrevious, focusOptions = undefined) => {
    if (screenName == 'Album_Player') {
        if (contentContext.albumData.albumIndex >= 1) {
            var previous = contentContext.albums.categories[contentContext.albumData.albumCategoryIndex].albums[contentContext.albumData.albumIndex - 1];
            if (previous != undefined) {
                return (
                    <View style={{ alignItems: 'flex-start' }}>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onPrevious(screenName)}
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    padding: getCorrectHeight(deviceContext, 10),
                                    width: getCorrectWidth(deviceContext, 100),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            }}
                                        ></Image>
                                    </View>
                                </>
                            </Pressable>
                        </View>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Podcast_Player') {
        if (contentContext.podcastData.podcastIndex >= 1) {
            var previous = contentContext.podcasts.categories[contentContext.podcastData.podcastCategoryIndex].podcasts[contentContext.podcastData.podcastIndex - 1];
            if (previous != undefined) {
                return (
                    <View style={{ alignItems: 'flex-start' }}>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onPrevious(screenName)}
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    padding: getCorrectHeight(deviceContext, 10),
                                    width: getCorrectWidth(deviceContext, 100),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            }}
                                        ></Image>
                                    </View>
                                </>
                            </Pressable>
                        </View>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Radio_Player') {
        if (contentContext.radioData.radioIndex >= 1) {
            var previous = contentContext.radioData.radioList[contentContext.radioData.radioIndex - 1];
            if (previous != undefined) {
                return (
                    <View style={{ alignItems: 'flex-start' }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                padding: getCorrectHeight(deviceContext, 10),
                                width: getCorrectWidth(deviceContext, 100),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                        color: styling.texts.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {previous.number}. {previous.name}
                                </Text>
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Channel_Player') {
        if (contentContext.channelData.channelList.length > 1) {
            var previous = contentContext.channelData.channelList[contentContext.channelData.channelIndex - 1];
            if (previous != undefined) {
                return (
                    <View style={{ alignItems: 'flex-start', backgroundColor: 'rgba(0, 0, 0, 0.30)', borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'center',

                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : deviceContext.isPhone ? 5 : 10),
                                width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {previous.number}. {previous.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                previous = contentContext.channelData.channelList[contentContext.channelData.channelList.length - 1];
                return (
                    <View style={{ alignItems: 'flex-start' }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.60)',
                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {previous.number}. {previous.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Recording_Player') {
        return null;
    }
    if (screenName == 'CatchupTV_Player') {
        if (contentContext.channelData.programIndex >= 1) {
            var previous = contentContext.channelData.programList[contentContext.channelData.programIndex - 1];
            if (previous != undefined) {
                return (
                    <View
                        style={{
                            alignItems: 'flex-start',
                            backgroundColor: 'rgba(0, 0, 0, 0.60)',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'flex-start',
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        paddingHorizontal: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: getCatchupTVStoredImage(contentContext, previous, deviceContext) }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                            width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                        }}
                                    ></Image>
                                </View>

                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            paddingVertical: getCorrectHeight(deviceContext, 5),
                                            paddingHorizontal: 10,
                                        }}
                                    >
                                        {previous.n}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Cam_Player') {
        if (contentContext.camData.camList.length > 1) {
            var previous = contentContext.camData.camList[contentContext.camData.camIndex - 1];
            if (previous != undefined) {
                return (
                    <View style={{ alignItems: 'flex-start', backgroundColor: 'rgba(0, 0, 0, 0.30)', borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'center',

                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {previous.number}. {previous.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                previous = contentContext.camData.camList[contentContext.camData.camList.length - 1];
                return (
                    <View style={{ alignItems: 'flex-start' }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.60)',
                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + previous.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {previous.number}. {previous.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Movie_Player') {
        return null;
    }
    if (screenName == 'Series_Player') {
        var previous;
        var newIndex = contentContext.seriesData.episodeIndex - 1;
        if (newIndex >= 0) {
            previous = contentContext.seriesData.episodeList[newIndex];
        } else if (contentContext.seriesData.seasonCategoryIndex > 0) {
            var newseasonCategoryIndex = contentContext.seriesData.seasonCategoryIndex - 1;
            if (newseasonCategoryIndex >= 0) {
                var season = contentContext.seriesData.series.seasons[newseasonCategoryIndex];
                previous = season.episodes[0];
            }
        }
        if (previous != undefined) {
            return (
                <View
                    style={{
                        alignItems: 'flex-start',
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',
                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                    }}
                >
                    <Pressable
                        focusOptions={focusOptions}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => onPrevious(screenName)}
                        style={{
                            alignItems: 'flex-start',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <>
                            <View
                                style={{
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                    paddingHorizontal: 10,
                                    paddingTop: 10,
                                }}
                            >
                                <Image
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    source={{ uri: appContext.cloudUrl + previous.images.widescreen }}
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                    }}
                                ></Image>
                            </View>

                            {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                <>
                                    {previous.number != undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {previous.number}. {previous.name}
                                        </Text>
                                    )}
                                    {previous.number == undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {previous.name}
                                        </Text>
                                    )}
                                </>
                            )}
                        </>
                    </Pressable>
                </View>
            );
        } else {
            return null;
        }
    }
    if (screenName == 'Course_Player') {
        var previous;
        var newIndex = contentContext.courseData.lessonIndex - 1;
        if (newIndex >= 0) {
            previous = contentContext.courseData.lessonList[newIndex];
        }
        if (previous != undefined) {
            return (
                <View
                    style={{
                        alignItems: 'flex-start',
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',
                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                    }}
                >
                    <Pressable
                        focusOptions={focusOptions}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => onPrevious(screenName)}
                        style={{
                            alignItems: 'flex-start',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <>
                            <View
                                style={{
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                    paddingHorizontal: 10,
                                    paddingTop: 10,
                                }}
                            >
                                <Image
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    source={{ uri: appContext.cloudUrl + previous.images.widescreen }}
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                    }}
                                ></Image>
                            </View>

                            {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                <>
                                    {previous.number != undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {previous.number}. {previous.name}
                                        </Text>
                                    )}
                                    {previous.number == undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {previous.name}
                                        </Text>
                                    )}
                                </>
                            )}
                        </>
                    </Pressable>
                </View>
            );
        } else {
            return null;
        }
    }
    if (screenName == 'Short_Player') {
        if (contentContext.shortData.shortList.length > 1) {
            var previous = contentContext.shortData.shortList[contentContext.shortData.shortIndex - 1];
            if (previous != undefined) {
                return (
                    <View
                        style={{
                            alignItems: 'flex-start',
                            backgroundColor: 'rgba(0, 0, 0, 0.60)',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onPrevious(screenName)}
                            style={{
                                alignItems: 'flex-start',
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                        paddingHorizontal: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: previous.images.widescreen }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                            width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                        }}
                                    ></Image>
                                </View>

                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <>
                                        {previous.number != undefined && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                    color: styling.texts.title_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    paddingVertical: getCorrectHeight(deviceContext, 5),
                                                    paddingHorizontal: 10,
                                                }}
                                            >
                                                {previous.number}. {previous.name}
                                            </Text>
                                        )}
                                        {previous.number == undefined && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                    color: styling.texts.title_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    paddingVertical: getCorrectHeight(deviceContext, 5),
                                                    paddingHorizontal: 10,
                                                }}
                                            >
                                                {previous.name}
                                            </Text>
                                        )}
                                    </>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            }
        } else {
            return null;
        }
    }
};
export const getNext = (contentContext, screenName, styling, deviceContext, appContext, onNext, focusOptions = undefined) => {
    if (screenName == 'Album_Player') {
        if (contentContext.albums.categories[contentContext.albumData.albumCategoryIndex].albums.length > 1) {
            var next = contentContext.albums.categories[contentContext.albumData.albumCategoryIndex].albums[contentContext.albumData.albumIndex + 1];
            if (next != undefined) {
                return (
                    <View style={{ alignItems: 'flex-end' }}>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onNext(screenName)}
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                    width: getCorrectWidth(deviceContext, 100),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + next.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            }}
                                        ></Image>
                                    </View>
                                </>
                            </Pressable>
                        </View>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Podcast_Player') {
        if (contentContext.podcasts.categories[contentContext.podcastData.podcastCategoryIndex].podcasts.length > 1) {
            var next = contentContext.podcasts.categories[contentContext.podcastData.podcastCategoryIndex].podcasts[contentContext.podcastData.podcastIndex + 1];
            if (next != undefined) {
                return (
                    <View style={{ alignItems: 'flex-end' }}>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onNext(screenName)}
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                    width: getCorrectWidth(deviceContext, 100),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + next.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 75),
                                            }}
                                        ></Image>
                                    </View>
                                </>
                            </Pressable>
                        </View>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Radio_Player') {
        if (contentContext.radioData.radioList.length > 1) {
            var next = contentContext.radioData.radioList[contentContext.radioData.radioIndex + 1];
            if (next != undefined) {
                return (
                    <View style={{ alignItems: 'flex-end' }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onNext(screenName)}
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                width: getCorrectWidth(deviceContext, 100),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + next.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                        color: styling.texts.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {next.number}. {next.name}
                                </Text>
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Channel_Player') {
        if (contentContext.channelData.channelList.length > 1) {
            var next = contentContext.channelData.channelList[contentContext.channelData.channelIndex + 1];
            if (next != undefined) {
                return (
                    <View style={{ alignItems: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.30)', borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onNext(screenName)}
                            style={{
                                alignItems: 'center',

                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : deviceContext.isPhone ? 5 : 10),
                                width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + next.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {next.number}. {next.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                var next = contentContext.channelData.channelList[0];
                if (next != undefined) {
                    return (
                        <View style={{ alignItems: 'flex-end' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onNext(screenName)}
                                style={{
                                    alignItems: 'center',

                                    backgroundColor: 'rgba(0, 0, 0, 0.60)',
                                    padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                    width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                            padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + next.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            }}
                                        ></Image>
                                    </View>
                                    {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginTop: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {next.number}. {next.name}
                                        </Text>
                                    )}
                                </>
                            </Pressable>
                        </View>
                    );
                }
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Recording_Player') {
        return null;
    }
    if (screenName == 'CatchupTV_Player') {
        if (contentContext.channelData.programList.length > 1) {
            var next = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
            var current_time = moment().unix();
            if (next != undefined && current_time > next.s) {
                return (
                    <View
                        style={{
                            alignItems: 'flex-end',
                            backgroundColor: 'rgba(0, 0, 0, 0.60)',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onNext(screenName)}
                            style={{
                                alignItems: 'flex-end',
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                        paddingHorizontal: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: getCatchupTVStoredImage(contentContext, next, deviceContext) }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                            width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                        }}
                                    ></Image>
                                </View>

                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            alignItems: 'flex-end',
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            paddingVertical: getCorrectHeight(deviceContext, 5),
                                            paddingHorizontal: 10,
                                        }}
                                    >
                                        {next.n}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Cam_Player') {
        if (contentContext.camData.camList.length > 1) {
            var next = contentContext.camData.camList[contentContext.camData.camIndex + 1];
            if (next != undefined) {
                return (
                    <View style={{ alignItems: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.30)', borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius }}>
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onNext(screenName)}
                            style={{
                                alignItems: 'center',

                                padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                // width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                        backgroundColor: appContext.application.theme.images?.background,
                                        height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                        padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + next.images?.square }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                        }}
                                    ></Image>
                                </View>
                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                            color: styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {next.number}. {next.name}
                                    </Text>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            } else {
                next = contentContext.camData.camList[0];
                if (next != undefined) {
                    return (
                        <View style={{ alignItems: 'flex-end' }}>
                            <Pressable
                                focusOptions={focusOptions}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => onNext(screenName)}
                                style={{
                                    alignItems: 'center',

                                    backgroundColor: 'rgba(0, 0, 0, 0.60)',
                                    padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 10),
                                    //    width: getCorrectWidth(deviceContext, !deviceContext.isPwa && !deviceContext.isKaiOs ? 125 : deviceContext.isKaiOs ? 20 : 70),
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                                            backgroundColor: appContext.application.theme.images?.background,
                                            height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                            width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 60),
                                            padding: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 0 : 5),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            source={{ uri: appContext.cloudUrl + next.images?.square }}
                                            style={{
                                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                                width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                                height: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 20 : 50),
                                            }}
                                        ></Image>
                                    </View>
                                    {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginTop: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {next.number}. {next.name}
                                        </Text>
                                    )}
                                </>
                            </Pressable>
                        </View>
                    );
                }
            }
        } else {
            return null;
        }
    }
    if (screenName == 'Movie_Player') {
        return null;
    }
    if (screenName == 'Series_Player') {
        var next;
        var newIndex = contentContext.seriesData.episodeIndex + 1;
        if (newIndex <= contentContext.seriesData.episodeList.length - 1) {
            next = contentContext.seriesData.episodeList[newIndex];
        } else if (contentContext.seriesData.seasonCategoryIndex <= contentContext.seriesData.series.seasons.length - 1) {
            var newseasonCategoryIndex = contentContext.seriesData.seasonCategoryIndex + 1;
            if (newseasonCategoryIndex < contentContext.seriesData.series.seasons.length - 1) {
                var season = contentContext.seriesData.series.seasons[newseasonCategoryIndex];
                next = season.episodes[0];
            }
        }
        if (next != undefined) {
            return (
                <View
                    style={{
                        alignItems: 'flex-end',
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',
                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                    }}
                >
                    <Pressable
                        focusOptions={focusOptions}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => onNext(screenName)}
                        style={{
                            alignItems: 'flex-end',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <>
                            <View
                                style={{
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                    paddingHorizontal: 10,
                                    paddingTop: 10,
                                }}
                            >
                                <Image
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    source={{ uri: appContext.cloudUrl + next.images.widescreen }}
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                    }}
                                ></Image>
                            </View>

                            {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                <>
                                    {next.number != undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                alignItems: 'flex-end',
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {next.number}. {next.name}
                                        </Text>
                                    )}
                                    {next.number == undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                alignItems: 'flex-end',
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {next.name}
                                        </Text>
                                    )}
                                </>
                            )}
                        </>
                    </Pressable>
                </View>
            );
        } else {
            return null;
        }
    }
    if (screenName == 'Course_Player') {
        var next;
        var newIndex = contentContext.courseData.lessonIndex + 1;
        if (newIndex <= contentContext.courseData.lessonList.length - 1) {
            next = contentContext.courseData.lessonList[newIndex];
        }
        if (next != undefined) {
            return (
                <View
                    style={{
                        alignItems: 'flex-end',
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',
                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                    }}
                >
                    <Pressable
                        focusOptions={focusOptions}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => onNext(screenName)}
                        style={{
                            alignItems: 'flex-end',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <>
                            <View
                                style={{
                                    borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                    paddingHorizontal: 10,
                                    paddingTop: 10,
                                }}
                            >
                                <Image
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    source={{ uri: appContext.cloudUrl + next.images.widescreen }}
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                    }}
                                ></Image>
                            </View>

                            {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                <>
                                    {next.number != undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                alignItems: 'flex-end',
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {next.number}. {next.name}
                                        </Text>
                                    )}
                                    {next.number == undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                alignItems: 'flex-end',
                                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                color: styling.texts.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                paddingVertical: getCorrectHeight(deviceContext, 5),
                                                paddingHorizontal: 10,
                                            }}
                                        >
                                            {next.name}
                                        </Text>
                                    )}
                                </>
                            )}
                        </>
                    </Pressable>
                </View>
            );
        } else {
            return null;
        }
    }
    if (screenName == 'Short_Player') {
        if (contentContext.shortData.shortList.length > 1) {
            var next = contentContext.shortData.shortList[contentContext.shortData.shortIndex + 1];
            if (next != undefined) {
                return (
                    <View
                        style={{
                            alignItems: 'flex-end',
                            backgroundColor: 'rgba(0, 0, 0, 0.60)',
                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                        }}
                    >
                        <Pressable
                            focusOptions={focusOptions}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onNext(screenName)}
                            style={{
                                alignItems: 'flex-end',
                                borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,
                            }}
                        >
                            <>
                                <View
                                    style={{
                                        borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius,

                                        paddingHorizontal: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: next.images.widescreen }}
                                        style={{
                                            borderRadius: deviceContext.isKaiOs ? 0 : appContext.application.theme.images.radius / 2,
                                            height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 22.5 : deviceContext.isPhone ? 45 : 90),
                                            width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 40 : deviceContext.isPhone ? 80 : 160),
                                        }}
                                    ></Image>
                                </View>

                                {!deviceContext.isPwa && !deviceContext.isKaiOs && (
                                    <>
                                        {next.number != undefined && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    alignItems: 'flex-end',
                                                    fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                    color: styling.texts.title_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    paddingVertical: getCorrectHeight(deviceContext, 5),
                                                    paddingHorizontal: 10,
                                                }}
                                            >
                                                {next.number}. {next.name}
                                            </Text>
                                        )}
                                        {next.number == undefined && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    alignItems: 'flex-end',
                                                    fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                                    color: styling.texts.title_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    paddingVertical: getCorrectHeight(deviceContext, 5),
                                                    paddingHorizontal: 10,
                                                }}
                                            >
                                                {next.name}
                                            </Text>
                                        )}
                                    </>
                                )}
                            </>
                        </Pressable>
                    </View>
                );
            }
        } else {
            return null;
        }
    }
};
export const getCatchupTVStoredImage = (contentContext, program, deviceContext) => {
    if (deviceContext.isSmartTV || deviceContext.isWebTV) {
        return getTimedImage(contentContext.channelData.channel.streams.channels.urls.tizen_webos, program?.s);
    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
        return getTimedImage(contentContext.channelData.channel.streams.channels.urls.ios_tvos, program?.s);
    } else {
        return getTimedImage(contentContext.channelData.channel.streams.channels.urls.standard_url, program?.s);
    }
};
export const getTimedImage = (url, time) => {
    var splitUrl = url.toString().split('/');
    var image = splitUrl[0] + '//' + splitUrl[2] + '/' + splitUrl[3] + '/' + time + '.jpg';
    return image;
};
export const getLiveImage = (url) => {
    var splitUrl = url.toString().split('/');
    var image = splitUrl[0] + '//' + splitUrl[2] + '/' + splitUrl[3] + '/preview.jpg';
    return image;
};
export const getPlayerStatus = (contentContext, screenName, deviceContext, userContext) => {
    if (screenName == 'Channel_Player') {
        var currentTime = moment().unix();
        if (contentContext.channelData.program?.e >= currentTime && contentContext.channelData.program?.s <= currentTime) {
            return (
                <View
                    style={{
                        backgroundColor: 'crimson',
                        padding: getCorrectWidth(deviceContext, 2),
                        marginRight: getCorrectWidth(deviceContext, 3),
                        borderRadius: getCorrectWidth(deviceContext, 3),
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={{
                            color: '#fff',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            fontWeight: 'bold',
                        }}
                    >
                        {lang.getTranslation(userContext, 'live').toUpperCase()}
                    </Text>
                </View>
            );
        } else if (contentContext.channelData.program?.e <= currentTime && contentContext.channelData.channel.interactivetv.catchuptv_enabled) {
            return (
                <View
                    style={{
                        backgroundColor: 'orange',
                        padding: getCorrectWidth(deviceContext, 2),
                        marginRight: getCorrectWidth(deviceContext, 3),
                        borderRadius: getCorrectWidth(deviceContext, 3),
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={{
                            color: '#fff',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            fontWeight: 'bold',
                        }}
                    >
                        {lang.getTranslation(userContext, 'dvr').toUpperCase()}
                    </Text>
                </View>
            );
        } else {
            return null;
        }
    } else if (screenName == 'Recording_Player') {
        return (
            <View
                style={{
                    backgroundColor: 'crimson',
                    padding: getCorrectWidth(deviceContext, 2),
                    marginRight: getCorrectWidth(deviceContext, 3),
                    borderRadius: getCorrectWidth(deviceContext, 3),
                }}
            >
                <Text
                    numberOfLines={1}
                    style={{
                        color: '#fff',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        fontWeight: 'bold',
                    }}
                >
                    {lang.getTranslation(userContext, 'dvr').toUpperCase()}
                </Text>
            </View>
        );
    } else if (screenName == 'CatchupTV_Player') {
        return (
            <View
                style={{
                    backgroundColor: '#4169e1',
                    padding: getCorrectWidth(deviceContext, 2),
                    marginRight: getCorrectWidth(deviceContext, 3),
                    borderRadius: getCorrectWidth(deviceContext, 3),
                }}
            >
                <Text
                    numberOfLines={1}
                    style={{
                        color: '#fff',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        fontWeight: 'bold',
                    }}
                >
                    {lang.getTranslation(userContext, 'replay').toUpperCase()}
                </Text>
            </View>
        );
    } else {
        return null;
    }
};
export const getImage = (contentContext, screenName, styling, deviceContext, appContext) => {
    if (screenName == 'Channel_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,
                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{ uri: appContext.cloudUrl + contentContext.channelData.channel.images?.square }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'Recording_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,

                    height: getCorrectHeight(deviceContext, 75),
                    width: getCorrectWidth(deviceContext, 133),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{ uri: contentContext.recordingData.program.m }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, 133),
                        height: getCorrectHeight(deviceContext, 75),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'CatchupTV_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,

                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{ uri: appContext.cloudUrl + contentContext.channelData.channel.images?.square }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'Cam_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,

                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{ uri: appContext.cloudUrl + contentContext.camData.cam.images?.square }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'Movie_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,
                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 88 : 133),
                    width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 50 : 75),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{
                        uri: contentContext.movieData.movie.images?.poster?.indexOf('https://') == -1 ? appContext.cloudUrl + contentContext.movieData.movie?.images?.poster : contentContext.movieData.movie?.images?.poster,
                    }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 50 : 75),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 88 : 133),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'Series_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,

                    flex: 1,
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{
                        uri: contentContext.seriesData.episode?.images?.widescreen?.indexOf('https://') == -1 ? appContext.cloudUrl + contentContext.seriesData.episode?.images?.widescreen : contentContext.seriesData.episode?.images?.widescreen,
                    }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 88 : 150),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 50 : 84),
                    }}
                ></Image>
            </View>
        );
    }
    if (screenName == 'Course_Player') {
        return (
            <View
                style={{
                    borderRadius: appContext.application.theme.images.radius,

                    height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 50 : 84),
                    width: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 88 : 150),
                    marginRight: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Image
                    resizeMethod={'scale'}
                    resizeMode={'contain'}
                    source={{
                        uri: contentContext.courseData.lesson.images?.widescreen?.indexOf('https://') == -1 ? appContext.cloudUrl + contentContext.courseData.lesson.images?.widescreen : contentContext.courseData.lesson.images?.widescreen,
                    }}
                    style={{
                        borderRadius: appContext.application.theme.images.radius,
                        width: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 88 : 133),
                        height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 50 : 75),
                    }}
                ></Image>
            </View>
        );
    }
};
export const getStartTimeScrubber = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.program?.s;
    } else if (screenName == 'Radio_Player') {
        return contentContext.radioData.program?.s;
    } else if (screenName == 'Cams_Player') {
        return contentContext.camData.program?.s;
    } else {
        return 0;
    }
};
export const getEndTimeScrubber = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.program?.e;
    } else if (screenName == 'Radio_Player') {
        return contentContext.radioData.program?.e;
    } else if (screenName == 'Cams_Player') {
        return contentContext.camData.program?.e;
    } else {
        return 0;
    }
};
export const getTypeScrubber = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return true;
    } else if (screenName == 'Cams_Player') {
        return true;
    } else {
        return false;
    }
};
export const getCatchupTV = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.channel.interactivetv.catchuptv_enabled;
    } else if (screenName == 'Cam_Player') {
        return contentContext.camData.cam.interactivetv.catchuptv_enabled;
    } else if (screenName == 'CatchupTV_Player') {
        return true;
    } else {
        return false;
    }
};
export const getControls = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.channel.interactivetv.catchuptv_enabled;
    } else {
        return true;
    }
};
export const getPauseControl = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        return contentContext.channelData.channel.interactivetv.pausetv_enabled;
    } else {
        return true;
    }
};
export const getCatchupTVPreviousControl = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        // var program = contentContext.channelData.programList[contentContext.channelData.programIndex - 1];
        if (contentContext.channelData.program != undefined && contentContext.channelData.channel.interactivetv.catchuptv_enabled) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
export const getCatchupTVNextControl = (contentContext, screenName) => {
    if (screenName == 'Channel_Player') {
        //var program = contentContext.channelData.programList[contentContext.channelData.programIndex + 1];
        if (contentContext.channelData.program != undefined && contentContext.channelData.channel.interactivetv.catchuptv_enabled) {
            var current_time = moment().unix();
            if (contentContext.channelData.program?.s > current_time) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
};
export const getForwardControl = (contentContext, screenName, playerContext) => {
    if (screenName == 'Channel_Player') {
        if (contentContext.channelData.channel.interactivetv.catchuptv_enabled) {
            var current_time = moment().unix() - contentContext.channelData.program?.s;
            var player_time = playerContext.seekSetPosition;
            if (player_time != 0 && player_time < current_time) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};
export const showAirplayPlaying = (deviceContext, userContext) => {
    return (
        <View
            pointerEvents={'box-none'}
            style={{
                position: 'absolute',
                zIndex: 0,
                width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext),
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) - getCorrectHeight(deviceContext, 75),
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <FontAwesomeIcon icon={faAirplay} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraExtraLarge')}></FontAwesomeIcon>
            <Text
                style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontFamily: deviceContext.fontType,
                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                }}
            >
                {lang.getTranslation(userContext, 'playing_cast')}
            </Text>
        </View>
    );
};
export const showChromecastPlaying = (deviceContext, userContext) => {
    return (
        <View
            pointerEvents={'box-none'}
            style={{
                position: 'absolute',
                zIndex: 0,
                width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext),
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) - getCorrectHeight(deviceContext, 75),
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <FontAwesomeIcon icon={faChromecast} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraExtraLarge')}></FontAwesomeIcon>
            <Text
                style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontFamily: deviceContext.fontType,
                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                }}
            >
                {lang.getTranslation(userContext, 'playing_cast')}
            </Text>
        </View>
    );
};
export const getLanguagFromPlayer = (c) => {
    try {
        if (c == null || c == '' || !c) {
            return '';
        } else {
            return new Intl.DisplayNames([lang.getLanguageCode(global.selectedLanguage)], { type: 'language' }).of(c);
        }
    } catch (e) {
        return '';
    }
};

export const getRating = (rating: any, deviceContext) => {
    if (rating != 0) {
        rating = Math.ceil(rating / 2);
        var empty = 5 - rating;
        var ratingOut = [] as any;
        for (let index = 0; index < rating; index++) {
            ratingOut.push(<FontAwesomeIcon key={index} icon={faStarSolid} color={'gold'} size={getFontSize(deviceContext, 'Large')} style={{ margin: getCorrectWidth(deviceContext, 1) }}></FontAwesomeIcon>);
        }
        for (let index = 0; index < empty; index++) {
            ratingOut.push(<FontAwesomeIcon key={index} icon={faStar} color={'gold'} size={getFontSize(deviceContext, 'Large')} style={{ margin: getCorrectWidth(deviceContext, 1) }}></FontAwesomeIcon>);
        }
        return ratingOut;
    } else {
        return null;
    }
};

export const getKeyboard = (styling, deviceContext, appContext, setPinCode) => {
    return (
        <View
            style={{
                flex: 1,
                flexDirection: 'row',
                height: getCorrectHeight(deviceContext, 240),
                alignSelf: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    height: getCorrectHeight(deviceContext, 240),

                    backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: getCorrectHeight(deviceContext, 25),
                    }}
                >
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('1')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                1
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('2')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                2
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('3')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                3
                            </Text>
                        </View>
                    </Pressable>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('4')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                4
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('5')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                5
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('6')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                6
                            </Text>
                        </View>
                    </Pressable>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('7')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                7
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('8')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                8
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('9')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                9
                            </Text>
                        </View>
                    </Pressable>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('back')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, 30),
                            }}
                        >
                            <FontAwesomeIcon icon={faBackspace} color={'#fff'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('0')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    padding: 10,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                0
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            margin: 5,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setPinCode('clear')}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, 30),
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} color={'#fff'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
            </View>
        </View>
    );
};

export const getAudioBackground = (contentContext, type, appContext) => {
    if (type == 'Album_Player') {
        return appContext.cloudUrl + contentContext.albumData.album.images?.square;
    }
    if (type == 'Podcast_Player') {
        return appContext.cloudUrl + contentContext.podcastData.podcast.images?.square;
    }
    if (type == 'Radio_Player') {
        return appContext.cloudUrl + contentContext.radioData.radio.images?.square;
    }
};
export const getAudioImage = (contentContext, type, appContext) => {
    if (type == 'Album_Player') {
        return appContext.cloudUrl + contentContext.albumData.album.images?.square;
    }
    if (type == 'Podcast_Player') {
        return appContext.cloudUrl + contentContext.podcastData.podcast.images?.square;
    }
    if (type == 'Radio_Player') {
        return appContext.cloudUrl + contentContext.radioData.radio.images?.square;
    }
};
export const getAudioTitle = (contentContext, type) => {
    if (type == 'Album_Player') {
        return contentContext.albumData.album.name;
    }
    if (type == 'Podcast_Player') {
        return contentContext.podcastData.podcast.name;
    }
    if (type == 'Radio_Player') {
        return contentContext.radioData.radio.number + '. ' + contentContext.radioData.radio.name;
    }
};
export const getAudioSub = (contentContext, type, userContext) => {
    if (type == 'Album_Player') {
        return (contentContext.albumData.album?.artist ?? contentContext.albumData.song?.producer?.trim()) + ' | ' + lang.getTranslation(userContext, 'tracks') + ' ' + contentContext.albumData.album.songs.length;
    }
    if (type == 'Podcast_Player') {
        return contentContext.podcastData.podcast.creator + ' | ' + lang.getTranslation(userContext, 'episodes') + ' ' + contentContext.podcastData.podcast.episodes.length;
    }
    if (type == 'Radio_Player') {
        return contentContext.radioData.program.n;
    }
};
export const getAudioCurrentTrack = (contentContext, type, userContext) => {
    if (type == 'Album_Player') {
        return contentContext.albumData.album.songs[contentContext.albumData.songIndex].name;
    }
    if (type == 'Podcast_Player') {
        return contentContext.podcastData.podcast.episodes[contentContext.podcastData.episodeIndex].name;
    }
    if (type == 'Radio_Player') {
        return contentContext.radioData.program.n;
    }
};
export const getAudioPreviousTrack = (contentContext, type) => {
    if (type == 'Album_Player') {
        if (contentContext.albumData.songIndex > 1) {
            return contentContext.albumData.songs[contentContext.albumData.songIndex - 1].name;
        } else {
            return '';
        }
    }
    if (type == 'Podcast_Player') {
        if (contentContext.podcastData.episodeIndex > 1) {
            return contentContext.podcastData.episodes[contentContext.podcastData.episodeIndex - 1].name;
        } else {
            return '';
        }
    }
    if (type == 'Radio_Player') {
        if (contentContext.programIndex > 1) {
            return contentContext.programList[contentContext.programIndex - 1].n;
        } else {
            return '';
        }
    }
};
export const getAudioNextTrack = (contentContext, type) => {
    if (type == 'Album_Player') {
        if (contentContext.albumData.songIndex < contentContext.albumData.songs.length - 1) {
            return contentContext.albumData.songs[contentContext.albumData.songIndex + 1].name;
        } else {
            return '';
        }
    }
    if (type == 'Podcast_Player') {
        if (contentContext.podcastData.episodeIndex < contentContext.podcastData.episodes.length - 1) {
            return contentContext.podcastData.episodes[contentContext.podcastData.episodeIndex + 1].name;
        } else {
            return '';
        }
    }
    if (type == 'Radio_Player') {
        if (contentContext.programIndex < contentContext.programList.length - 1) {
            return contentContext.programList[contentContext.programIndex - 1].n;
        } else {
            return '';
        }
    }
};

export const checkMaxDays = (contentContext, userContext) => {
    var override = contentContext.channelData.channel.interactivetv.catchuptv_days;
    var epgdays = userContext.product.settings.epg_retention;
    var programdays = moment.unix(contentContext.channelData.program?.s).diff(moment().unix(), 'days');
    if (programdays > epgdays && override <= epgdays) {
        return false;
    } else if (override >= epgdays) {
        return true;
    } else {
        if (override != 0 && override <= epgdays) {
            return true;
        } else {
            return true;
        }
    }
};

export const getCorrectName = (item, userContext) => {
    if (item.type === 'empty') return '';
    if (item != undefined) {
        var test = item?.translations?.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            return test?.description;
        } else {
            return item.translations[0]?.description;
        }
    } else {
        return '';
    }
};
export const getCorrectCategories = (item, userContext) => {
    var categories = [] as any;
    item.categories.forEach((category, index) => {
        var test = category.translations.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            categories.push({ name: test?.description, channels: item.categories[index].channels });
        } else {
            categories.push({ name: category.translations[0]?.description, channels: item.categories[index].channels });
        }
    });
    return categories;
};
export const checkParentalLockShouldApply = (userContext, contentContext, program, channel) => {
    if (channel == undefined) {
        return false;
    }
    if (channel.parental?.enabled) {
        return true;
    } else if (program != undefined && program.mt != undefined) {
        if (program.mt.p != undefined) {
            if (contentContext.settings.agerating != undefined) {
                var ratings = contentContext.settings.agerating.ratings; //age ratings set by operator in tvms
                if (ratings != undefined) {
                    ratings.forEach((rating, index) => {
                        if (rating == program.mt.p) {
                            if (rating.locked == true) {
                                if (userContext.parental != undefined) {
                                    var currentRatingIndex = index;
                                    var customerRatingIndex = contentContext.settings.agerating.ratings.findIndex((r) => r.name == userContext.parental);
                                    if (customerRatingIndex > -1) {
                                        if (customerRatingIndex > currentRatingIndex) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            } else {
                                return false;
                            }
                        }
                    });
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
};
