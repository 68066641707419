import * as React from 'react';
import { View, FlashList, CreateListRenderItemInfo, FocusContext, setFocus, CoreManager } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import {
    checkIfAccessToContent,
    checkIfUserGeneratedListAndEmpty,
    EMPTY_DATA_ARRAY,
    extraTopBottomMargin,
    getDetailsScreen,
    getFavoritesForRails,
    getMoviesFromSubscription,
    getRailsHeight,
    getRailsWidth,
    getSortingByRecommendation,
    getWatchlistForRails,
    LOADER_TIMEOUT_MS,
} from './helpers/helper';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Pressable from '../../components/pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faRectangleHistory } from '@fortawesome/pro-light-svg-icons';
import Text from '../../components/text';
import { EXPANDABLE_WIDTH } from '../shared/player';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../../components/helpers/helper';
import { favoriteStatus, manageFavorite, manageWatching, watchingStatus } from '../../../data/account';
import Modal from '../../components/modal/modal';
import lang from '../../../../application/languages/languages';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Morphing from '../shared/morphing';
import { isFactorTv, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { SettingsContext } from '../../../context/settingsContext';
import { GetBottomPart, GetScrollElement, GetTopPart, PressAndHold } from './helpers/views';
import useBaseRail from './useBaseRail';
import { Ratio } from '../../../../screens/wrapper/helpers/getFocusOptions';
import { useFocusEffect } from '@react-navigation/native';

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    reloadElements: any;
    railIndex?: number;
}

export const Morphing_Rails = React.memo(({ styling, focusContext, navigation, reloadElements, railIndex }: Props) => {
    const screenName = getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const settingsContext = React.useContext(SettingsContext);
    const [favoriteCount, setFavoriteCount] = React.useState();
    const [data, setData] = React.useState(screenName === 'Home' && !checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext) ? EMPTY_DATA_ARRAY : []);

    const [dataRefreshed, setDataRefreshed] = React.useState<any>();
    const currentFocusRailIndex = React.useRef<number | undefined>();

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current / (deviceContext.isPhone ? 1.4 : 1) : getCorrectHeight(deviceContext, styling.placement.height);
    if (deviceContext.formFactor == 'Phone') {
        height = height * 0.7;
    }
    var width = height / 1.5;
    var widewidth = (height / 9) * 16;

    useFocusEffect(() => {
        setFavoriteCount(userContext.profile.favorites['movies'].length);
    });

    //mobile width check
    if (width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone') {
        height = ((getRealWidth(deviceContext) * 0.7) / 16) * 9;
        if (styling.texts.placement_text == 'Below') {
            height = height + getCorrectHeight(deviceContext, styling.content.list?.type == 'Channels' ? 50 : styling.content.list?.type == 'Games' ? 30 : 15);
        }
        width = (height / 9) * 16;
    }

    const getData = async () => {
        if (styling.content.type == 'Watched') {
            var items = getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    const newData = isFactorTv ? [...items, { type: 'footer' }] : [...items];
                    setData(newData);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }

        if (styling.content.type == 'Favorites') {
            var items = (await getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    const newData = isFactorTv ? [...items, { type: 'footer' }] : [...items];
                    setData(newData);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }
        if (styling.content.type == 'Content List') {
            var path = '';
            if (styling.content.list?.type == 'Movies') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (styling.content.list?.type == 'Movies') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getMoviesFromSubscription(contentContext, sortedByRecommended);
                        const newData = isFactorTv ? [...dataOut, { type: 'footer' }] : [...dataOut];

                        setData(newData);
                        return;
                    }
                }
            } catch (error) {
                //
            }
        }
    };

    React.useEffect(() => {
        if (railIndex === currentFocusRailIndex.current) {
            if (data.length === 0) {
                setFocus(`empty-morphing-placeholder-${railIndex}`);
            } else {
                const focusIndex = data.length > 2 ? 2 : 0;
                setFocus(`morphing-${railIndex}-${focusIndex}`);
            }
        }
    }, [dataRefreshed]);

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'morphing',
        contentType: styling.content.type,
        getData,
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext),
    });

    React.useEffect(() => {
        try {
            if (contentContext.movies?.categories != undefined) {
                if (styling.content.list?.type == 'Movies' && appContext.application.menus?.find((m) => m.name == 'Movies') != undefined) {
                    var movies = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.movies?.categories?.forEach((category) => {
                            category.movies.forEach((movie) => {
                                if (movie.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                    if (!movies.some((_) => _._id === movie._id)) {
                                        movies.push(movie);
                                    }
                                }
                            });
                        });
                    }
                    if (movies.length > 0 && contentContext.search.length > 2) {
                        setData(movies);
                    }
                }
            }
        } catch (e) {
            setData([]);
        }
    }, [contentContext.search]);

    const detailsItem = (item: any, index: any) => {
        getDetailsScreen(styling, userContext, contentContext, appContext, navigation, item, settingsContext);
    };

    const getOverviewScreen = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        if (item.type === 'footer') {
            const expandToWidth = (height / 9) * 16 - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 4) - Ratio(200);
            return <View style={{ width: expandToWidth, height }} />;
        } else {
            return (
                <Morphing
                    elementType={'Rails'}
                    rails={true}
                    index={index}
                    type={styling.content.list?.type}
                    styling={styling}
                    data={item}
                    height={height}
                    width={width}
                    widewidth={widewidth}
                    focusOptions={{
                        focusKey: `morphing-${railIndex}-${index}`,
                    }}
                    focusContext={focusContext}
                    focusRepeatContext={focusRepeatContext}
                    onPress={() => {
                        if (item.type !== 'empty') {
                            detailsItem(item, index);
                        }
                    }}
                    isFirst={index === 0}
                    isLast={data.length - 1 === index}
                    onLongPress={() => {
                        if (item.type !== 'empty') {
                            showLongpressModal(item, index);
                        }
                    }}
                ></Morphing>
            );
        }
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        const currentFocusParent = CoreManager.getCurrentFocus()?.getParent()?.getParent();
        const parentGroup = currentFocusParent;
        // @ts-ignore
        currentFocusRailIndex.current = Number(parentGroup?._focusKey?.split?.('-')?.[1]);

        if (styling.content.press_and_hold && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(styling.content.list?.type, item._id, userContext));
            setWatchlist(watchingStatus(styling.content.list?.type, item._id, userContext) == undefined ? false : true);
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        setWatchlist(!watchlist);
        manageWatching(userContext, styling.content.list?.type, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setShowLongpress(false);
        reloadElements();
    };
    const manageFavorite_ = () => {
        setFavorite(!favorite);
        manageFavorite(contentContext, styling.content.list?.type, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setShowLongpress(false);
        reloadElements();
    };

    React.useEffect(() => {
        if (showLongpress) {
            appContext.setModal(
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => setShowLongpress(false)}
                    data={longItem}
                    contentType={styling.content.list?.type}
                />
            );
        } else {
            appContext.setModal(null);
        }
    }, [showLongpress, watchlist, favorite, longItem]);

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,
                                height: styling.content.type === 'Search' && data.length === 0 ? 0 : getRailsHeight(deviceContext, appContext, styling, height),
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingHorizontal:
                                    styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                        ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                            ? getCorrectWidth(deviceContext, 5)
                                            : getCorrectWidth(deviceContext, styling.placement.padding)
                                        : 0,
                            }}
                        >
                            <GetTopPart
                                screenName={screenName}
                                focusContext={focusContext}
                                openOverviewPage={openOverviewPage}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            ></GetTopPart>

                            <View style={{ flexDirection: 'row' }}>
                                {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && (
                                    <View style={{ marginRight: getCorrectWidth(deviceContext, 4) }}>
                                        <Pressable
                                            style={[
                                                {
                                                    ...getPressableStyling(styling.components.button._id, appContext),
                                                    height: height - getCorrectWidth(deviceContext, 7),
                                                    width: width,
                                                },
                                            ]}
                                            focusContext={focusContext}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => openOverviewPage()}
                                        >
                                            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faRectangleHistory} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraLarge')}></FontAwesomeIcon>
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        marginTop: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        color: styling.placement.title_color,
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'more').toUpperCase()}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    </View>
                                )}
                                <View
                                    style={{ flex: 1, justifyContent: 'center', width: getRailsWidth(deviceContext, appContext, styling, screenName) }}
                                    focusContext={focusContext}
                                    focusOptions={{
                                        group: `morphing-${railIndex}`,
                                        focusKey: `morphing-${railIndex}`,
                                        allowFocusOutsideGroup: true,
                                    }}
                                >
                                    <View>{Loader()}</View>
                                    {data != null && data.length > 0 && (
                                        <FlashList
                                            ref={railsRef}
                                            data={data}
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            estimatedItemSize={height}
                                            extraData={favoriteCount}
                                            // drawDistance={height * 2}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderItem}
                                            initialScrollIndex={0}
                                            type={'row'}
                                            focusOptions={{
                                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                                ...flashListProps.focusOptions,
                                            }}
                                            {...flashListProps}
                                        />
                                    )}
                                    {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            </View>

                            {deviceContext.formFactor != 'Phone' && styling.content.press_and_hold && styling.content.press_and_hold_message && data != null && data.length > 0 && (
                                <PressAndHold deviceContext={deviceContext} appContext={appContext} userContext={userContext} lang={lang}></PressAndHold>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Morphing_Rails;
