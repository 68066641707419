import { Image, Screen, ScrollView, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Gradient from 'react-native-linear-gradient';
import Pressable from '../../application/builder/components/pressable/pressable';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import { AppContext } from '../context/appContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import utils from '../general/utils';
import LANG from '../languages/languages';
import Stacks from '../stacks/StackSelector';
import { getCorrectWidth } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getMenuIcon } from '../styling/menuIcon';
import { createTVSideNavigator } from './helpers/SideNavigator';
import Text from '../builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../builder/components/helpers/helper';
import { ContentContext } from '../context/contentContext';
import { MediaCache, CacheEventType, AddCachingTaskEvent } from 'react-native-theoplayer';
import { CachingTaskView } from '../builder/components/downloadbutton/downloadbutton';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from './helpers/helpers';
import { updateDevicesJson } from '../data/account';
import { sendActionReport } from '../../reporting/apis/action';
import { actionType } from '../../reporting/models/apps/action';

const LinearGradient = withParentContextMapper(Gradient);
const SideNavigator = createTVSideNavigator();
const Stack = createNativeStackNavigator();

const Menu = ({ navigation }: { navigation: any }) => {
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [activeSettings, setActiveSettings] = useState('');
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    var styling = [] as any;
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    global.menuWidth = 135;
    global.menuHeight = 0;
    const onPress = (menu: any) => {
        if (menu != 'Account' && activeSettings == '') {
            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        } else {
            contentContext.setActiveMenu(menu);
            if (menu == 'Back') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Home' }],
                    })
                );
            } else if (menu == 'Account') {
                contentContext.setActiveMenu('Manual');
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Manual' }],
                    })
                );
            } else if (menu == 'Switch Profile') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles' }],
                    })
                );
            } else if (menu == 'Logout') {
                settingsContext.setShowMainMenu(false);
                setActiveSettings('');

                userContext.setAutoLogin(false);
                utils.storeJson('AutoLogin', false);

                userContext.setAllProfiles([]);

                //delete current device
                var devices = userContext.devices;
                sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_DEVICE);
                var index = devices.findIndex((d) => d.uuid == deviceContext.uniqueId);
                if (index != -1) {
                    devices.splice(index, 1);
                    updateDevicesJson(appContext, userContext, devices);
                }

                setTimeout(() => {
                    if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                        userContext.setAppId('');
                        utils.storeJson('ServiceID', '');
                        userContext.setSelectedLanguage('');
                        utils.storeJson('Selected_Language', '');
                        userContext.setUserId('');
                        utils.storeJson('UserID', '');
                        userContext.setPass('');
                        utils.storeJson('Pass', '');
                    }

                    if (appContext.application.settings?.enable_nested == true) {
                        contentContext.setActiveMenu('Services');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else if (appContext.application?.settings.enable_anonymous == true) {
                        contentContext.setActiveMenu('Anonymous');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Anonymous' }],
                            })
                        );
                    } else {
                        contentContext.setActiveMenu('Login');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    }
                }, 1000);
            } else if (menu == 'Refresh') {
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                contentContext.setActiveMenu('Loader');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader' }],
                    })
                );
            } else {
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        }
    };

    const renderMenuButton = (item, index) => (
        <Pressable
            key={index}
            style={{
                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                marginHorizontal: getCorrectWidth(deviceContext, 3),
                marginVertical: getCorrectWidth(deviceContext, 1),
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress(item.menu)}
        >
            <View
                style={{
                    alignSelf: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
            >
                {contentContext.activeMenu != item.menu && (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: getCorrectWidth(deviceContext, 125),
                            paddingVertical: getCorrectWidth(deviceContext, 9),
                            paddingHorizontal: getCorrectWidth(deviceContext, 12),
                        }}
                    >
                        {downloadStatus == 'loading' && item.menu == 'Downloads' && (
                            <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 10), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <CachingTaskView size={'Normal'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                            </View>
                        )}
                        {(item.menu == 'Switch Profile' || item.menu == 'Account') && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <View style={{ width: getCorrectWidth(deviceContext, 20), alignItems: 'center' }}>
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        source={{ uri: userContext.profile.avatar }}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 14),
                                            height: getCorrectWidth(deviceContext, 14),
                                            borderRadius: 100,
                                        }}
                                    ></Image>
                                </View>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginLeft: getCorrectWidth(deviceContext, 20),
                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    }}
                                >
                                    {LANG.getTranslation(userContext, item.name)}
                                </Text>
                            </View>
                        )}
                        {item.menu != 'Switch Profile' && item.menu != 'Account' && (
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: getCorrectWidth(deviceContext, 20), alignItems: 'center' }}>
                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.3} style={{ color: getPressableTextStyling(styling.components?.button?._id, appContext).color }} icon={getMenuIcon(item.menu, userContext)} />
                                </View>

                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginLeft: getCorrectWidth(deviceContext, 20),
                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    }}
                                >
                                    {LANG.getTranslation(userContext, item.name)}
                                </Text>
                            </View>
                        )}
                    </View>
                )}
                {contentContext.activeMenu == item.menu && (
                    <View style={{ flexDirection: 'row', padding: getCorrectWidth(deviceContext, 2) }}>
                        {(item.menu == 'Switch Profile' || item.menu == 'Account') && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    paddingVertical: getCorrectWidth(deviceContext, 9),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 12),

                                    alignContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <View style={{ width: getCorrectWidth(deviceContext, 20), alignItems: 'center' }}>
                                    <Image
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        source={{ uri: userContext.profile.avatar }}
                                        style={{
                                            position: 'absolute',
                                            width: getCorrectWidth(deviceContext, 22),
                                            height: getCorrectWidth(deviceContext, 22),
                                            borderRadius: 100,
                                        }}
                                    ></Image>
                                </View>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        width: getCorrectWidth(deviceContext, 59),
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginLeft: getCorrectWidth(deviceContext, 20),
                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                        color: getSelectedStyling('Navigators', appContext).text_color,
                                        alignContent: 'center',
                                    }}
                                >
                                    {LANG.getTranslation(userContext, item.name)}
                                </Text>
                            </View>
                        )}
                        {item.menu != 'Switch Profile' && item.menu != 'Account' && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    paddingVertical: getCorrectWidth(deviceContext, 9),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 12),
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <View style={{ width: getCorrectWidth(deviceContext, 20), alignItems: 'center' }}>
                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.3} style={{ color: getSelectedStyling('Navigators', appContext).text_color }} icon={getMenuIcon(item.menu, userContext)} />
                                </View>

                                <Text
                                    numberOfLines={1}
                                    style={{
                                        width: getCorrectWidth(deviceContext, 59),
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        marginLeft: getCorrectWidth(deviceContext, 17),
                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                        color: getSelectedStyling('Navigators', appContext).text_color,
                                    }}
                                >
                                    {LANG.getTranslation(userContext, item.name)}
                                </Text>
                            </View>
                        )}
                    </View>
                )}
            </View>
        </Pressable>
    );

    const renderMenuButtons = () => {
        var menuArray = [] as any;
        if (activeSettings == '') {
            menuArray = [{ name: 'account', menu: 'Account' }];
            appContext.application.menus.forEach((element) => {
                if (
                    element.name != 'Casting' &&
                    element.name != 'Hamburger' &&
                    element.name != 'Logout' &&
                    element.name != 'Youtube' &&
                    element.name != 'Loader' &&
                    element.name != 'Services' &&
                    element.name != 'Disclaimer' &&
                    element.name != 'Signin' &&
                    element.name != 'Forgot' &&
                    element.name != 'Check' &&
                    element.name != 'Player' &&
                    element.name != 'Profiles' &&
                    element.name != 'Languages' &&
                    element.name != 'Settings' &&
                    element.name != 'Messages' &&
                    element.name != 'Support' &&
                    element.name != 'Album_Details' &&
                    element.name != 'Album_Player' &&
                    element.name != 'Podcast_Details' &&
                    element.name != 'Podcast_Player' &&
                    element.name != 'Programs'
                ) {
                    if (
                        (userContext.userId.indexOf('_review') > -1 && element.name == 'Search') ||
                        (userContext.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                        (userContext.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                        (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                        (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                        (checkIfMenuHasWebview(appContext, element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                    ) {
                        //do nothing
                    } else {
                        var menu_ = { name: element.name, menu: element.name };
                        menuArray.push(menu_);
                    }
                }
            });
            var first = 'Switch Profile';
            menuArray.sort(function (x, y) {
                return x.menu == first ? -1 : y.menu == first ? 1 : 0;
            });
            var first = 'Search';
            menuArray.sort(function (x, y) {
                return x.menu == first ? -1 : y.menu == first ? 1 : 0;
            });
        } else {
            if (appContext.application.settings.enable_profiles != false) {
                menuArray.push({ name: 'switch_profile', menu: 'Switch Profile' });
            }
            if (appContext.application.settings.show_info_manual) {
                menuArray.push({ name: 'manual', menu: 'Manual' });
            }
            if (appContext.application.settings.enable_register) {
                menuArray.push({ name: 'account', menu: 'Registration' });
            }

            if (userContext?.product?.settings?.wallet_enabled) {
                menuArray.push({ name: 'wallet', menu: 'Wallet' });
            }

            if (appContext.application.settings.show_info_subscription && userContext.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'subscription', menu: 'Subscription' });
            }

            if (appContext.application.settings.show_info_messages && userContext.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'messages', menu: 'Messages' });
            }
            // if (appContext.application.settings.show_info_renewal && userContext.userId.indexOf('_review') < 0) {
            //     menuArray.push({ name: 'renewal', menu: 'Renewal' });
            // }
            if (appContext.application.settings.show_info_support && userContext.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'support', menu: 'Support' });
            }
            if (appContext.application.settings.show_info_apps && global.Cart != undefined && userContext.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'apps', menu: 'Apps' });
            }
            if (appContext.application.settings.show_info_about) {
                menuArray.push({ name: 'about', menu: 'About' });
            }
            if (appContext.application.settings.show_info_devices) {
                menuArray.push({ name: 'devices', menu: 'Devices' });
            }
            if (appContext.application.settings.show_info_logout) {
                menuArray.push({ name: 'logout', menu: 'Logout' });
            }
            if (appContext.application.settings.show_info_updates && userContext.userId.indexOf('_review') < 0 && (deviceContext.isAndroid || deviceContext.isAndroidTV || deviceContext.isFireTV)) {
                menuArray.push({ name: 'updates', menu: 'Updates' });
            }
            if (appContext.application.settings.show_info_refresh) {
                menuArray.push({ name: 'refresh', menu: 'Refresh' });
            }
            if (appContext.application.settings.show_info_disclaimer && userContext.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'disclaimer', menu: 'Disclaimer' });
            }

            menuArray.push({ name: 'back', menu: 'Back' });
        }

        var menu = [];
        menuArray.map((item, index) => {
            if (item.name != undefined) {
                menu.push(renderMenuButton(item, index));
            }
        });
        return menu;
    };

    if (settingsContext.showMainMenu) {
        return (
            <Screen
                style={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                focusOptions={{
                    stealFocus: false,
                    group: 'menu-drawer',
                    focusKey: 'menu-drawer',
                    nextFocusRight: ['tv-guide-day-selector', 'content-screen', 'settings-screen'],
                    autoFocusEnabled: false,
                }}
            >
                <LinearGradient
                    style={{
                        backgroundColor: 'transparent',
                        width: getCorrectWidth(deviceContext, 135),
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    locations={[0.2, 0.7]}
                    colors={[styling.general.background_color1, styling.general.background_color2]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 1 }}
                />
                <Gradient
                    style={[
                        {
                            width: getCorrectWidth(deviceContext, 135),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        },
                    ]}
                    locations={[0.4, 0.8, 1]}
                    colors={[styling.general.background_color1, styling.general.background_color2]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 1 }}
                />
                <View
                    style={[
                        {
                            width: getCorrectWidth(deviceContext, 135),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        },
                    ]}
                >
                    <View
                        style={{
                            height: 135,
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <Image resizeMethod={'scale'} resizeMode={'contain'} style={{ width: 150, height: 75 }} source={{ uri: appContext.application.branding.logos.logo }} />
                    </View>

                    <ScrollView>{renderMenuButtons()}</ScrollView>
                </View>
            </Screen>
        );
    } else {
        return null;
    }
};

function SideNavigation() {
    return (
        <SideNavigator.Navigator type drawerContent={(props: any) => <Menu {...props} />}>
            <SideNavigator.Screen name={'AppStack'} component={Stacks} />
        </SideNavigator.Navigator>
    );
}

export default function Main() {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Main" component={SideNavigation} />
        </Stack.Navigator>
    );
}
