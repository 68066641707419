import moment from 'moment';
import { manageFavorite, manageRecordings, manageReminders, manageWatching } from '../../../../data/account';
import { getDetailsCourse, getDetailsMovie, getDetailsSeries, getDetailsShort, playCatchupTVIndex, playChannel, playCourse, playMovie, playSeries, playShort } from '../../rails/helpers/helper';
//import Share from 'react-native-share'

export const playEpisode = (contentContext, item, navigation, seasonIndex, episodeIndex, settingsContext) => {
    playSeries(contentContext, contentContext.seriesData.series.seasons[seasonIndex].episodes[episodeIndex], navigation, episodeIndex, contentContext.seriesData.series.seasons[seasonIndex].episodes, settingsContext, seasonIndex);
};
export const playLesson = (contentContext, item, navigation, lessonIndex, settingsContext) => {
    playCourse(contentContext, contentContext.courseData.course.lessons[lessonIndex], navigation, lessonIndex, contentContext.courseData.course.lessons, settingsContext);
};
export const onPressPlaySeason = (contentContext, item, navigation, seasonIndex, episodeIndex, settingsContext) => {
    playSeries(contentContext, contentContext.seriesData.series.seasons[seasonIndex].episodes[episodeIndex], navigation, episodeIndex, contentContext.seriesData.series.seasons[seasonIndex].episodes, settingsContext, seasonIndex);
};
export const onPressPlayCourse = (contentContext, item, navigation, lessonIndex, settingsContext) => {
    playCourse(contentContext, contentContext.courseData.course.lessons[lessonIndex], navigation, lessonIndex, contentContext.courseData.course.lessons, settingsContext);
};
export const onPressPlayCatchupTV = (navigation, startType, item, contentContext, settingsContext, programIndex) => {
    if (programIndex == -1) {
        var epg = contentContext.epgTv?.find((e) => e.i == item._id)?.epg;
        if (epg != undefined) {
            var currentTime = moment().unix();
            programIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        }
    }
    playCatchupTVIndex(contentContext, item, navigation, startType, settingsContext, programIndex);
};
export const onPressInformation = (screenName, contentContext, appContext, userContext, item, navigation, settingsContext) => {
    if (screenName == 'Channels') {
        playChannel(contentContext, appContext, userContext, item, navigation, settingsContext);
    }
    if (screenName == 'Series') {
        getDetailsSeries(contentContext, item, navigation);
    }
    if (screenName == 'Movies') {
        getDetailsMovie(contentContext, item, navigation);
    }
    if (screenName == 'Courses') {
        getDetailsCourse(contentContext, item, navigation);
    }
    if (screenName == 'Shorts') {
        getDetailsShort(contentContext, item, navigation);
    }
};
export const onPressPlay = (screenName, navigation, item, contentContext, appContext, userContext, index, settingsContext) => {
    if (screenName == 'Movie') {
        playMovie(contentContext, item, navigation, settingsContext);
    }
    if (screenName == 'Course') {
        playCourse(contentContext, item, navigation, index, [], settingsContext);
    }
    if (screenName == 'Short') {
        playShort(contentContext, item, navigation, settingsContext);
    }

    if (screenName == 'Channels') {
        playChannel(contentContext, appContext, userContext, item, navigation, settingsContext);
    }
    if (screenName == 'Program') {
        playChannel(contentContext, appContext, userContext, item, navigation, settingsContext);
    }
};
export const onPressPlayRecording = (navigation, settingsContext) => {
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Recording_Player');
};
export const onPressTrailer = (screenName, navigation, item, settingsContext) => {
    if (screenName == 'Movie') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Movie_Player', {
                startType: 'trailer',
            });
    }
    if (screenName == 'Seasons') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Series_Player', {
                startType: 'trailer',
            });
    }
};
export const onPressReplay = (screenName, navigation, settingsContext) => {
    if (screenName == 'Movie') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Movie_Player', {
                startType: 'start',
            });
    }
    if (screenName == 'Seasons') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Series_Player', {
                startType: 'start',
            });
    }
    if (screenName == 'Course') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Course_Player', {
                startType: 'start',
            });
    }
    if (screenName == 'Short') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Short_Player', {
                startType: 'start',
            });
    }
    if (screenName == 'Channels') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('CatchupTV_Player', {
                startType: 'start',
            });
    }
};
export const onPressPurchase = () => {
    ///todo
};

export const onPressFavorite = (isFavorite, contentContext, userContext, screenName, appContext, deviceContext, setUpdateScreenFavorite, item) => {
    manageFavorite(contentContext, screenName, item, appContext.application, isFavorite, userContext, deviceContext, appContext);
    if (setUpdateScreenFavorite != null && setUpdateScreenFavorite != undefined) {
        setUpdateScreenFavorite(moment().unix());
    }
};
export const onPressWatchlist = (isWatchlist, userContext, screenName, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex) => {
    manageWatching(userContext, screenName, item, appContext.application, isWatchlist, 0, 0, [], [], [], [], contentContext);
    if (setUpdateScreenWatchlist != null && setUpdateScreenWatchlist != undefined) {
        setUpdateScreenWatchlist(moment().unix());
    }
};
export const onPressContinue = (screenName, navigation, item, settingsContext) => {
    if (screenName == 'Movie') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Movie_Player', {
                startType: 'continue',
            });
    }
    if (screenName == 'Seasons') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Series_Player', {
                startType: 'continue',
            });
    }
    if (screenName == 'Course') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Course_Player', {
                startType: 'continue',
            });
    }
    if (screenName == 'Short') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('Short_Player', {
                startType: 'continue',
            });
    }
    if (screenName == 'Channels') {
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('CatchupTV_Player', {
                startType: 'continue',
            });
    }
};
export const onPressRecording = (isRecorded, userContext, appContext, program, setShowRecording, setShowRecordingRemoved) => {
    if (isRecorded) {
        deleteOrCancelRecording(userContext, appContext, program);
        setShowRecordingRemoved(true);
    } else {
        setShowRecording(true);
    }
};
export const onPressRecordingDelete = (userContext, appContext, program, navigation) => {
    deleteOrCancelRecording(userContext, appContext, program);
    navigation.navigate('Recordings');
};

export const onPressRemind = (isReminded, program, appContext, userContext, setShowReminder?, setShowReminderRemoved?) => {
    if (isReminded) {
        deleteOrCancelReminder(userContext, appContext, program);
        setShowReminderRemoved(true);
    } else {
        setShowReminder(true);
    }
};
export const remindProgram = (userContext, appContext, program, channel) => {
    manageReminders(
        'add',
        {
            program: program,
            channel: channel,
        },
        userContext,
        appContext.application
    );
};
const deleteOrCancelReminder = async (userContext, appContext, program) => {
    var newRemIndex = userContext.profile.reminders.findIndex((p) => p.program.i == program.i);
    manageReminders('delete', newRemIndex, userContext, appContext.application);
};
export const recordProgram = async (userContext, appContext, program, channel) => {
    return new Promise(function (resolve, reject) {
        var recs = userContext.profile.recordings;
        var timeSeconds = 0;
        if (recs != undefined) {
            recs.forEach((rec) => {
                timeSeconds += rec.program?.e - rec.program?.s;
            });
        }
        var test = userContext.profile.recordings.find((p) => p?.program?.i == program.i);
        if (test != undefined) {
            resolve({ success: false, reason: 'recording_already_set' });
        } else {
            var space = (userContext.product?.services?.recordings?.hours ?? 0) * 3600;
            if (space > timeSeconds) {
                var timeProgram = program?.e - program?.s;
                var newTime = timeProgram + timeSeconds;
                if (newTime < space) {
                    var path = 'https://app.tvms.io/api/v1';
                    fetch(
                        path +
                            '/recording/add?client=' +
                            encodeURIComponent(appContext.application.client) +
                            '&deployment=' +
                            encodeURIComponent(appContext.application.deployment) +
                            '&service=' +
                            encodeURIComponent(appContext.application.branding.service),
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                channel: channel,
                                program: program,
                            }),
                        }
                    )
                        .then((responsePortal) => responsePortal.json())
                        .then((response) => {
                            if (response.success == true) {
                                manageRecordings(
                                    'add',
                                    {
                                        program: program,
                                        channel: channel,
                                    },
                                    userContext,
                                    appContext.application
                                );
                                resolve({ success: true, reason: 'recording_added' });
                            } else {
                                resolve({ success: false, reason: 'no_recording_added' });
                            }
                        })
                        .catch((error) => {
                            resolve({ success: false, reason: 'no_recording_added' });
                        });
                } else {
                    resolve({ success: false, reason: 'no_space_left' });
                }
            } else {
                resolve({ success: false, reason: 'no_space_left' });
            }
        }
    });
};
const deleteOrCancelRecording = async (userContext, appContext, program) => {
    var newRec = userContext.profile.recordings.findIndex((p) => p?.program?.i == program.i);
    manageRecordings('delete', newRec, userContext, appContext.application);
};

const getSocialShareImage = (item) => {
    return item.images.square
}
export const shareToSocialMedia = (item, type) => {
    const shareOptions = {
        title: item.name,
        message: 'some message',
        image:  getSocialShareImage(item),
        url: 'https://cloudtv.akamaized.net/donotremove/sharing/share.html?client=xxx&dep=xxx&ser=xxx&type=xxx&id=xxx',
      };
    
    //   Share.open(shareOptions)
    //     .then((res) => { console.log(res) })
    //     .catch((err) => { err && console.log(err); });
}
