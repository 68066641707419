import { App as SDKApp, Debugger, View, Text } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { useState } from 'react';
import { ActivityIndicator, Dimensions } from 'react-native';
import { AppContext, AppProvider } from '../context/appContext';
import { ContentProvider } from '../context/contentContext';
import { DeviceContext, DeviceProvider } from '../context/deviceContext';
import OFFLINE from '../context/helpers/offline';
import { KeyboardProvider } from '../context/keyboardContext';
import { NetworkProvider } from '../context/networkContext';
import { PlayerProvider } from '../context/playerContext';
import { ReportProvider } from '../context/reportContext';
import { SettingsProvider } from '../context/settingsContext';
import { UserContext, UserProvider } from '../context/userContext';
import { useEffectOnce } from '../hooks/useEffectOnce';
import Navigation from '../navigation/navigation';
import { getApp, getCart, getLanguages } from '../start/helpers/loader';
import { getBrowser } from './helpers/getBrowser';
import { getQueryString } from './helpers/getQueryString';
import { loadStorageVariables } from './helpers/localStorage';
import moment from 'moment';
import 'moment/min/locales';
import { getGlobals } from '../../globals';

const App = (): React.ReactElement => {
    var webTvUrl = window.location.href;
    var packageId = getGlobals().AppPackageID;
    var queryStrings = getQueryString(webTvUrl) as any;
    if (queryStrings.packageid != undefined) {
        packageId = queryStrings.packageid;
    }
    const [appInfoLoaded, setAppInfoLoaded] = React.useState('loading');

    React.useEffect(() => {
        async function fetchData() {
            getApp(packageId)
                .then(async (result: any) => {
                    if (result) {
                        setAppInfoLoaded('loaded');
                    } else {
                        setAppInfoLoaded('error');
                    }
                })
                .catch((e) => {
                    setAppInfoLoaded('error');
                });
        }
        fetchData();
    }, []);

    if (appInfoLoaded == 'loading') {
        return null;
    }
    if (appInfoLoaded == 'error') {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                <Text style={{ color: '#ffffff' }}>There was an error loading the app!</Text>
            </View>
        );
    }

    return (
        <SettingsProvider>
            <AppProvider>
                <DeviceProvider>
                    <UserProvider>
                        <KeyboardProvider>
                            <IndexWeb />
                        </KeyboardProvider>
                    </UserProvider>
                </DeviceProvider>
            </AppProvider>
        </SettingsProvider>
    );
};
export default App;

export const IndexWeb = (): React.ReactElement => {
    global.BottomSheet = null;
    global.BottomTouchableWithoutFeedback = null;
    global.BottomSheetModalProvider = null;
    global.BottomSheetView = null;
    global.seekPosition = 0;
    global.duration = 0;
    global.scrubberIsFocus = false;
    global.NetworkInfo = null;
    global.QRCode = 'null';

    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const [storageLoaded, setStorageLoaded] = useState(false);
    const [showSettingsError, setShowSettingsError] = useState(false);

    var webTvUrl = window.location.href;
    var queryStrings = getQueryString(webTvUrl) as any;
    if (queryStrings.packageid != undefined) {
        deviceContext.setPackageId(queryStrings.packageid);
    }

    useEffectOnce(() => {
        loadApp();
    });

    const loadApp = async () => {
        if (appContext.application == undefined) {
            setShowSettingsError(true);
            return;
        }
        if (appContext.application.settings.enable_register == true || appContext.application?.settings.enable_anonymous == true) {
            await getCart(appContext.application.client, appContext.application.deployment, appContext.application.branding.service, appContext.application.settings.webshop_id, appContext);
        }
        var languages = appContext.application.settings.languages;
        getLanguages(languages, appContext.application.client, appContext.application.deployment).then(() => {
            // @ts-expect-error
            var deviceid = new DeviceUUID().get();
            var browser = getBrowser();
            deviceContext.setIsChrome(browser.name == 'Google Chrome' ? true : false);
            deviceContext.setIsSafari(browser.name == 'Safari' ? true : false);
            deviceContext.setFormFactor('Desktop');
            deviceContext.setModel(browser.name);
            deviceContext.setType('PC or Mac');
            deviceContext.setIsWebTV(true);
            deviceContext.setOs('WebTV');
            deviceContext.setOsVersion('1.0.0');
            deviceContext.setUniqueId(deviceid);
            deviceContext.setDeviceType('WebTV');
            deviceContext.setFontType(appContext.application.theme.fonts);

            if (Dimensions.get('window').width < Dimensions.get('window').height && deviceContext.isPwa == false) {
                deviceContext.setIsPwaVertical(true);
                deviceContext.setIsPwa(true);
                deviceContext.setDeviceType('PWA');
            } else if (getDeviceIsPwa()) {
                deviceContext.setIsPwaHorizontal(getDeviceIsPwa());
                deviceContext.setIsPwa(getDeviceIsPwa());
                deviceContext.setDeviceType('PWA');
            }

            loadStorageVariables().then((result) => {
                userContext.setSelectedLanguage(result[0] ?? 'English');
                userContext.setAppId(result[1] ?? '');
                userContext.setUserId(result[2] ?? '');
                userContext.setPass(result[3] ?? '');
                userContext.setAuthenticated(result[4] ?? false);
                userContext.setAutoLogin(result[4] ?? false);
                userContext.setIsOnboardingFinished(result[5] ?? false);
                userContext.setSelectedLanguageCode(result[6] ?? 'en');

                //set the locale for dates

      
                try {
                    var locales = moment.locales();
                    if (locales != undefined) {
                    var test = locales.find((l) => (l = userContext.selectedLanguageCode));
                    if (test != undefined) {
                        moment.locale(userContext.selectedLanguageCode);
                    }
                }
                } catch (e) {
                    //do nothing
                }

                const linkElement = document.querySelector('link[rel=icon]');
                // @ts-expect-error
                linkElement.href = appContext.application.branding.logos.favicon;

                var webTvUrl = window.location.href;
                var queryStrings = getQueryString(webTvUrl) as any;
                if (queryStrings.userid != undefined) {
                    userContext.setUserId(queryStrings.userid);
                }
                if (queryStrings.password != undefined) {
                    userContext.setPass(queryStrings.password);
                }
                if (queryStrings.service != undefined) {
                    userContext.setAppId(queryStrings.service);
                }
                setStorageLoaded(true);
            });
        });
    };

    const getDeviceIsPwa = () => {
        let check = false;

        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
            // @ts-expect-error
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    return (
        <SDKApp style={{ flex: 1 }}>
            <NetworkProvider>
                <ContentProvider>
                    <ReportProvider>
                        <PlayerProvider>
                            <OFFLINE></OFFLINE>
                            {storageLoaded && <Navigation />}
                            {!storageLoaded && !showSettingsError && (
                                <View style={{ flex: 1, justifyContent: 'center' }}>
                                    <ActivityIndicator size={'large'} color={'white'} />
                                </View>
                            )}
                            {showSettingsError && (
                                <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', fontSize: 20 }}>The app data could not be loaded check your setup</Text>
                                </View>
                            )}
                        </PlayerProvider>
                    </ReportProvider>
                </ContentProvider>
            </NetworkProvider>
            <Debugger />
        </SDKApp>
    );
};
