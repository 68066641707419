import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { FocusContext, Text, View } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getFocusStyling, getPressableStyling } from '../helpers/helper';
import Pressable from '../pressable/pressable';
import TextInput from '../textinput/index';

export interface InputProps {
    props?: any;
    icon: any;
    width: any;
    large?: boolean;
    isSecure: boolean;
    placeholder: string;
    styling: any;
    animation: any;
    onChangText: any;
    style?: any;
    value: any;
    showKeyboard: any;
    focusOptions?: any;
    onFocus?: () => void;
    focusContext?: FocusContext;
    needsMargin?: boolean;
}
const Input_ = ({ icon, width, value, large, isSecure, placeholder, styling, animation, onChangText, style, showKeyboard, focusContext, focusOptions, needsMargin, onFocus, ...props }: InputProps) => {
    const inputRef = React.useRef();
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const [textIsSecure, setTextIsSecure] = React.useState(isSecure);

    const setFocus = () => {
        if (deviceContext.formFactor != 'Television') {
            if (inputRef.current) {
                var input = inputRef.current as any;
                input.focus();
            }
        } else {
            showKeyboard?.();
        }
    };
    const getSecureText = (value) => {
        var ret = '';
        for (let index = 0; index < value.length; index++) {
            ret += '*';
        }
        return ret;
    };
    ///flex:1 weg gehaald als hij weer terug moet dan andere oplossing
    return (
        <View focusContext={focusContext}>
            <View>
                <Pressable
                    style={[
                        style,
                        {
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                        },
                    ]}
                    animatorOptions={getFocusStyling('Inputs', appContext)}
                    onPress={() => setFocus()}
                    focusOptions={focusOptions}
                    onFocus={onFocus}
                >
                    <View style={{ flexDirection: 'row', width: width,  }}>
                        <View style={{ justifyContent: 'center', alignContent: 'center', paddingLeft: getCorrectWidth(deviceContext, 15) }}>
                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Large')} style={{ color: styling.icon_color }} icon={icon} />
                        </View>
                        {(deviceContext.isPhone || deviceContext.isWebTV || deviceContext.isTablet || deviceContext.isKaiOs) && (
                            <TextInput
                                {...props}
                                ref={inputRef}
                                value={value}
                                numberOfLines={1}
                                placeholder={placeholder}
                                placeholderTextColor={styling.placeholder_color}
                                clearButtonMode={'always'}
                                autoCorrect={false}
                                autoFocus={false}
                                secureTextEntry={textIsSecure}
                                autoComplete={'off'}
                                autoCapitalize={'none'}
                                underlineColorAndroid={'transparent'}
                                onChangeText={(value) => onChangText(value)}
                                style={[
                                    {
                                        //flex: 1,
                                        paddingLeft: getCorrectWidth(deviceContext, 15),
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        
                                        fontWeight: styling.text_bold ? 'bold' : 'normal',

                                        color: styling.text_color,
                                        height: getCorrectHeight(deviceContext, large != undefined ? 40 : 35),
                                        width: width - getCorrectHeight(deviceContext, 25) - (isSecure && deviceContext.formFactor != 'Television' ? getCorrectHeight(deviceContext, 25) : 0),
                                    },
                                ]}
                            />
                        )}
                        {!deviceContext.isPhone && !deviceContext.isWebTV && !deviceContext.isTablet && !deviceContext.isKaiOs && (
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 28),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    {...props}
                                    numberOfLines={1}
                                    style={[
                                        {
                                            paddingLeft: getCorrectWidth(deviceContext, 15),
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: styling.text_bold ? 'bold' : 'normal',
                                            color: styling.text_color,
                                            width: width * 0.7,
                                        },
                                    ]}
                                >
                                    {!textIsSecure && value}
                                    {textIsSecure && getSecureText(value)}
                                </Text>
                                {value.length == 0 && (
                                    <Text
                                        {...props}
                                        numberOfLines={1}
                                        style={[
                                            {
                                                position: 'absolute',
                                                paddingLeft: getCorrectWidth(deviceContext, 15),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.text_bold ? 'bold' : 'normal',
                                                color: styling.placeholder_color,
                                                width: width * 0.7,
                                            },
                                        ]}
                                    >
                                        {placeholder}
                                    </Text>
                                )}
                            </View>
                        )}
                        {isSecure && deviceContext.formFactor != 'Television' && (
                            <View
                                style={{
                                    cursor: 'pointer',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'flex-end',

                                    paddingRight: getCorrectWidth(deviceContext, 15),
                                }}
                            >
                                <Pressable onPress={() => setTextIsSecure(!textIsSecure)} animatorOptions={animation}>
                                    <FontAwesomeIcon size={getCorrectHeight(deviceContext, 35) * 0.4} style={{ color: styling.icon_color, marginHorizontal: 5 }} icon={textIsSecure ? faEyeSlash : faEye} />
                                </Pressable>
                            </View>
                        )}
                    </View>
                </Pressable>
            </View>
        </View>
    );
};

export default Input_;
