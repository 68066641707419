import * as React from 'react';
import { View, Text } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../../styling/fontSizes';
import { getCorrectWidth } from '../../../../styling/correctSizes';
import { colorShade } from '../../../../styling/colorShade';
import { isMobile } from '@rnv/renative';

export const getLiveTag = (live, appContext, deviceContext) => {
    return (
        <View
            style={{
                backgroundColor: 'crimson',

                paddingHorizontal: 3,
                marginRight: 5,
                borderRadius: appContext.application.theme.tags.radius / 2,
            }}
        >
            <Text
                style={{
                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                    textShadowOffset: { width: -1, height: 1 },
                    textShadowRadius: 2,
                    fontWeight: 'bold',
                    color: appContext.application.theme.tags.color,
                    fontSize: getFontSize(deviceContext, 'X-Small'),
                    margin: getCorrectWidth(deviceContext, 2),
                    includeFontPadding: false,
                }}
            >
                {live}
            </Text>
        </View>
    );
};
export const getReplayTag = (replay, appContext, deviceContext) => {
    return (
        <View
            style={{
                backgroundColor: '#4169e1',
                paddingHorizontal: 3,
                marginRight: 3,
                borderRadius: appContext.application.theme.tags.radius / 2,
            }}
        >
            <Text
                style={{
                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                    textShadowOffset: { width: -1, height: 1 },
                    textShadowRadius: 2,
                    fontWeight: 'bold',
                    color: appContext.application.theme.tags.color,
                    fontSize: getFontSize(deviceContext, 'X-Small'),
                    margin: getCorrectWidth(deviceContext, 2),
                    includeFontPadding: false,
                }}
            >
                {replay}
            </Text>
        </View>
    );
};
export const getProgramTime = (time, appContext, deviceContext) => {
    return (
        <View
            style={{
                backgroundColor: colorShade(appContext.application.theme.tags.background, 30),

                paddingHorizontal: 3,
                marginRight: 3,
                borderRadius: appContext.application.theme.tags.radius / 2,
            }}
        >
            <Text
                style={{
                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                    textShadowOffset: { width: -1, height: 1 },
                    textShadowRadius: 2,
                    color: appContext.application.theme.tags.color,
                    fontSize: getFontSize(deviceContext, 'X-Small'),
                    margin: getCorrectWidth(deviceContext, 2),
                    includeFontPadding: false,
                    fontWeight: 'bold',
                }}
            >
                {time}
            </Text>
        </View>
    );
};
export const getProgramTags = (tags, styling, appContext, deviceContext) => {
    if (tags == undefined) {
        return null;
    }
    var Out = [] as any;

    var splitted = tags.split(',');
    splitted.map((split, index) => {
        if ((index < 3 && isMobile()) || !isMobile()) {
            Out.push(
                <View
                    key={index}
                    style={{
                        backgroundColor: appContext.application.theme.tags.background,

                        paddingHorizontal: 3,
                        marginRight: 3,
                        borderRadius: appContext.application.theme.tags.radius / 2,
                    }}
                >
                    <Text
                        style={{
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            includeFontPadding: false,
                            fontWeight: 'bold',
                        }}
                    >
                        {split}
                    </Text>
                </View>
            );
        }
    });

    return <View style={{ flexDirection: 'row', marginVertical: 5 }}>{Out}</View>;
};
