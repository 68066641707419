import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Image from '../../components/image/image';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { ContentContext } from '../../../context/contentContext';

export interface Program {
    n: string;
    s: any;
    e: any;
}
export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
    progress?: number;
    catchuptv?: boolean;
    program?: Program;
}

export interface Props {
    focusContext?: any;
    focusRepeatContext?: any;
    focusOptions?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

export const Round = ({ focusContext, focusRepeatContext, noMargin, width, onPress, styling, data, onLongPress, rails, type, heroIsPlayer, elementType, isFirst, isLast, ...rest }: Props) => {
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };
//margin bottom weg
    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };
    if (data == undefined) {
        return null;
    } else {
        return (
            <View style={getCorrectScaleStyling() ? { height: width * 1.1, justifyContent: 'center' } : {}}>
                <Pressable
                    style={[
                        {
                            ...getPressableStyling(styling.components?.button._id, appContext),
                            borderRadius: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                            marginRight: getCorrectWidth(deviceContext, 10),
                            height: width - getCorrectWidth(deviceContext, 5),
                            width: width - getCorrectWidth(deviceContext, 5),
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        },
                    ]}
                    focusContext={focusContext}
                    focusRepeatContext={focusRepeatContext}
                    onFocus={() => (rest.onFocus ? rest.onFocus() : onFocus(data))}
                    onBlur={() => (rest.onBlur ? rest.onBlur() : onBlur())}
                    animatorOptions={getFocusStyling(elementType, appContext)}
                    onPress={() => onPress(data?._id)}
                    onLongPress={() => onLongPress(data?._id)}
                    focusOptions={rest.focusOptions}
                    isFirst={isFirst}
                    isLast={isLast}
                >
                    <View>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                backgroundColor: appContext.application.theme.images?.background,
                                borderRadius: width,
                                height: width - getCorrectHeight(deviceContext, +(appContext.application.theme.images.margin * 2) + 5),
                                width: width - getCorrectHeight(deviceContext, +(appContext.application.theme.images.margin * 2) + 5),
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View>
                                <Image
                                    isCache={true}
                                    deviceContext={deviceContext}
                                    source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                    resizeMethod={'scale'}
                                    resizeMode={'cover'}
                                    style={{
                                        height: width * 0.5,
                                        width: width * 0.5,
                                    }}
                                />
                                {appContext.application.theme.icons.show_play && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderRadius: 100,
                                                height: width / 3,
                                                width: width / 3,
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon size={width / 6} style={{ color: appContext.application.theme.icons.background }} icon={faPlay} />
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </Pressable>
            </View>
        );
    }
};
export default Round;
