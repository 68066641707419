import { View, VerticalList, Image } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import { isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import * as React from 'react';
import { Dimensions } from 'react-native';
import { DeviceContext } from '../../context/deviceContext';
import { ContentContext } from '../../context/contentContext';
import { Long_Grid } from '../elements/grids/long';
import { Rectangle_Grid } from '../elements/grids/rectangle';
import { Square_Grid } from '../elements/grids/square';
import { Tile_Grid } from '../elements/grids/tile';
import { Player_Grid } from '../elements/grids/player';
import { Widescreen_Grid } from '../elements/grids/widescreen';
import { DvrProgress } from '../elements/misc/dvrprogress';
import { FilterBar } from '../elements/misc/filterbar';
import { LogoTime } from '../elements/misc/logotime';
import SearchBar from '../elements/misc/searchbar';
import { Navigation_Menu } from '../elements/navigation/menu';
import { Promotions_Ads } from '../elements/promotions/ad';
import { Promotions_Messages } from '../elements/promotions/promo';
import { Subscriber_Messages } from '../elements/promotions/message';
import { Promotions_Ota } from '../elements/promotions/ota';
import Genre_Rails from '../elements/rails/genre';
import { Long_Rails } from '../elements/rails/long';
import { Rectangle_Rails } from '../elements/rails/rectangle';
import { Round_Rails } from '../elements/rails/round';
import { Square_Rails } from '../elements/rails/square';
import { Tile_Rails } from '../elements/rails/tile';
import Top10_Rails from '../elements/rails/top10';
import Player_Rails from '../elements/rails/player';
import Morphing_Rails from '../elements/rails/morphing';
import { Widescreen_Rails } from '../elements/rails/widescreen';
import { TVGuide_Horizontal } from '../elements/tvguide/tvguide_horizontal';
import { TVGuide_Vertical } from '../elements/tvguide/tvguide_vertical';
import { UserContext } from '../../context/userContext';
import CastoolaAds from '../elements/promotions/castoolaads';
import CastoolaFullscreen from '../elements/promotions/castoolafullscreen';
import DownloadList from '../elements/lists/downloads';
import Survey from '../elements/promotions/survey';
import Menu_Rails from '../elements/rails/menu';
import { getCurrentScreenName } from '../../hooks/getScreenName';
import { AppContext } from '../../context/appContext';
import WebViews from '../elements/misc/webview';
import Hero from './hero';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import {
    checkIfUserGeneratedListAndEmpty,
} from '../../builder/elements/rails/helpers/helper';
import { getRealHeight } from '../../styling/realDeviceSizes';

const isWebTV = isPlatformTizen || isPlatformWebos;
const height = Dimensions.get('screen').height;

const Screen = ({ elements, focusContext: model, navigation, heroIsPlayer, contentScreensAvailable, hero, heroIsSingleType }): React.ReactElement => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const screenName = getCurrentScreenName();
    const navigatorType = appContext.application.navigators?.television.type;
    let paginatorFocusOptions = {};

    if (navigatorType == 'Menu Left') {
        paginatorFocusOptions = {
            nextFocusLeft: ['menu-left'],
        };
    } else if (navigatorType == 'Drawer') {
        paginatorFocusOptions = {
            nextFocusLeft: ['menu-drawer'],
        };
    }

    const canShowRailsBasedOnSchedule = (rails) => {
        if (rails.scheduler == undefined) {
            return true;
        } else {
            if (rails.scheduler.start == 0) {
                return true;
            } else {
                var now = moment().unix();
                if (rails.scheduler.start <= now && rails.scheduler.end >= now) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
    const canShowRailsBasedOnContentAccess = (rails) => {
        var test = appContext.application.menus.find((m) => m.name == rails?.content?.list?.type);
        if (rails?.content?.list?.type == 'Games' && !deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV) {
            return false;
        }
        if (test == undefined) {
            return false;
        } else {
            return true;
        }
    };

    const reloadElements = () => {
        userContext.setRefreshData(!userContext.refreshData);
    };
    const getElement = (item, index, focusContext) => {
        const element = item;
        if (element.ads != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Ads key={index} styling={element.ads} focusContext={focusContext}></Promotions_Ads>;
        }
        if (element.messages != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Messages key={index} styling={element.messages} focusContext={focusContext}></Promotions_Messages>;
        }
        if (element.otas != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Ota key={index} styling={element.otas} focusContext={focusContext}></Promotions_Ota>;
        }
        if (element.filterbars != undefined) {
            return <FilterBar key={index} styling={element.filterbars} focusContext={focusContext}></FilterBar>;
        }
        if (element.webview != undefined && (deviceContext.isWebTV || deviceContext.isTablet || deviceContext.isPhone)) {
            return <WebViews key={index} styling={element.webview} focusContext={focusContext} navigation={navigation}></WebViews>;
        }
        if (element.searchbars != undefined && userContext.userId.indexOf('_review') < 0) {
            return <SearchBar navigation={navigation} key={index} styling={element.searchbars} focusContext={focusContext}></SearchBar>;
        }
        if (element.dvrprogress != undefined) {
            return <DvrProgress key={index} styling={element.dvrprogress} focusContext={focusContext}></DvrProgress>;
        }
        if (element.logotime != undefined) {
            return <LogoTime key={index} styling={element.logotime} scrollA={0} focusContext={focusContext}></LogoTime>;
        }
        if (element.rectangle_grids != undefined) {
            return <Rectangle_Grid key={index} navigation={navigation} styling={element.rectangle_grids} focusContext={focusContext}></Rectangle_Grid>;
        }
        if (element.square_grids != undefined) {
            return <Square_Grid key={index} navigation={navigation} styling={element.square_grids} focusContext={focusContext}></Square_Grid>;
        }
        if (element.widescreen_grids != undefined) {
            return <Widescreen_Grid key={index} navigation={navigation} styling={element.widescreen_grids} focusContext={focusContext}></Widescreen_Grid>;
        }
        if (element.player_grids != undefined) {
            return <Player_Grid navigation={navigation} key={index} styling={element.player_grids} focusContext={focusContext}></Player_Grid>;
        }
        if (element.long_grids != undefined) {
            return <Long_Grid key={index} navigation={navigation} styling={element.long_grids} focusContext={focusContext}></Long_Grid>;
        }
        if (element.tile_grids != undefined) {
            return <Tile_Grid key={index} navigation={navigation} styling={element.tile_grids} focusContext={focusContext}></Tile_Grid>;
        }
        if (element.castoolaads != undefined && userContext.userId.indexOf('_review') < 0) {
            return <CastoolaAds key={index} navigation={navigation} styling={element.castoolaads} focusContext={focusContext}></CastoolaAds>;
        }
        if (element.castoolafullscreen != undefined) {
            return <CastoolaFullscreen key={index} navigation={navigation} styling={element.castoolafullscreen} focusContext={focusContext}></CastoolaFullscreen>;
        }
        if (element.list_downloads != undefined) {
            return <DownloadList key={index} navigation={navigation} styling={element.list_downloads} focusContext={focusContext}></DownloadList>;
        }
        if (element.survey != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Survey key={index} navigation={navigation} styling={element.survey} focusContext={focusContext}></Survey>;
        }
        if (element.menu_rails != undefined && canShowRailsBasedOnContentAccess(element.menu_rails) && canShowRailsBasedOnContentAccess(element.menu_rails)) {
            return <Menu_Rails key={index} navigation={navigation} styling={element.menu_rails} focusContext={focusContext}></Menu_Rails>;
        }
        if (element.rectangle_rails != undefined && canShowRailsBasedOnSchedule(element.rectangle_rails) && canShowRailsBasedOnContentAccess(element.rectangle_rails)) {
            return <Rectangle_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.rectangle_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Rectangle_Rails>;
        }
        if (element.square_rails != undefined && canShowRailsBasedOnSchedule(element.square_rails) && canShowRailsBasedOnContentAccess(element.square_rails)) {
            return <Square_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.square_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Square_Rails>;
        }
        if (element.top10_rails != undefined && element.top10_rails != null && canShowRailsBasedOnSchedule(element.top10_rails) && canShowRailsBasedOnContentAccess(element.top10_rails)) {
            return <Top10_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.top10_rails} focusContext={focusContext}></Top10_Rails>;
        }
        if (element.genre_rails != undefined && canShowRailsBasedOnSchedule(element.genre_rails) && canShowRailsBasedOnContentAccess(element.genre_rails)) {
            return <Genre_Rails railIndex={index} navigation={navigation} key={index} styling={element.genre_rails} focusContext={focusContext}></Genre_Rails>;
        }
        if (element.round_rails != undefined && canShowRailsBasedOnSchedule(element.round_rails) && canShowRailsBasedOnContentAccess(element.round_rails)) {
            return <Round_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.round_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Round_Rails>;
        }
        if (element.widescreen_rails != undefined && canShowRailsBasedOnSchedule(element.widescreen_rails) && canShowRailsBasedOnContentAccess(element.widescreen_rails)) {
            return <Widescreen_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.widescreen_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Widescreen_Rails>;
        }
        if (element.player_rails != undefined && canShowRailsBasedOnSchedule(element.player_rails) && canShowRailsBasedOnContentAccess(element.player_rails) && userContext.userId.indexOf('_review') < 0) {
            if (isWebTV) return null;
            return <Player_Rails railIndex={index} navigation={navigation} key={index} styling={element.player_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Player_Rails>;
        }
        if (element.morphing_rails != undefined && canShowRailsBasedOnSchedule(element.morphing_rails) && canShowRailsBasedOnContentAccess(element.morphing_rails) && userContext.userId.indexOf('_review') < 0) {
            if (isWebTV) return null;
            return <Morphing_Rails railIndex={index} navigation={navigation} key={index} styling={element.morphing_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Morphing_Rails>;
        }
        if (element.long_rails != undefined && canShowRailsBasedOnSchedule(element.long_rails) && canShowRailsBasedOnContentAccess(element.long_rails)) {
            return <Long_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.long_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Long_Rails>;
        }
        if (element.tile_rails != undefined && canShowRailsBasedOnSchedule(element.tile_rails) && canShowRailsBasedOnContentAccess(element.tile_rails)) {
            return <Tile_Rails railIndex={index} heroIsPlayer={heroIsPlayer} navigation={navigation} key={index} styling={element.tile_rails} focusContext={focusContext} reloadElements={() => reloadElements()}></Tile_Rails>;
        }
        if (element.menus != undefined) {
            return <Navigation_Menu key={index} styling={element.menus} focusContext={focusContext}></Navigation_Menu>;
        }
        if (element.guide_horizontal != undefined && !deviceContext.isPhone && !deviceContext.isPwaVertical) {
            return <TVGuide_Horizontal navigation={navigation} key={index} styling={element.guide_horizontal} focusContext={focusContext}></TVGuide_Horizontal>;
        }
        if (element.guide_horizontal != undefined && (deviceContext.isPhone || deviceContext.isPwaVertical)) {
            return <TVGuide_Vertical navigation={navigation} key={index} styling={element.guide_horizontal} focusContext={focusContext}></TVGuide_Vertical>;
        }
    };

    const filterData = React.useCallback(

        (elements: any[]) => {
            return elements.filter((element) => {
                const key = Object.keys(element)[0];

                if (element[key] && (key === 'castoolaads' || key === 'messages')) {
                    return userContext.userId.indexOf('_review') < 0;
                }

                if (element[key] && (key === 'player_rails' || key === 'morphing_rails')) {
                    return canShowRailsBasedOnContentAccess(element[key])
                        && !checkIfUserGeneratedListAndEmpty(element[key], userContext, contentContext, appContext)
                        && canShowRailsBasedOnContentAccess(element[key]) && userContext.userId.indexOf('_review') < 0;
                }

                if (key !== 'genre_rails' && key !== 'menu_rails') {
                    return element[key] && canShowRailsBasedOnContentAccess(element[key])
                        && canShowRailsBasedOnContentAccess(element[key])
                        && !checkIfUserGeneratedListAndEmpty(element[key], userContext, contentContext, appContext);
                }

                return element[key] && canShowRailsBasedOnContentAccess(element[key]) && canShowRailsBasedOnContentAccess(element[key]);
            });
        },
        [elements]
    );

    const Header = React.useMemo(
        () => (
            <View>
         {/* {userContext.userId.indexOf('_review') < 0 && screenName == 'Home' && <Subscriber_Messages key={0} focusContext={model}></Subscriber_Messages>} */}
                <View
                    style={
                        {
                            zIndex: 4,
                            flexGrow: 1,
                            alignItems: 'center',
                            height: height * 0.78
                        }
                    }
                >
                    <Hero focusContext={model} navigation={navigation} elements={hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                </View>
                <Image
                    source={require('../../../assets/images/gradient-home-top-bottom.png')}
                    resizeMethod={'scale'}
                    resizeMode={'stretch'}
                    style={{
                        top: getRealHeight(deviceContext) + (heroIsSingleType ? 10 : 0),
                        bottom: 0,
                        left: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: -1,
                        opacity: heroIsSingleType ? 0.5 : 1,
                    }}
                ></Image>
            </View>
        ),
        []
    );

    return (
        <VerticalList
            itemsInPage={1}
            focusContext={model}
            data={filterData(elements)}
            dataRenderer={getElement}
            Header={Header}
            style={{
                flex: 1,
            }}
            paginatorFocusOptions={paginatorFocusOptions}
            contentStyle={{
                zIndex: 5,
                marginTop: getCorrectHeight(deviceContext, 5),
                marginLeft: getCorrectWidth(deviceContext, global.menuWidth),
            }}
        />
    );
};
function areEqual(prevProps, nextProps) {
    return prevProps.elements.length === nextProps.elements.length;
}

export default React.memo(Screen, areEqual);
