export const createNewProfiles = (id, userContext, appContext) => {
    var profiles = [
        {
            id: id,
            avatar: 'https://cloudtv.akamaized.net/ProfileImages/183371647_flower _xl-beta-v2-2-2.png',
            name: userContext.user.customer != undefined ? userContext.user.customer.first_name : 'TV User',
            locked: false,
            subtitles: '',
            audio: '',
            language: userContext.selectedLanguage,
            parental: '',
            code: '0000',
            clock: appContext.application.settings.clock_format,
            aspectRatio: 'Cover',
            consent: true,
            recommendations: [],
            recordings: [],
            reminders: [],
            favorites: {
                channels: [],
                radios: [],
                cams: [],
                movies: [],
                series: [],
                courses: [],
                podcasts: [],
                music: [],
                shorts: [],
            },
            watchings: {
                channels: [],
                radios: [],
                cams: [],
                movies: [],
                series: [],
                seasons: [],
                episodes: [],
                courses: [],
                lessons: [],
                podcasts: [],
                pods: [],
                music: [],
                songs: [],
                shorts: [],
            },
            payperview: {
                channels: [],
                radios: [],
                cams: [],
                movies: [],
                series: [],
                courses: [],
                podcasts: [],
                music: [],
                shorts: [],
            },
        },
    ];
    return profiles;
};